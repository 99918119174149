/* eslint-disable @typescript-eslint/naming-convention */
export const ICONS_MAP: { [key: string]: string } = {
    chevron: '/assets/icons/chevron.svg',
    'logo--google': '/assets/icons/logo--google.svg',
    github: '/assets/icons/github.svg',
    '2factor': '/assets/icons/2factor.svg',
    '4-four-add': '/assets/icons/4-four-add.svg',
    adaptative: '/assets/icons/adaptative.svg',
    adaptive: '/assets/icons/adaptive.svg',
    add: '/assets/icons/add.svg',
    'add--rounded': '/assets/icons/add--rounded.svg',
    ai: '/assets/icons/ai.svg',
    'ai-universities': '/assets/icons/ai-universities.svg',
    'ai-processor': '/assets/icons/ai-processor.svg',
    'auto-backup': '/assets/icons/auto-backup.svg',
    'anycast-server': '/assets/icons/anycast-server.svg',
    api: '/assets/icons/api.svg',
    apps: '/assets/icons/apps.svg',
    atom: '/assets/icons/atom.svg',
    scale: '/assets/icons/scale.svg',
    multiplatform: '/assets/icons/multiplatform.svg',
    backup: '/assets/icons/backup.svg',
    'bad-bot': '/assets/icons/bad-bot.svg',
    'bare-metal': '/assets/icons/bare-metal.svg',
    'basic-vm': '/assets/icons/basic-vm.svg',
    blockchain: '/assets/icons/blockchain.svg',
    'block-storage': '/assets/icons/block-storage.svg',
    brain: '/assets/icons/brain.svg',
    browser: '/assets/icons/browser.svg',
    buildings: '/assets/icons/buildings.svg',
    cache: '/assets/icons/cache.svg',
    cdn: '/assets/icons/cdn.svg',
    certbot: '/assets/icons/certbot.svg',
    certificate: '/assets/icons/certificate.svg',
    chat: '/assets/icons/chat.svg',
    chip: '/assets/icons/chip.svg',
    client: '/assets/icons/client.svg',
    'clock-check': '/assets/icons/clock-check.svg',
    'cloud-chat': '/assets/icons/cloud-chat.svg',
    'cloud-connection': '/assets/icons/cloud-connection.svg',
    'cloud-loading': '/assets/icons/cloud-loading.svg',
    'cloud-monitoring': '/assets/icons/cloud-monitoring.svg',
    'cloud-server': '/assets/icons/cloud-server.svg',
    'secure-cloud': '/assets/icons/secure-cloud.svg',
    'case-studies': '/assets/icons/case-studies.svg',
    eco: '/assets/icons/eco.svg',
    car: '/assets/icons/car.svg',
    caas: '/assets/icons/header-new/caas.svg',
    cloud: '/assets/icons/cloud.svg',
    cluster: '/assets/icons/cluster.svg',
    coin: '/assets/icons/coin.svg',
    computing: '/assets/icons/computing.svg',
    containers: '/assets/icons/containers.svg',
    content: '/assets/icons/content.svg',
    'creditcard-code': '/assets/icons/creditcard-code.svg',
    'custom-domain': '/assets/icons/custom-domain.svg',
    data_recover: '/assets/icons/data_recover.svg',
    data_reload: '/assets/icons/data_reload.svg',
    'ddos-protection': '/assets/icons/ddos-protection.svg',
    'delete-bin': '/assets/icons/delete-bin.svg',
    'dev-tools': '/assets/icons/dev-tools.svg',
    'direct-connect': '/assets/icons/direct-connect.svg',
    dns: '/assets/icons/dns.svg',
    dvr: '/assets/icons/dvr.svg',
    documentation: '/assets/icons/documentation.svg',
    'download-distribution': '/assets/icons/download-distribution.svg',
    download: '/assets/icons/download.svg',
    'edge-network': '/assets/icons/edge-network.svg',
    education: '/assets/icons/education.svg',
    'encrypted-data': '/assets/icons/encrypted-data.svg',
    'favorite-star': '/assets/icons/favorite-star.svg',
    'file-copy': '/assets/icons/file-copy.svg',
    'file-size': '/assets/icons/file-size.svg',
    fintech: '/assets/icons/fintech.svg',
    factory: '/assets/icons/factory.svg',
    'fast-edge': '/assets/icons/fast-edge.svg',
    gdpr: '/assets/icons/gdpr.svg',
    'git-branch': '/assets/icons/git-branch.svg',
    'false-positive-rate': '/assets/icons/false-positive-rate.svg',
    globe: '/assets/icons/globe.svg',
    grafana: '/assets/icons/grafana.svg',
    'grafana-bold': '/assets/icons/grafana-bold.svg',
    growth: '/assets/icons/growth.svg',
    health: '/assets/icons/health.svg',
    home: '/assets/icons/home.svg',
    http: '/assets/icons/http.svg',
    'hybrid-cloud': '/assets/icons/hybrid-cloud.svg',
    image: '/assets/icons/image.svg',
    improve: '/assets/icons/improve.svg',
    'info-circle': '/assets/icons/info-circle.svg',
    instances: '/assets/icons/instances.svg',
    interface: '/assets/icons/interface.svg',
    ip: '/assets/icons/ip.svg',
    'key-cloud': '/assets/icons/key-cloud.svg',
    key: '/assets/icons/key.svg',
    'key-bold': '/assets/icons/key-bold.svg',
    kubernetess: '/assets/icons/kubernetess.svg',
    laptop: '/assets/icons/laptop.svg',
    'laptop-dark': '/assets/icons/laptop-dark.svg',
    list: '/assets/icons/list.svg',
    'load-balancer': '/assets/icons/load-balancer.svg',
    limit: '/assets/icons/limit.svg',
    location: '/assets/icons/location.svg',
    'low-latency': '/assets/icons/low-latency.svg',
    'mail-broken': '/assets/icons/mail-broken.svg',
    map: '/assets/icons/map.svg',
    'media-package': '/assets/icons/media-package.svg',
    'media-store': '/assets/icons/media-store.svg',
    'media-transcoder': '/assets/icons/media-transcoder.svg',
    megaphone: '/assets/icons/megaphone.svg',
    'megaphone-dark': '/assets/icons/megaphone-dark.svg',
    microphone: '/assets/icons/microphone.svg',
    'mobile-device': '/assets/icons/mobile-device.svg',
    money: '/assets/icons/money.svg',
    'mouse-apple': '/assets/icons/mouse-apple.svg',
    multimedia: '/assets/icons/multimedia.svg',
    'network-protection': '/assets/icons/network-protection.svg',
    network: '/assets/icons/network.svg',
    'network-2': '/assets/icons/network-2.svg',
    'network-map': '/assets/icons/network-map.svg',
    notebook: '/assets/icons/notebook.svg',
    object: '/assets/icons/object.svg',
    octodns: '/assets/icons/octodns.svg',
    open: '/assets/icons/open.svg',
    'peering-connection': '/assets/icons/peering-connection.svg',
    people: '/assets/icons/people.svg',
    performance: '/assets/icons/performance.svg',
    plane: '/assets/icons/plane.svg',
    'play-circle': '/assets/icons/play-circle.svg',
    puzzle: '/assets/icons/puzzle.svg',
    press: '/assets/icons/press.svg',
    radar: '/assets/icons/radar.svg',
    'request-protection': '/assets/icons/request-protection.svg',
    resolver: '/assets/icons/resolver.svg',
    recording: '/assets/icons/recording.svg',
    'triangle-minus': '/assets/icons/triangle-minus.svg',
    'focus-eye': '/assets/icons/focus-eye.svg',
    retail: '/assets/icons/retail.svg',
    rocket: '/assets/icons/rocket.svg',
    router: '/assets/icons/router.svg',
    sdk: '/assets/icons/sdk.svg',
    security: '/assets/icons/security.svg',
    vpn: '/assets/icons/vpn.svg',
    seo: '/assets/icons/seo.svg',
    server: '/assets/icons/server.svg',
    settings: '/assets/icons/settings.svg',
    'shield-advanced': '/assets/icons/shield-advanced.svg',
    'shield-lock': '/assets/icons/shield-lock.svg',
    'shield-earth': '/assets/icons/shield-earth.svg',
    'shield-earth-bold': '/assets/icons/shield-earth-bold.svg',
    shrink: '/assets/icons/shrink.svg',
    'site-to-site': '/assets/icons/site-to-site.svg',
    skull: '/assets/icons/skull.svg',
    speed: '/assets/icons/speed.svg',
    'ssl-certificate': '/assets/icons/ssl-certificate.svg',
    storage: '/assets/icons/storage.svg',
    streaming: '/assets/icons/streaming.svg',
    'technical-support': '/assets/icons/technical-support.svg',
    terraform: '/assets/icons/terraform.svg',
    'writing-code': '/assets/icons/writing-code.svg',
    user: '/assets/icons/user.svg',
    univer_learn: '/assets/icons/univer_learn.svg',
    'video-camera': '/assets/icons/video-camera.svg',
    'video-convert': '/assets/icons/video-convert.svg',
    'video-streaming': '/assets/icons/video-streaming.svg',
    waf: '/assets/icons/waf.svg',
    'white-label': '/assets/icons/white-label.svg',
    'check-mark': '/assets/icons/check-mark.svg',
    'check-mark-small': '/assets/icons/check-mark-small.svg',
    'circle-check': '/assets/icons/circle-check.svg',
    check: '/assets/icons/check.svg',
    linkedin: '/assets/social-media/linkedin.svg',
    youtube: '/assets/social-media/youtube.svg',
    facebook: '/assets/social-media/facebook.svg',
    twitter: '/assets/social-media/twitter.svg',
    'twitter-x': '/assets/social-media/twitter-x.svg',
    instagram: '/assets/social-media/instagram.svg',
    weibo: '/assets/social-media/weibo.svg',
    xing: '/assets/social-media/xing.svg',
    quora: '/assets/social-media/quora.svg',
    reddit: '/assets/social-media/reddit.svg',
    tikTok: '/assets/social-media/tik-tok.svg',
    camera: '/assets/icons/camera.svg',
    'globe-grid': '/assets/icons/globe-grid.svg',
    'magic-fix': '/assets/icons/magic-fix.svg',
    'paint-brush': '/assets/icons/paint-brush.svg',
    'paint-brush-angled': '/assets/icons/paint-brush-angled.svg',
    robot: '/assets/icons/robot.svg',
    'building-2': '/assets/icons/building-2.svg',
    'chart-line-curved': '/assets/icons/chart-line-curved.svg',
    'cloud-bold': '/assets/icons/cloud-bold.svg',
    'container-bold': '/assets/icons/container-bold.svg',
    'ddos-protection-bold': '/assets/icons/ddos-protection-bold.svg',
    'dns-bold': '/assets/icons/dns-bold.svg',
    dollar: '/assets/icons/dollar.svg',
    'favorite-star-delete': '/assets/icons/favorite-star-delete.svg',
    hand: '/assets/icons/hand.svg',
    'image-bold': '/assets/icons/image-bold.svg',
    'map-simple': '/assets/icons/map-simple.svg',
    'network-bold': '/assets/icons/network-bold.svg',
    'paper-fold-text': '/assets/icons/paper-fold-text.svg',
    'paper-fold-text-bold': '/assets/icons/paper-fold-text-bold.svg',
    'play-circle-bold': '/assets/icons/play-circle-bold.svg',
    play: '/assets/icons/play.svg',
    'room-service': '/assets/icons/room-service.svg',
    'server-bold': '/assets/icons/server-bold.svg',
    'settings-cog': '/assets/icons/settings-cog.svg',
    'shield-check': '/assets/icons/shield-check.svg',
    'shield-check-bold': '/assets/icons/shield-check-bold.svg',
    game: '/assets/icons/game.svg',
    users: '/assets/icons/users.svg',
    close: '/assets/icons/close.svg',
    'application-plus': '/assets/icons/application-plus.svg',
    'cloud-bookmark': '/assets/icons/cloud-bookmark.svg',
    'container-3': '/assets/icons/container-3.svg',
    code: '/assets/icons/code.svg',
    data: '/assets/icons/data.svg',
    'data-search': '/assets/icons/data-search.svg',
    file: '/assets/icons/file.svg',
    'file-php': '/assets/icons/file-php.svg',
    'globe-network': '/assets/icons/globe-network.svg',
    'laptop-settings': '/assets/icons/laptop-settings.svg',
    'lightning-bolt': '/assets/icons/lightning-bolt.svg',
    lock: '/assets/icons/lock.svg',
    'lock-simple': '/assets/icons/lock-simple.svg',
    'login-2': '/assets/icons/login-2.svg',
    reload: '/assets/icons/reload.svg',
    save: '/assets/icons/save.svg',
    'server-4': '/assets/icons/server-4.svg',
    'server-4-settings': '/assets/icons/server-4-settings.svg',
    'server-signal': '/assets/icons/server-signal.svg',
    'settings-adjustments': '/assets/icons/settings-adjustments.svg',
    'shop-market': '/assets/icons/shop-market.svg',
    'textbox-code': '/assets/icons/textbox-code.svg',
    'video-eye': '/assets/icons/video-eye.svg',
    'video-flash': '/assets/icons/video-flash.svg',
    video: '/assets/icons/video.svg',
    'virtual-machines': '/assets/icons/virtual-machines.svg',
    'virtual-machines-bold': '/assets/icons/virtual-machines-bold.svg',
    'server-2': '/assets/icons/server-2.svg',
    fastly: '/assets/icons/fastly.svg',
    'table-check': '/assets/icons/table-check.svg',
    'table-close': '/assets/icons/table-close.svg',
    'access-manager': '/assets/icons/access-manager.svg',
    'attack-shield': '/assets/icons/attack-shield.svg',
    'radio-button-on': '/assets/icons/radio-button-on.svg',
    broadcasting: '/assets/icons/broadcasting.svg',
    brotli: '/assets/icons/brotli.svg',
    'chart-bar': '/assets/icons/chart-bar.svg',
    'check-list': '/assets/icons/check-list.svg',
    'chevron-shrink': '/assets/icons/chevron-shrink.svg',
    'chevron-expand': '/assets/icons/chevron-expand.svg',
    'cloud-check': '/assets/icons/cloud-check.svg',
    'cloud-connect': '/assets/icons/cloud-connect.svg',
    'cloud-signal': '/assets/icons/cloud-signal.svg',
    'code-block': '/assets/icons/code-block.svg',
    cpu: '/assets/icons/cpu.svg',
    cpu_growth: '/assets/icons/cpu_growth.svg',
    'data-box-delete': '/assets/icons/data-box-delete.svg',
    'file-move': '/assets/icons/file-move.svg',
    'file-text-key': '/assets/icons/file-text-key.svg',
    'file-zip': '/assets/icons/file-zip.svg',
    'file-zip-2': '/assets/icons/file-zip-2.svg',
    gpu_frequency: '/assets/icons/gpu_frequency.svg',
    notification: '/assets/icons/notification.svg',
    redirect: '/assets/icons/redirect.svg',
    search: '/assets/icons/search.svg',
    'security-token': '/assets/icons/security-token.svg',
    timer: '/assets/icons/timer.svg',
    ttl: '/assets/icons/ttl.svg',
    linux: '/assets/icons/linux.svg',
    windows: '/assets/icons/windows.svg',
    'apps-bold': '/assets/icons/apps-bold.svg',
    'bug-bold': '/assets/icons/bug-bold.svg',
    'education-bold': '/assets/icons/education-bold.svg',
    'favorite-star-bold': '/assets/icons/favorite-star-bold.svg',
    'growth-bold': '/assets/icons/growth-bold.svg',
    'interface-bold': '/assets/icons/interface-bold.svg',
    'people-bold': '/assets/icons/people-bold.svg',
    html5: '/assets/icons/html5.svg',
    adds: '/assets/icons/adds.svg',
    'icon-sun': '/assets/icons/icon-sun.svg',
    'round-arr': '/assets/icons/round-arr.svg',
    lacp: '/assets/icons/lacp.svg',
    'pricing-traffic': '/assets/icons/pricing-traffic.svg',
    mail: '/assets/icons/mail.svg',
    'pre-roll': '/assets/icons/pre-roll.svg',
    'mid-roll': '/assets/icons/mid-roll.svg',
    'post-roll': '/assets/icons/post-roll.svg',
    'repeat-roll': '/assets/icons/repeat-roll.svg',
    support: '/assets/icons/support.svg',
    statistic: '/assets/icons/statistic.svg',
    economy: '/assets/icons/economy.svg',
    'easy-setup': '/assets/icons/easy-setup.svg',
    'arrow-right': '/assets/icons/arrow-right.svg',
    chart: '/assets/icons/chart.svg',
    sla: '/assets/icons/sla.svg',
    'calendar-time': '/assets/icons/calendar-time.svg',
    'false-positive': '/assets/icons/false-positive.svg',
    'loss-false': '/assets/icons/header/loss-false.svg',
    // Flags
    luxembourg: '/assets/flags/Luxembourg.svg',
    netherlands: '/assets/flags/Netherlands.svg',
    'united-kingdom': '/assets/flags/United Kingdom.svg',
    newport: '/assets/images/ai-platform/newport.svg',
    manassas: '/assets/flags/United States Of America.svg',
    // Games logos
    wargaming: '/assets/images/gaming-ddos/games-logo/Wargaming.svg',
    'half-life': '/assets/images/gaming-ddos/games-logo/Half-logo.svg',
    'gta-san-andreas': '/assets/images/gaming-ddos/games-logo/Grand_Theft_Auto_San_Andreas_logo.svg',
    gta5: '/assets/images/gaming-ddos/games-logo/GTA5-logo-o.svg',
    rust: '/assets/images/gaming-ddos/games-logo/Rust.svg',
    cs: '/assets/images/gaming-ddos/games-logo/Counter.svg',
    'team-fortress': '/assets/images/gaming-ddos/games-logo/Team_Fortress_2_style_logo.svg',
    'left-for-dead': '/assets/images/gaming-ddos/games-logo/Left-4-Dead.svg',
    csgo: '/assets/images/gaming-ddos/games-logo/csgo.svg',
    'reg-doll': '/assets/images/gaming-ddos/games-logo/Rag-Doll-Kung-Fu.svg',
    ship: '/assets/images/gaming-ddos/games-logo/The-Ship.svg',
    'garry-mod': '/assets/images/gaming-ddos/games-logo/Garry-Mod-logo.svg',
    'nuclear-down': '/assets/images/gaming-ddos/games-logo/Nuclear-Dawn.svg',
    'dino-d-day': '/assets/images/gaming-ddos/games-logo/Dino-D-Day.svg',
    arma3: '/assets/images/gaming-ddos/games-logo/Arma.svg',
    'call-of-duty-mf3': '/assets/images/gaming-ddos/games-logo/Call-of-Duty-Modern-Warfare3.svg',
    starbound: '/assets/images/gaming-ddos/games-logo/Starbound.svg',
    'space-engineers': '/assets/images/gaming-ddos/games-logo/Space-Engineers.svg',
    '7-days-to-die': '/assets/images/gaming-ddos/games-logo/7Days-to-Die.svg',
    quake: '/assets/images/gaming-ddos/games-logo/Quake-Live.svg',
    ark: '/assets/images/gaming-ddos/games-logo/ARKSurvival-Evolved.svg',
    minecraft: '/assets/images/gaming-ddos/games-logo/Minecraft.svg',
    battlefield4: '/assets/images/gaming-ddos/games-logo/Battlefield4.svg',
    teamspeak3: '/assets/images/gaming-ddos/games-logo/TeamSpeak3.svg',
    'other-games': '/assets/images/gaming-ddos/games-logo/Other-games.svg',
    '5g': '/assets/icons/5g.svg',
    'vertical-orientation': '/assets/icons/vertical-orientation.svg',
    'tv-screens': '/assets/icons/tv-screens.svg',
    'horizontal-orientation': '/assets/icons/horizontal-orientation.svg',
    'desktop-version': '/assets/icons/desktop-version.svg',
    locked: '/assets/icons/locked.svg',
};

export const HEADER_ICONS_MAP: { [key: string]: string } = {
    '2factor': '/assets/icons/header/2factor.svg',
    '5g': '/assets/icons/5g.svg',
    'access-manager': '/assets/icons/header/access-manager.svg',
    close: '/assets/icons/close.svg',
    ai: '/assets/icons/ai.svg',
    'anycast-server': '/assets/icons/header/anycast-server.svg',
    'ai-universities': '/assets/icons/header/ai-universities.svg',
    api: '/assets/icons/header/api.svg',
    'application-plus': '/assets/icons/header/application-plus.svg',
    apps: '/assets/icons/header/apps.svg',
    'arrow-right': '/assets/icons/header/arrow-right.svg',
    atom: '/assets/icons/header/atom.svg',
    'attack-shield': '/assets/icons/header/attack-shield.svg',
    'bad-bot': '/assets/icons/header/bad-bot.svg',
    backup: '/assets/icons/header/backup.svg',
    'bare-metal': '/assets/icons/header/bare-metal.svg',
    blockchain: '/assets/icons/header/blockchain.svg',
    broadcasting: '/assets/icons/header/broadcasting.svg',
    brotli: '/assets/icons/header/brotli.svg',
    browser: '/assets/icons/header/browser.svg',
    bug: '/assets/icons/header/bug.svg',
    building: '/assets/icons/header/building.svg',
    buildings: '/assets/icons/header/buildings.svg',
    cache: '/assets/icons/header/cache.svg',
    'calendar-time': '/assets/icons/header/calendar-time.svg',
    camera: '/assets/icons/header/camera.svg',
    cdn: '/assets/icons/header/cdn.svg',
    certificate: '/assets/icons/header/certificate.svg',
    chart: '/assets/icons/header/chart.svg',
    'chart-bar': '/assets/icons/header/chart-bar.svg',
    chat: '/assets/icons/header/chat.svg',
    'check-list': '/assets/icons/header/check-list.svg',
    'chevron-down': '/assets/icons/header/chevron-down.svg',
    'chevron-down-form': '/assets/icons/header/chevron-down-form.svg',
    'chevron-right': '/assets/icons/header/chevron-right.svg',
    'chevron-shrink': '/assets/icons/header/chevron-shrink.svg',
    chip: '/assets/icons/header/chip.svg',
    client: '/assets/icons/header/client.svg',
    'clock-check': '/assets/icons/header/clock-check.svg',
    cloud: '/assets/icons/header/cloud.svg',
    'cloud-bookmark': '/assets/icons/header/cloud-bookmark.svg',
    'cloud-chat': '/assets/icons/header/cloud-chat.svg',
    'cloud-connect': '/assets/icons/header/cloud-connect.svg',
    'cloud-connection': '/assets/icons/header/cloud-connection.svg',
    'cloud-loading': '/assets/icons/header/cloud-loading.svg',
    'cloud-monitoring': '/assets/icons/header/cloud-monitoring.svg',
    'cloud-server': '/assets/icons/header/cloud-server.svg',
    'cloud-signal': '/assets/icons/header/cloud-signal.svg',
    cluster: '/assets/icons/header/cluster.svg',
    code: '/assets/icons/header/code.svg',
    'code-block': '/assets/icons/header/code-block.svg',
    coin: '/assets/icons/header/coin.svg',
    computing: '/assets/icons/header/computing.svg',
    'container-3': '/assets/icons/header/container-3.svg',
    container: '/assets/icons/header/container.svg',
    caas: '/assets/icons/header/caas.svg',
    containers: '/assets/icons/header/containers.svg',
    content: '/assets/icons/header/content.svg',
    cpu: '/assets/icons/header/cpu.svg',
    'creditcard-code': '/assets/icons/header/creditcard-code.svg',
    cup: '/assets/icons/header/cup.svg',
    'custom-domain': '/assets/icons/custom-domain.svg',
    data: '/assets/icons/header/data.svg',
    data_recover: '/assets/icons/header/data_recover.svg',
    'data-box-delete': '/assets/icons/header/data-box-delete.svg',
    'ddos-protection': '/assets/icons/header/ddos-protection.svg',
    'direct-connect': '/assets/icons/header/direct-connect.svg',
    dns: '/assets/icons/header/dns.svg',
    documentation: '/assets/icons/header/documentation.svg',
    dollar: '/assets/icons/header/dollar.svg',
    download: '/assets/icons/header/download.svg',
    'download-distribution': '/assets/icons/header/download-distribution.svg',
    'easy-setup': '/assets/icons/header/easy-setup.svg',
    eco: '/assets/icons/header/eco.svg',
    economy: '/assets/icons/header/economy.svg',
    'edge-network': '/assets/icons/header/edge-network.svg',
    education: '/assets/icons/header/education.svg',
    'favorite-star': '/assets/icons/header/favorite-star.svg',
    'file-copy': '/assets/icons/header/file-copy.svg',
    'file-lock': '/assets/icons/header/file-lock.svg',
    'file-move': '/assets/icons/header/file-move.svg',
    'file-size': '/assets/icons/header/file-size.svg',
    'file-text-key': '/assets/icons/header/file-text-key.svg',
    'file-zip': '/assets/icons/header/file-zip.svg',
    file: '/assets/icons/header/file.svg',
    fintech: '/assets/icons/header/fintech.svg',
    'fast-edge': '/assets/icons/header/fast-edge.svg',
    game: '/assets/icons/header/game.svg',
    'git-branch': '/assets/icons/header/git-branch.svg',
    globe: '/assets/icons/header/globe.svg',
    'globe-grid': '/assets/icons/header/globe-grid.svg',
    'globe-grid-2': '/assets/icons/header/globe-grid-2.svg',
    'globe-network': '/assets/icons/header/globe-network.svg',
    growth: '/assets/icons/header/growth.svg',
    health: '/assets/icons/header/health.svg',
    http: '/assets/icons/header/http.svg',
    'hybrid-cloud': '/assets/icons/header/hybrid-cloud.svg',
    image: '/assets/icons/header/image.svg',
    'info-circle': '/assets/icons/header/info-circle.svg',
    interface: '/assets/icons/header/interface.svg',
    instances: '/assets/icons/header/instances.svg',
    ip: '/assets/icons/header/ip.svg',
    key: '/assets/icons/header/key.svg',
    'key-cloud': '/assets/icons/header/key-cloud.svg',
    kubernetess: '/assets/icons/header/kubernetess.svg',
    lacp: '/assets/icons/header/lacp.svg',
    laptop: '/assets/icons/header/laptop.svg',
    'laptop-settings': '/assets/icons/header/laptop-settings.svg',
    'lightning-bolt': '/assets/icons/header/lightning-bolt.svg',
    limit: '/assets/icons/header/limit.svg',
    list: '/assets/icons/header/list.svg',
    'load-balancer': '/assets/icons/header/load-balancer.svg',
    location: '/assets/icons/header/location.svg',
    lock: '/assets/icons/header/lock.svg',
    login: '/assets/icons/header/login.svg',
    'log-in': '/assets/icons/header/log-in.svg',
    logs: '/assets/icons/header/logs.svg',
    'loss-false': '/assets/icons/header/loss-false.svg',
    'low-latency': '/assets/icons/header/low-latency.svg',
    'magic-fix': '/assets/icons/header/magic-fix.svg',
    map: '/assets/icons/header/map.svg',
    'map-simple': '/assets/icons/header/map-simple.svg',
    'media-package': '/assets/icons/header/media-package.svg',
    'media-store': '/assets/icons/header/media-store.svg',
    'media-transcoder': '/assets/icons/header/media-transcoder.svg',
    megaphone: '/assets/icons/header/megaphone.svg',
    microphone: '/assets/icons/header/microphone.svg',
    mobile: '/assets/icons/mobile.svg',
    'mobile-device': '/assets/icons/header/mobile-device.svg',
    money: '/assets/icons/header/money.svg',
    'mouse-apple': '/assets/icons/header/mouse-apple.svg',
    multimedia: '/assets/icons/header/multimedia.svg',
    network: '/assets/icons/header/network.svg',
    'network-2': '/assets/icons/header/network-2.svg',
    'network-protection': '/assets/icons/header/network-protection.svg',
    notebook: '/assets/icons/header/notebook.svg',
    notification: '/assets/icons/header/notification.svg',
    object: '/assets/icons/header/object.svg',
    octodns: '/assets/icons/header/octodns.svg',
    'paint-brush': '/assets/icons/header/paint-brush.svg',
    'paper-fold-text': '/assets/icons/header/paper-fold-text.svg',
    people: '/assets/icons/header/people.svg',
    performance: '/assets/icons/header/performance.svg',
    plane: '/assets/icons/header/plane.svg',
    play: '/assets/icons/header/play.svg',
    'play-circle': '/assets/icons/header/play-circle.svg',
    'pricing-traffic': '/assets/icons/header/pricing-traffic.svg',
    puzzle: '/assets/icons/header/puzzle.svg',
    radar: '/assets/icons/header/radar.svg',
    'radio-button-on': '/assets/icons/header/radio-button-on.svg',
    redirect: '/assets/icons/header/redirect.svg',
    reload: '/assets/icons/header/reload.svg',
    'request-protection': '/assets/icons/header/request-protection.svg',
    resolver: '/assets/icons/header/resolver.svg',
    retail: '/assets/icons/header/retail.svg',
    robot: '/assets/icons/header/robot.svg',
    rocket: '/assets/icons/header/rocket.svg',
    'room-service': '/assets/icons/header/room-service.svg',
    router: '/assets/icons/header/router.svg',
    save: '/assets/icons/header/save.svg',
    sdk: '/assets/icons/header/sdk.svg',
    search: '/assets/icons/header/search.svg',
    'secure-cloud': '/assets/icons/header/secure-cloud.svg',
    security: '/assets/icons/header/security.svg',
    'security-token': '/assets/icons/header/security-token.svg',
    server: '/assets/icons/header/server.svg',
    'server-2': '/assets/icons/header/server-2.svg',
    'server-4-settings': '/assets/icons/header/server-4-settings.svg',
    'server-signal': '/assets/icons/header/server-signal.svg',
    settings: '/assets/icons/header/settings.svg',
    'settings-adjustments': '/assets/icons/header/settings-adjustments.svg',
    'settings-cog': '/assets/icons/header/settings-cog.svg',
    'shield-advanced': '/assets/icons/header/shield-advanced.svg',
    'shield-check': '/assets/icons/header/shield-check.svg',
    'shield-earth': '/assets/icons/header/shield-earth.svg',
    'shield-earth-bold': '/assets/icons/shield-earth-bold.svg',
    'shield-lock': '/assets/icons/header/shield-lock.svg',
    'shop-market': '/assets/icons/header/shop-market.svg',
    'site-to-site': '/assets/icons/header/site-to-site.svg',
    skull: '/assets/icons/header/skull.svg',
    'ssl-certificate': '/assets/icons/header/ssl-certificate.svg',
    statistic: '/assets/icons/header/statistic.svg',
    storage: '/assets/icons/header/storage.svg',
    streaming: '/assets/icons/header/streaming.svg',
    support: '/assets/icons/header/support.svg',
    'technical-support': '/assets/icons/header/technical-support.svg',
    terraform: '/assets/icons/header/terraform.svg',
    'textbox-code': '/assets/icons/header/textbox-code.svg',
    timer: '/assets/icons/header/timer.svg',
    ttl: '/assets/icons/header/ttl.svg',
    univer_learn: '/assets/icons/header/univer_learn.svg',
    video: '/assets/icons/header/video.svg',
    'video-convert': '/assets/icons/header/video-convert.svg',
    'video-eye': '/assets/icons/header/video-eye.svg',
    'video-flash': '/assets/icons/header/video-flash.svg',
    'video-streaming': '/assets/icons/header/video-streaming.svg',
    'virtual-machines': '/assets/icons/header/virtual-machines.svg',
    vpn: '/assets/icons/header/vpn.svg',
    waf: '/assets/icons/header/waf.svg',
    menu: '/assets/icons/header/menu.svg',
    'white-label': '/assets/icons/header/white-label.svg',
    'peering-network': '/assets/icons/header/peering-network.svg',
    'case-studies': '/assets/icons/header/case-studies.svg',
    'press-fill': '/assets/icons/header/press-fill.svg',
    'ai-bold': '/assets/icons/header/ai-bold.svg',
    'cloud-bold': '/assets/icons/header/cloud-bold.svg',
    '5g-bold': '/assets/icons/header/5g-bold.svg',
    'open-menu': '/assets/icons/header/open-menu.svg',
    'close-menu': '/assets/icons/header/close-menu.svg',
    mail: '/assets/icons/header/mail.svg',
    user: '/assets/icons/header/user.svg',
};

export const HEADER_NEW_ICONS_MAP: { [key: string]: string } = {
    '5g': '/assets/icons/header-new/5g.svg',
    'bare-metal': '/assets/icons/header-new/bare-metal.svg',
    'basic-vms': '/assets/icons/header-new/basic-vms.svg',
    'block-storage': '/assets/icons/header-new/block-storage.svg',
    'cloud-container-registry': 'assets/icons/header-new/cloud-container-registry.svg',
    caas: '/assets/icons/header-new/caas.svg',
    cdn: '/assets/icons/header-new/cdn.svg',
    'data-migration': '/assets/icons/header-new/data-migration.svg',
    'ddos-protection': '/assets/icons/header-new/ddos-protection.svg',
    'disaster-recovery': '/assets/icons/header-new/disaster-recovery.svg',
    fastedge: '/assets/icons/header-new/fastedge.svg',
    'file-storage': '/assets/icons/header-new/file-storage.svg',
    'function-as-a-service': '/assets/icons/header-new/function-as-a-service.svg',
    'gpu-ai': '/assets/icons/header-new/gpu-ai.svg',
    'image-ai': '/assets/icons/header-new/image-ai.svg',
    'ipu-ai': '/assets/icons/header-new/ipu-ai.svg',
    'it-management': '/assets/icons/header-new/it-management.svg',
    inference: '/assets/icons/header-new/inference.svg',
    kubernetes: '/assets/icons/header-new/kubernetes.svg',
    loadbalanceer: '/assets/icons/header-new/loadbalanceer.svg',
    'managed-dns': '/assets/icons/header-new/managed-dns.svg',
    'managed-logging': '/assets/icons/header-new/managed-logging.svg',
    'managed-postgresql': '/assets/icons/header-new/managed-postgresql.svg',
    'object-storage': '/assets/icons/header-new/object-storage.svg',
    'penetration-test': '/assets/icons/header-new/penetration-test.svg',
    premium: '/assets/icons/header-new/premium.svg',
    'private-cloud': '/assets/icons/header-new/private-cloud.svg',
    'public-dns': '/assets/icons/header-new/public-dns.svg',
    'software-development': '/assets/icons/header-new/software-development.svg',
    'speech-ai': '/assets/icons/header-new/speech-ai.svg',
    'video-streaming': '/assets/icons/header-new/video-streaming.svg',
    'virtual-machines': '/assets/icons/header-new/virtual-machines.svg',
    waap: '/assets/icons/header-new/waap.svg',
    'web-application-security': '/assets/icons/header-new/web-application-security.svg',
    'whitelabel-program': '/assets/icons/header-new/whitelabel-program.svg',
    user: '/assets/icons/header-new/user.svg',
    search: '/assets/icons/header-new/search.svg',
    'open-menu': '/assets/icons/header-new/open-menu.svg',
    mail: '/assets/icons/header-new/mail.svg',
    'globe-grid': '/assets/icons/header-new/globe-grid.svg',
    'close-menu': '/assets/icons/header-new/close-menu.svg',
    'chevron--down': '/assets/icons/header-new/chevron--down.svg',
    'attack-shield': '/assets/icons/header-new/attack-shield.svg',
    'arrow-right': '/assets/icons/header-new/arrow-right.svg',
    'arrow-left': '/assets/icons/header-new/arrow-left.svg',
    'arrow-right-big': '/assets/icons/header-new/arrow-right-big.svg',
    'dedicated-server': '/assets/icons/header-new/dedicated-server.svg',
    'virtual-server': '/assets/icons/header-new/virtual-server.svg',
};

export const ICONS_COLLECTION: { [key: string]: string } = {
    'edge-ai': '/assets/icons/collection/edge-ai.svg',
    'edge-network': '/assets/icons/collection/edge-network.svg',
    'products-edge-ai-gpu-cloud': '/assets/icons/collection/products-edge-ai-gpu-cloud.svg',
    'products-edge-ai-image-generator': '/assets/icons/collection/products-edge-ai-image-generator.svg',
    'products-edge-ai-speech-to-text-translation':
        '/assets/icons/collection/products-edge-ai-speech-to-text-translation.svg',
    'products-inference-at-the-edge': '/assets/icons/collection/products-inference-at-the-edge.svg',
    'products-edge-cloud-basic-vms': '/assets/icons/collection/products-edge-cloud-basic-vms.svg',
    'products-edge-cloud-virtual-machines': '/assets/icons/collection/products-edge-cloud-virtual-machines.svg',
    'products-edge-cloud-5g-network': '/assets/icons/collection/products-edge-cloud-5g-network.svg',
    'products-edge-cloud-container-as-a-service':
        '/assets/icons/collection/products-edge-cloud-container-as-a-service.svg',
    'products-edge-cloud-managed-kubernetes': '/assets/icons/collection/products-edge-cloud-managed-kubernetes.svg',
    'products-edge-cloud-virtual-private-cloud':
        '/assets/icons/collection/products-edge-cloud-virtual-private-cloud.svg',
    'products-edge-cloud-load-balancer': '/assets/icons/collection/products-edge-cloud-load-balancer.svg',
    'products-edge-cloud-bare-metal': '/assets/icons/collection/products-edge-cloud-bare-metal.svg',
    'products-edge-cloud-function-as-a-service': '/assets/icons/collection/products-edge-cloud-managed-postgresql.svg',
    'products-edge-cloud-file-shares': '/assets/icons/collection/products-edge-cloud-file-shares.svg',
    'products-edge-cloud-object-storage': '/assets/icons/collection/products-edge-cloud-object-storage.svg',
    'products-edge-cloud-managed-logging': '/assets/icons/collection/products-edge-cloud-managed-logging.svg',
    'products-edge-cloud-virtual-servers': '/assets/icons/collection/products-edge-cloud-virtual-servers.svg',
    'products-edge-cloud-dedicated-servers': '/assets/icons/collection/products-edge-cloud-dedicated-servers.svg',
    'products-edge-network-cdn': '/assets/icons/collection/products-edge-network-cdn.svg',
    'products-edge-network-fast-edge': '/assets/icons/collection/products-edge-network-fast-edge.svg',
    'products-edge-networ-video-streaming': '/assets/icons/collection/products-edge-networ-video-streaming.svg',
    'products-edge-network-managed-dns': '/assets/icons/collection/products-edge-network-managed-dns.svg',
    'products-edge-network-public-dns': '/assets/icons/collection/products-edge-network-public-dns.svg',
    'products-edge-security-ddos-protection': '/assets/icons/collection/products-edge-security-ddos-protection.svg',
    'products-edge-security-waap': '/assets/icons/collection/products-edge-security-waap.svg',
    'products-edge-cloud-container-registry': '/assets/icons/collection/products-edge-cloud-container-registry.svg',
    'products-edge-security-web-application-security':
        '/assets/icons/collection/products-edge-security-web-application-security.svg',
    'system-close-rounded': '/assets/icons/collection/system-close-rounded.svg',
    'system-add-rounded': '/assets/icons/collection/system-add-rounded.svg',
    'system-minus-rounded': '/assets/icons/collection/system-minus-rounded.svg',
    'system-close': '/assets/icons/collection/system-close.svg',
    'system-add': '/assets/icons/collection/system-add.svg',
    'system-minus': '/assets/icons/collection/system-minus.svg',
    'system-arrow-right': '/assets/icons/collection/system-arrow-right.svg',
    'system-arrow-left': '/assets/icons/collection/system-arrow-left.svg',
    'system-arrow-up': '/assets/icons/collection/system-arrow-up.svg',
    'system-arrow-down': '/assets/icons/collection/system-arrow-down.svg',
    'system-arrow-corner': '/assets/icons/collection/system-arrow-corner.svg',
    'system-search': '/assets/icons/collection/system-search.svg',
    'system-list': '/assets/icons/collection/system-list.svg',
    'system-open': '/assets/icons/collection/system-open.svg',
    'system-delete-bin-2': '/assets/icons/collection/system-delete-bin-2.svg',
    'system-favorite': '/assets/icons/collection/system-favorite.svg',
    'system-star': '/assets/icons/collection/system-star.svg',
    'system-notification': '/assets/icons/collection/system-notification.svg',
    'system-notification-off': '/assets/icons/collection/system-notification-off.svg',
    'system-notification-new': '/assets/icons/collection/system-notification-new.svg',
    'system-thumbs-down': '/assets/icons/collection/system-thumbs-down.svg',
    'system-thumbs-up': '/assets/icons/collection/system-thumbs-up.svg',
    'system-view': '/assets/icons/collection/system-view.svg',
    'system-eye-slash': '/assets/icons/collection/system-eye-slash.svg',
    'system-dark': '/assets/icons/collection/system-dark.svg',
    'system-sun': '/assets/icons/collection/system-sun.svg',
    'system-check-rounded': '/assets/icons/collection/system-check-rounded.svg',
    'system-warning': '/assets/icons/collection/system-warning.svg',
    'system-check': '/assets/icons/collection/system-check.svg',
    'system-info-circle': '/assets/icons/collection/system-info-circle.svg',
    'system-settings': '/assets/icons/collection/system-settings.svg',
    'system-chat': '/assets/icons/collection/system-chat.svg',
    'system-chat-writing': '/assets/icons/collection/system-chat-writing.svg',
    'system-phone': '/assets/icons/collection/system-phone.svg',
    'system-mail': '/assets/icons/collection/system-mail.svg',
    'system-mail-broken': '/assets/icons/collection/system-mail-broken.svg',
    'system-user': '/assets/icons/collection/system-user.svg',
    'system-users': '/assets/icons/collection/system-users.svg',
    'system-chevron-left': '/assets/icons/collection/system-chevron-left.svg',
    'system-chevron-down': '/assets/icons/collection/system-chevron-down.svg',
    'system-chevron-right': '/assets/icons/collection/system-chevron-right.svg',
    'system-chevron-up': '/assets/icons/collection/system-chevron-up.svg',
    'system-filters': '/assets/icons/collection/system-filters.svg',
    'system-kebab-menu': '/assets/icons/collection/system-kebab-menu.svg',
    'system-check-false': '/assets/icons/collection/system-check-false.svg',
    'system-chevron-shrink': '/assets/icons/collection/system-chevron-shrink.svg',
    'system-scale': '/assets/icons/collection/system-scale.svg',
    'system-24-support': '/assets/icons/collection/system-24-support.svg',
    'system-save-check': '/assets/icons/collection/system-save-check.svg',
    'system-save': '/assets/icons/collection/system-save.svg',
    'system-maintenance': '/assets/icons/collection/system-maintenance.svg',
    'system-technical-support': '/assets/icons/collection/system-technical-support.svg',
    'system-support': '/assets/icons/collection/system-support.svg',
    'system-account': '/assets/icons/collection/system-account.svg',
    'system-user-cirlce-add': '/assets/icons/collection/system-user-cirlce-add.svg',
    'system-more-circle': '/assets/icons/collection/system-more-circle.svg',
    'system-more': '/assets/icons/collection/system-more.svg',
    'system-redo': '/assets/icons/collection/system-redo.svg',
    'system-emoji-happy': '/assets/icons/collection/system-emoji-happy.svg',
    'system-emoji-normal': '/assets/icons/collection/system-emoji-normal.svg',
    'system-emoji-sad': '/assets/icons/collection/system-emoji-sad.svg',
    'system-smileys': '/assets/icons/collection/system-smileys.svg',
    'system-document-copy': '/assets/icons/collection/system-document-copy.svg',
    'system-calculator': '/assets/icons/collection/system-calculator.svg',
    'system-location': '/assets/icons/collection/system-location.svg',
    'system-location-add': '/assets/icons/collection/system-location-add.svg',
    'system-location-minus': '/assets/icons/collection/system-location-minus.svg',
    'system-location-cross': '/assets/icons/collection/system-location-cross.svg',
    'system-location-tick': '/assets/icons/collection/system-location-tick.svg',
    'system-favorite-filled': '/assets/icons/collection/system-favorite-filled.svg',
    'system-star-filled': '/assets/icons/collection/system-star-filled.svg',
    'system-half-star-filled': '/assets/icons/collection/system-half-star-filled.svg',
    'system-check-filled': '/assets/icons/collection/system-check-filled.svg',
    'system-filled-warning-filled': '/assets/icons/collection/system-filled-warning-filled.svg',
    'system--more-circle-filled': '/assets/icons/collection/system--more-circle-filled.svg',
    'system-user-cirlce-add-filled': '/assets/icons/collection/system-user-cirlce-add-filled.svg',
    'system-calculator-filled': '/assets/icons/collection/system-calculator-filled.svg',
    'system-spinner': '/assets/icons/collection/system-spinner.svg',
    'file-recovery': '/assets/icons/collection/file-recovery.svg',
    'file-press': '/assets/icons/collection/file-press.svg',
    'file-paper-fold-text': '/assets/icons/collection/file-paper-fold-text.svg',
    'file-notebook': '/assets/icons/collection/file-notebook.svg',
    'file-backup': '/assets/icons/collection/file-backup.svg',
    'file-download': '/assets/icons/collection/file-download.svg',
    'file-upload': '/assets/icons/collection/file-upload.svg',
    'file-download-distribution': '/assets/icons/collection/file-download-distribution.svg',
    'file-size': '/assets/icons/collection/file-size.svg',
    'file-text-key': '/assets/icons/collection/file-text-key.svg',
    'file-move': '/assets/icons/collection/file-move.svg',
    'file-zipe': '/assets/icons/collection/file-zipe.svg',
    'file-copy': '/assets/icons/collection/file-copy.svg',
    'file-check-list': '/assets/icons/collection/file-check-list.svg',
    'file-document-favorite': '/assets/icons/collection/file-document-favorite.svg',
    'file-document-previous': '/assets/icons/collection/file-document-previous.svg',
    'file-document-forward': '/assets/icons/collection/file-document-forward.svg',
    'file-document-text': '/assets/icons/collection/file-document-text.svg',
    'file-document-cloud': '/assets/icons/collection/file-document-cloud.svg',
    'file-clipboard-text': '/assets/icons/collection/file-clipboard-text.svg',
    'file-document-normal': '/assets/icons/collection/file-document-normal.svg',
    'ffile-clipboard-close': '/assets/icons/collection/file-clipboard-close.svg',
    'file-document-download': '/assets/icons/collection/file-document-download.svg',
    'file-note': '/assets/icons/collection/file-note.svg',
    'file-document-like': '/assets/icons/collection/file-document-like.svg',
    'file-document-filter': '/assets/icons/collection/file-document-filter.svg',
    'file-clipboard-export': '/assets/icons/collection/file-clipboard-export.svg',
    'file-clipboard-import': '/assets/icons/collection/file-clipboard-import.svg',
    'file-clipboard-tick': '/assets/icons/collection/file-clipboard-tick.svg',
    'file-archive-book': '/assets/icons/collection/file-archive-book.svg',
    'file-note-remove': '/assets/icons/collection/file-note-remove.svg',
    'infrastructure-cloud': '/assets/icons/collection/infrastructure-cloud.svg',
    'infrastructure-cloud-storage': '/assets/icons/collection/infrastructure-cloud-storage.svg',
    'infrastructure-cloud-loading': '/assets/icons/collection/infrastructure-cloud-loading.svg',
    'infrastructure-secure-cloud': '/assets/icons/collection/infrastructure-secure-cloud.svg',
    'infrastructure-cloud-monitoring': '/assets/icons/collection/infrastructure-cloud-monitoring.svg',
    'infrastructure-cloud-signal': '/assets/icons/collection/infrastructure-cloud-signal.svg',
    'infrastructure-cloud-chat': '/assets/icons/collection/infrastructure-cloud-chat.svg',
    'infrastructure-cloud-connection': '/assets/icons/collection/infrastructure-cloud-connection.svg',
    'infrastructure-block-storage': '/assets/icons/collection/infrastructure-block-storage.svg',
    'infrastructure-file-storage': '/assets/icons/collection/infrastructure-file-storage.svg',
    'infrastructure-router': '/assets/icons/collection/infrastructure-router.svg',
    'infrastructure-site-to-site': '/assets/icons/collection/infrastructure-site-to-site.svg',
    'infrastructure-network-protection': '/assets/icons/collection/infrastructure-network-protection.svg',
    'infrastructure-edge-network': '/assets/icons/collection/infrastructure-edge-network.svg',
    'infrastructure-hosting': '/assets/icons/collection/infrastructure-hosting.svg',
    'infrastructure-vpn': '/assets/icons/collection/infrastructure-vpn.svg',
    'infrastructure-resolver': '/assets/icons/collection/infrastructure-resolver.svg',
    'infrastructure-direct-connect': '/assets/icons/collection/infrastructure-direct-connect.svg',
    'infrastructure-gpu-frequency': '/assets/icons/collection/infrastructure-gpu-frequency.svg',
    'infrastructure-gpu': '/assets/icons/collection/infrastructure-gpu.svg',
    'infrastructure-server': '/assets/icons/collection/infrastructure-server.svg',
    'infrastructure-cpu-growth': '/assets/icons/collection/infrastructure-cpu-growth.svg',
    'infrastructure-cache': '/assets/icons/collection/infrastructure-cache.svg',
    'infrastructure-computing-gpu': '/assets/icons/collection/infrastructure-computing-gpu.svg',
    'infrastructure-anycast-server': '/assets/icons/collection/infrastructure-anycast-server.svg',
    'infrastructure-cpu': '/assets/icons/collection/infrastructure-cpu.svg',
    'infrastructure-ip': '/assets/icons/collection/infrastructure-ip.svg',
    'infrastructure-shrink': '/assets/icons/collection/infrastructure-shrink.svg',
    'infrastructure-expand': '/assets/icons/collection/infrastructure-expand.svg',
    'infrastructure-bare-metal': '/assets/icons/collection/infrastructure-bare-metal.svg',
    'infrastructure-cluster': '/assets/icons/collection/infrastructure-cluster.svg',
    'media-multimedia': '/assets/icons/collection/media-multimedia.svg',
    'media-video-hosting': '/assets/icons/collection/media-video-hosting.svg',
    'media-video-time': '/assets/icons/collection/media-video-time.svg',
    'media-store': '/assets/icons/collection/media-store.svg',
    'media-video': '/assets/icons/collection/media-video.svg',
    'media-transcoder': '/assets/icons/collection/media-transcoder.svg',
    'media-record-circle': '/assets/icons/collection/media-record-circle.svg',
    'media-video-convert': '/assets/icons/collection/media-video-convert.svg',
    'media-play-circle': '/assets/icons/collection/media-play-circle.svg',
    'media-broadcasting': '/assets/icons/collection/media-broadcasting.svg',
    'media-4-modes': '/assets/icons/collection/media-4-modes.svg',
    'media-image': '/assets/icons/collection/media-image.svg',
    'media-dvr': '/assets/icons/collection/media-dvr.svg',
    'media-adaptative': '/assets/icons/collection/media-adaptative.svg',
    'media-multichanel': '/assets/icons/collection/media-multichanel.svg',
    'media-megaphone': '/assets/icons/collection/media-megaphone.svg',
    'media-voice': '/assets/icons/collection/media-voice.svg',
    'media-playback': '/assets/icons/collection/media-playback.svg',
    'media-musicnote': '/assets/icons/collection/media-musicnote.svg',
    'media-game': '/assets/icons/collection/media-game.svg',
    'media-game-dedicated-channel': '/assets/icons/collection/media-game-dedicated-channel.svg',
    'media-pause': '/assets/icons/collection/media-pause.svg',
    'media-previous': '/assets/icons/collection/media-previous.svg',
    'media-next': '/assets/icons/collection/media-next.svg',
    'media-volume-cross': '/assets/icons/collection/media-volume-cross.svg',
    'media-play-filled': '/assets/icons/collection/media-play-filled.svg',
    'media-repeate-one': '/assets/icons/collection/media-repeate-one.svg',
    'media-streaming': '/assets/icons/collection/media-streaming.svg',
    'media-video-streaming': '/assets/icons/collection/media-video-streaming.svg',
    'media-mid-roll': '/assets/icons/collection/media-mid-roll.svg',
    'media-post-roll': '/assets/icons/collection/media-post-roll.svg',
    'media-repeat-roll': '/assets/icons/collection/media-repeat-roll.svg',
    'media-pre-roll': '/assets/icons/collection/media-pre-roll.svg',
    'media-mobile-device': '/assets/icons/collection/media-mobile-device.svg',
    'media-laptop': '/assets/icons/collection/media-laptop.svg',
    'logos-digg': '/assets/icons/collection/logos-digg.svg',
    'logos-discord': '/assets/icons/collection/logos-discord.svg',
    'logos-facebook': '/assets/icons/collection/logos-facebook.svg',
    'logos-flickr': '/assets/icons/collection/logos-flickr.svg',
    'logos-github': '/assets/icons/collection/logos-github.svg',
    'logos-glassdoor': '/assets/icons/collection/logos-glassdoor.svg',
    'logos-instagram': '/assets/icons/collection/logos-instagram.svg',
    'logos-linkedin': '/assets/icons/collection/logos-linkedin.svg',
    'logos-medium': '/assets/icons/collection/logos-medium.svg',
    'logos-pinterest': '/assets/icons/collection/logos-pinterest.svg',
    'logos-quora': '/assets/icons/collection/logos-quora.svg',
    'logos-skype': '/assets/icons/collection/logos-skype.svg',
    'logos-slack': '/assets/icons/collection/logos-slack.svg',
    'logos-snapchat': '/assets/icons/collection/logos-snapchat.svg',
    'logos-tumblr': '/assets/icons/collection/logos-tumblr.svg',
    'logos-twitter': '/assets/icons/collection/logos-twitter.svg',
    'logos-wechat': '/assets/icons/collection/logos-wechat.svg',
    'logos-xing': '/assets/icons/collection/logos-xing.svg',
    'logos-yelp': '/assets/icons/collection/logos-yelp.svg',
    'logos-youtube': '/assets/icons/collection/logos-youtube.svg',
    'logos-mastodon': '/assets/icons/collection/logos-mastodon.svg',
    'logos-google': '/assets/icons/collection/logos-google.svg',
    'logos-livestream': '/assets/icons/collection/logos-livestream.svg',
    'logos-http': '/assets/icons/collection/logos-http.svg',
    'logos-html-3': '/assets/icons/collection/logos-html-3.svg',
    'logos-javascript': '/assets/icons/collection/logos-javascript.svg',
    'logos-html-5': '/assets/icons/collection/logos-html-5.svg',
    'logos-brotli': '/assets/icons/collection/logos-brotli.svg',
    'logos-gdpr': '/assets/icons/collection/logos-gdpr.svg',
    'logos-terraform': '/assets/icons/collection/logos-terraform.svg',
    'logos-octodns': '/assets/icons/collection/logos-octodns.svg',
    'miscellaneous-grafana': '/assets/icons/collection/miscellaneous-grafana.svg',
    'security-key': '/assets/icons/collection/security-key.svg',
    'security-attack-shield': '/assets/icons/collection/security-attack-shield.svg',
    'security-lock': '/assets/icons/collection/security-lock.svg',
    'security-unlock': '/assets/icons/collection/security-unlock.svg',
    'security-encrypted-data': '/assets/icons/collection/security-unlock.svg',
    'security-2factor': '/assets/icons/collection/security-2factor.svg',
    'security-token': '/assets/icons/collection/security-token.svg',
    'security-looking-glass': '/assets/icons/collection/security-looking-glass.svg',
    'security-waf': '/assets/icons/collection/security-waf.svg',
    'security-bad-bot': '/assets/icons/collection/security-bad-bot.svg',
    'security-shield-advanced': '/assets/icons/collection/security-shield-advanced.svg',
    'security-skull': '/assets/icons/collection/security-skull.svg',
    'finances-money-dollar': '/assets/icons/collection/finances-money-dollar.svg',
    'finances-money-euro': '/assets/icons/collection/finances-money-euro.svg',
    'finances-fintech': '/assets/icons/collection/finances-fintech.svg',
    'finances-coin': '/assets/icons/collection/finances-coin.svg',
    'finances-discount': '/assets/icons/collection/finances-discount.svg',
    'finances-economy': '/assets/icons/collection/finances-economy.svg',
    'code-dev-tools': '/assets/icons/collection/code-dev-tools.svg',
    'code-api': '/assets/icons/collection/code-api.svg',
    'code-git-branch-2': '/assets/icons/collection/code-git-branch-2.svg',
    'code-creditcard-code': '/assets/icons/collection/code-creditcard-code.svg',
    'code-block': '/assets/icons/collection/code-block.svg',
    'code-jwt-decoder': '/assets/icons/collection/code-jwt-decoder.svg',
    'code-uuid-v4': '/assets/icons/collection/code-uuid-v4.svg',
    'code-atom-5': '/assets/icons/collection/code-atom-5.svg',
    'code-sdk': '/assets/icons/collection/code-sdk.svg',
    'code-bug': '/assets/icons/collection/code-bug.svg',
    'code-chip': '/assets/icons/collection/code-chip.svg',
    'code-base64-converter': '/assets/icons/collection/code-base64-converter.svg',
    'code-hash': '/assets/icons/collection/code-hash.svg',
    'code-unix': '/assets/icons/collection/code-unix.svg',
    'code-infipv4': '/assets/icons/collection/code-infipv4.svg',
    'code-infipv6': '/assets/icons/collection/code-infipv6.svg',
    'code-writing': '/assets/icons/collection/code-writing.svg',
    'miscellaneous-clock-check': '/assets/icons/collection/miscellaneous-clock-check.svg',
    'miscellaneous-ttl': '/assets/icons/collection/miscellaneous-ttl.svg',
    'miscellaneous-speed': '/assets/icons/collection/miscellaneous-speed.svg',
    'miscellaneous-timer': '/assets/icons/collection/miscellaneous-timer.svg',
    'miscellaneous-calendar': '/assets/icons/collection/miscellaneous-calendar.svg',
    'miscellaneous-calendar-time': '/assets/icons/collection/miscellaneous-calendar-time.svg',
    'miscellaneous-data-reload': '/assets/icons/collection/miscellaneous-data-reload.svg',
    'miscellaneous-data-box-delete': '/assets/icons/collection/miscellaneous-data-box-delete.svg',
    'miscellaneous-data-recover': '/assets/icons/collection/miscellaneous-data-recover.svg',
    'miscellaneous-data-migration': '/assets/icons/collection/miscellaneous-data-migration.svg',
    'miscellaneous-verify': '/assets/icons/collection/miscellaneous-verify.svg',
    'miscellaneous-award': '/assets/icons/collection/miscellaneous-award.svg',
    'miscellaneous-certificate': '/assets/icons/collection/miscellaneous-certificate.svg',
    'miscellaneous-trophy': '/assets/icons/collection/miscellaneous-trophy.svg',
    'miscellaneous-computer': '/assets/icons/collection/miscellaneous-computer.svg',
    'miscellaneous-mouse': '/assets/icons/collection/miscellaneous-mouse.svg',
    'miscellaneous-plane': '/assets/icons/collection/miscellaneous-plane.svg',
    'miscellaneous-car': '/assets/icons/collection/miscellaneous-car.svg',
    'miscellaneous-smart-car': '/assets/icons/collection/miscellaneous-smart-car.svg',
    'miscellaneous-truck': '/assets/icons/collection/miscellaneous-truck.svg',
    'miscellaneous-rocket': '/assets/icons/collection/miscellaneous-rocket.svg',
    'miscellaneous-drone': '/assets/icons/collection/miscellaneous-drone.svg',
    'miscellaneous-gps': '/assets/icons/collection/miscellaneous-gps.svg',
    'miscellaneous-tower': '/assets/icons/collection/miscellaneous-tower.svg',
    'miscellaneous-location': '/assets/icons/collection/miscellaneous-location.svg',
    'miscellaneous-radar': '/assets/icons/collection/miscellaneous-radar.svg',
    'miscellaneous-globe-grid': '/assets/icons/collection/miscellaneous-globe-grid.svg',
    'miscellaneous-map': '/assets/icons/collection/miscellaneous-map.svg',
    'miscellaneous-univer-learn': '/assets/icons/collection/miscellaneous-univer-learn.svg',
    'miscellaneous-redirect': '/assets/icons/collection/miscellaneous-redirect.svg',
    'miscellaneous-home': '/assets/icons/collection/miscellaneous-home.svg',
    'miscellaneous-buildings': '/assets/icons/collection/miscellaneous-buildings.svg',
    'miscellaneous-retail': '/assets/icons/collection/miscellaneous-retail.svg',
    'miscellaneous-shop-market': '/assets/icons/collection/miscellaneous-shop-market.svg',
    'miscellaneous-gift': '/assets/icons/collection/miscellaneous-gift.svg',
    'miscellaneous-package': '/assets/icons/collection/miscellaneous-package.svg',
    'miscellaneous-layers': '/assets/icons/collection/miscellaneous-layers.svg',
    'miscellaneous-ram': '/assets/icons/collection/miscellaneous-ram.svg',
    'miscellaneous-performance': '/assets/icons/collection/miscellaneous-performance.svg',
    'miscellaneous-statistic-up': '/assets/icons/collection/miscellaneous-statistic-up.svg',
    'miscellaneous-statistic-down': '/assets/icons/collection/miscellaneous-statistic-down.svg',
    'miscellaneous-chart-bar': '/assets/icons/collection/miscellaneous-chart-bar.svg',
    'miscellaneous-growth': '/assets/icons/collection/miscellaneous-growth.svg',
    'miscellaneous-discover': '/assets/icons/collection/miscellaneous-discover.svg',
    'miscellaneous-logs': '/assets/icons/collection/miscellaneous-logs.svg',
    'miscellaneous-storage': '/assets/icons/collection/miscellaneous-storage.svg',
    'miscellaneous-peering-connection': '/assets/icons/collection/miscellaneous-peering-connection.svg',
    'miscellaneous-hierarchy': '/assets/icons/collection/miscellaneous-hierarchy.svg',
    'miscellaneous-lamp': '/assets/icons/collection/miscellaneous-lamp.svg',
    'miscellaneous-diaster-recovery': '/assets/icons/collection/miscellaneous-diaster-recovery.svg',
    'miscellaneous-puzzle': '/assets/icons/collection/miscellaneous-puzzle.svg',
    'miscellaneous-multiplatform': '/assets/icons/collection/miscellaneous-multiplatform.svg',
    'miscellaneous-interface': '/assets/icons/collection/miscellaneous-interface.svg',
    'miscellaneous-containers': '/assets/icons/collection/miscellaneous-containers.svg',
    'miscellaneous-instances': '/assets/icons/collection/miscellaneous-instances.svg',
    'miscellaneous-eco': '/assets/icons/collection/miscellaneous-eco.svg',
    'miscellaneous-pet': '/assets/icons/collection/miscellaneous-pet.svg',
    'miscellaneous-group': '/assets/icons/collection/miscellaneous-group.svg',
    'miscellaneous-apps': '/assets/icons/collection/miscellaneous-apps.svg',
    'miscellaneous-exercice': '/assets/icons/collection/miscellaneous-exercice.svg',
    'miscellaneous-health': '/assets/icons/collection/miscellaneous-health.svg',
    'miscellaneous-card-remove': '/assets/icons/collection/miscellaneous-card-remove.svg',
    'miscellaneous-object': '/assets/icons/collection/miscellaneous-object.svg',
    'miscellaneous-custom-domain': '/assets/icons/collection/miscellaneous-custom-domain.svg',
    'miscellaneous-browser-protection': '/assets/icons/collection/miscellaneous-browser-protection.svg',
    'miscellaneous-factory': '/assets/icons/collection/miscellaneous-factory.svg',
    'miscellaneous-pie-chart': '/assets/icons/collection/miscellaneous-pie-chart.svg',
    'miscellaneous-block-storage': '/assets/icons/collection/miscellaneous-block-storage.svg',
    'miscellaneous-content': '/assets/icons/collection/miscellaneous-content.svg',
    'miscellaneous-ssl-certificate': '/assets/icons/collection/miscellaneous-ssl-certificate.svg',
    'miscellaneous-case-studies': '/assets/icons/collection/miscellaneous-case-studies.svg',
    'miscellaneous-seo': '/assets/icons/collection/miscellaneous-seo.svg',
    'miscellaneous-key-square': '/assets/icons/collection/miscellaneous-key-square.svg',
    'miscellaneous-access-manager': '/assets/icons/collection/miscellaneous-access-manager.svg',
    'miscellaneous-blockchain': '/assets/icons/collection/miscellaneous-blockchain.svg',
    'miscellaneous-reload': '/assets/icons/collection/miscellaneous-reload.svg',
};
