import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'splitString',
    standalone: true,
})
export class SplitStringPipe implements PipeTransform {
    public transform(value: string, separator: string = '\n'): Array<string> {
        if (!value) {
            return [];
        }
        const handledSeparator: Array<string> = value.split(separator);
        const handledRegularString: Array<string> =
            separator === '\n' ? handledSeparator.flatMap((v) => v.split('\\n')) : handledSeparator;
        return handledRegularString.map((v) => (v.length === 0 ? '&nbsp;' : v));
    }
}
