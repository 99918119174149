import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from 'angular-svg-icon';
import { ICONS_MAP } from '../../../core/constants';

export type SquareIconVariant =
    | 'primary'
    | 'brand'
    | 'white'
    | 'dark-primary'
    | 'transparent'
    | 'transparent-orange'
    | 'transparent-white';

export type SquareIconShape = 'square' | 'circle';

@Component({
    selector: 'gcore-square-icon',
    templateUrl: './square-icon.component.html',
    styleUrls: ['./square-icon.component.scss'],
    standalone: true,
    imports: [CommonModule, SvgIconComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SquareIconComponent {
    @Input() public iconName: string;
    @Input() public variant: SquareIconVariant = 'brand';
    @Input() public shape = 'square';

    public ICONS_MAP = ICONS_MAP;
}
