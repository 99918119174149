export enum RowItemEnum {
    Str = 'string',
    Bool = 'boolean',
    Combine = 'combine',
}

export enum RowItemIconEnum {
    Check = '/assets/icons/table-check.svg',
    Close = '/assets/icons/table-close.svg',
}

export interface TableRowConfigInterface {
    field: string;
    label: string;
    extraLabel?: string;
    isPrice?: boolean;
    type?: RowItemEnum;
}

export interface PriceTableConfig {
    highlightedColumnLabel?: string;
    firstColumnLabel?: string;
    rows: Array<TableRowConfigInterface>;
    customNoVat?: string;
}

export interface CompareTableConfig {
    footnote: Array<string>;
    rows: Array<TableRowConfigInterface>;
}

export interface TableHeaderEl<T> {
    fieldName: keyof T;
    label: string;
    text?: string;
}
