<div
    class="gc-language-selector"
    [class.gc-language-selector_mobile-menu]="mobileMenuItem"
    (clickOutside)="toggleDropdown(false)"
    gcoreClickOutside
>
    <button
        class="gc-selector-button gc-selector-button_main gc-button gc-bg_action-tertiary gc-width_full"
        [class.disabled]="availableLanguages.length <= 1"
        [class.selected]="isDropdownShown"
        [class.dark]="darkTheme"
        [class.mixed]="darkTheme === 'mixed'"
        [class.gc-justify_between]="mobileMenuItem"
        (click)="toggleDropdown()"
    >
        <div class="gc-flex gc-align-items_center">
            <svg-icon
                class="gc-icon gc-language-icon gc-display_inline-block gc-icon_primary gc-text-vertical_center"
                [class.dark]="darkTheme"
                src="assets/icons/header-new/globe-grid.svg"
            ></svg-icon>
            <span
                class="gc-text_primary gc-text-vertical_center"
                [class.dark]="darkTheme"
                [class.gc-label_xs]="!mobileMenuItem"
                [class.gc-label_sm]="mobileMenuItem"
                >{{ currentLanguage | uppercase }}</span
            >
        </div>
        <svg-icon
            *ngIf="mobileMenuItem"
            class="gc-icon gc-chevron gc-icon_primary"
            [class.dark]="darkTheme"
            src="assets/icons/header-new/chevron--down.svg"
        ></svg-icon>
    </button>
    <ul
        class="gc-dropdown gc-dropdown_{{
            position
        }}   gc-flex gc-flex_wrap gc-align-items_center gc-animation_fadein  gc-bg_primary  gc-width_full "
        *ngIf="isDropdownShown"
        [ngClass]="{
            'dark': darkTheme === true,
            'gc-bg_secondary': secondaryTheme,
            'gc-position_absolute': !mobileMenuItem,
            'gc-flex_column': !mobileMenuItem,
            'gc-elevation_lg': !mobileMenuItem,
            'gc-border-radius_lg': !mobileMenuItem,
               'gc-p_2': !mobileMenuItem,
               'gc-py_6': mobileMenuItem,
        }"
    >
        <li *ngFor="let lang of availableLanguages" [class.gc-width_full]="!mobileMenuItem">
            <button
                class="gc-selector-button gc-text_primary gc-bg_action-tertiary gc-width_full"
                [class.dark]="darkTheme === true"
                [class.selected]="lang === currentLanguage"
                [class.gc-width_full]="!mobileMenuItem"
                (click)="switchLanguage(lang)"
            >
                {{ lang | uppercase }}
            </button>
        </li>
    </ul>
</div>
