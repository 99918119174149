import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from 'angular-svg-icon';
import { LocalizationService } from '../../core/services';
import { LanguageEnum } from '../../core/enums';
import { ClickOutsideDirective } from '../../core/directives';
import { ButtonComponent } from '../../ui-kit/actions/button/button.component';

@Component({
    selector: 'gcore-language-selector',
    standalone: true,
    imports: [CommonModule, ClickOutsideDirective, SvgIconComponent, ButtonComponent],
    templateUrl: './language-selector.component.html',
    styleUrls: ['./language-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSelectorComponent implements OnInit {
    @Input() public position: 'top' | 'bottom' = 'bottom';
    @Input() public darkTheme: boolean | 'mixed';
    @Input() public secondaryTheme: boolean;
    @Input() public availableLanguages: Array<LanguageEnum>;
    @Input() public mobileMenuItem: boolean = false;
    public currentLanguage: LanguageEnum = LanguageEnum.EN;
    public isDropdownShown: boolean = false;

    constructor(private localizationService: LocalizationService) {}

    public ngOnInit(): void {
        this.currentLanguage = this.localizationService.getCurrentLocale();
    }

    public switchLanguage(locale: LanguageEnum): void {
        this.localizationService.switchLanguage(locale);
    }

    public toggleDropdown(isOpen?: boolean): void {
        if (isOpen !== undefined) {
            this.isDropdownShown = isOpen;
        } else {
            this.isDropdownShown = !this.isDropdownShown;
        }
    }
}
