import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export type LogoVariant = 'dark' | 'white' | 'beige' | 'orange' | 'dark-new';

@Component({
    selector: 'gcore-logo',
    templateUrl: './logo.component.html',
    styleUrls: ['./logo.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoComponent {
    @Input() public variant: LogoVariant = 'dark';
}
