import { Injectable } from '@angular/core';
import { marketingCookies, statisticCookies } from '../constants';
import { EnvironmentEnum, LoginLinksEnum, RegisterLinksEnum } from '../enums';
import config from '../../../config';

import { sourcebuster } from '../utils/sourcebuster';
import { LocalizationService } from './localization.service';
import { CookieService } from './cookie.service';

const analitycsHiddenFiledsMap: Record<string, string> = {
    utm_campaign: 'cmp',
    utm_content: 'cnt',
    utm_medium: 'mdm',
    utm_source: 'src',
    utm_term: 'trm',
    ga_client_id: '_ga',
};

const MAX_AGE = 1000 * 60 * 60 * 24 * 365 * 10;

export enum CookiesConsent {
    All = 'all',
    Marketing = 'marketing',
    Statistic = 'statistic',
    DeclineAll = 'decline_all',
}

@Injectable({
    providedIn: 'root',
})
export class AnalyticsService {
    public lastFormTarget: string;

    constructor(private localizationService: LocalizationService, private cookies: CookieService) {}

    public getCookies(): { [key: string]: string } {
        return document.cookie.split('; ').reduce((cookesMap: { [key: string]: string }, cookie: string) => {
            const [key, value] = cookie.split('=');
            if (key === '_ga') {
                cookesMap[key] = this.formatGaId(value);
            } else {
                cookesMap[key] = value;
            }
            return cookesMap;
        }, {});
    }

    public setUTMCookie(): void {
        document.body.addEventListener('gcoreCookieBannerConfirmed', (event) => {
            const cookies = this.getCookies();
            if (cookies.cookies_consent && cookies.cookies_consent !== 'decline_all') {
                sourcebuster.init();
                this.setHiddenFields(this.lastFormTarget);
                this.setHiddenFields('.gc-subscribe-form');
            }
        });
        const cookies = this.getCookies();
        if (cookies.cookies_consent && cookies.cookies_consent !== 'decline_all') {
            sourcebuster.init();
        }
    }

    public setHiddenFields(formTarget: string): void {
        Object.keys(analitycsHiddenFiledsMap).forEach((key) => {
            const targetInput = document.querySelector(`${formTarget} input[name=${key}]`) as HTMLInputElement;
            if (!targetInput) {
                return;
            }
            const value = analitycsHiddenFiledsMap[key];
            targetInput.value =
                (key === 'ga_client_id'
                    ? this.formatGaId(this.cookies.getCookie(value))
                    : sourcebuster.get?.current[value]) || '';
        });
        if (formTarget !== '.gc-subscribe-form') {
            this.lastFormTarget = formTarget;
        }
    }

    public getHiddenFieldsMarketo(): Record<string, string> {
        return Object.fromEntries(
            Object.entries(analitycsHiddenFiledsMap).map((item: [string, string]) => [
                item[0],
                (item[0] === 'ga_client_id'
                    ? this.formatGaId(this.cookies.getCookie(item[1]))
                    : sourcebuster.get?.current[item[1]]) || '',
            ]),
        );
    }

    public getSignUpUrlWithParams(
        link: RegisterLinksEnum | LoginLinksEnum = RegisterLinksEnum.Cloud,
        product?: string,
        customInfo?: string,
    ): string {
        const language = this.localizationService.getCurrentLocale();
        const separatorSymbol = link === RegisterLinksEnum.Hosting || link === LoginLinksEnum.Hosting ? '&' : '?';
        const productParam = product ? `&default_product=${product}` : '';
        customInfo = customInfo ? `&custom_info=${customInfo}` : '';

        if (config.environment === 'preprod') {
            if (link === RegisterLinksEnum.Cloud) link = RegisterLinksEnum.CloudPreprod;
            if (link === LoginLinksEnum.Cloud) link = LoginLinksEnum.CloudPreprod;
        }

        return `${link}${separatorSymbol}lang=${language}${productParam}${customInfo}`;
    }

    public applyCookiesConsent(): boolean {
        const cookies = this.getCookies();
        const cookiesConsent = cookies.cookies_consent;
        if (cookiesConsent) {
            switch (cookiesConsent) {
                case CookiesConsent.All:
                    break;
                case CookiesConsent.DeclineAll:
                    marketingCookies.concat(statisticCookies).forEach((cookie) => this.disableCookie(cookie, cookies));
                    break;
                case CookiesConsent.Marketing:
                    statisticCookies.forEach((cookie) => this.disableCookie(cookie, cookies));
                    break;
                case CookiesConsent.Statistic:
                    marketingCookies.forEach((cookie) => this.disableCookie(cookie, cookies));
                    break;
            }
            this.setCookiesConsent(cookies.cookies_consent as CookiesConsent);
            return false;
        } else {
            return true;
        }
    }

    public setCookiesConsent(value: CookiesConsent): void {
        document.cookie = `cookies_consent=${value}; max-age=${MAX_AGE}; domain=${
            config.environment === EnvironmentEnum.prod ? '.gcore.com' : '.preprod.world'
        };`;
    }

    private formatGaId(value: string): string {
        if (!value) {
            return '';
        }
        const firstDotIndex = value.indexOf('.');
        const secondDotIndex = value.indexOf('.', firstDotIndex + 1);
        return value.slice(secondDotIndex + 1);
    }

    private disableCookie(cookie: string, cookies: { [key: string]: string }): void {
        if (cookie.endsWith('*')) {
            const names = Object.keys(cookies).filter((cookieName) => cookieName.startsWith(cookie.replace('*', '')));
            names.forEach(
                (cookieName) =>
                    (document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=${window.location.hostname}`),
            );
        }
        document.cookie = `${cookie}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=${window.location.hostname}`;
    }
}
