import { APP_INITIALIZER, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoutingService } from './routing.service';
import { LanguageEnum } from './core/enums';

export const fallbackRoutes: Routes = [
    {
        path: '',
        loadComponent: () => import('./templates/site/site.component').then((c) => c.SiteComponent),
        children: [
            {
                path: 'page-not-found',
                loadComponent: () =>
                    import('./pages/page-not-found/page-not-found.component').then((c) => c.PageNotFoundComponent),
                data: {
                    availableLanguages: [LanguageEnum.EN],
                },
            },
            {
                path: 'contact-us',
                loadComponent: () =>
                    import('./templates/site-page/site-page.component').then((c) => c.SitePageComponent),
                data: {
                    availableLanguages: [LanguageEnum.EN],
                },
            },
            {
                path: '',
                loadComponent: () => import('./pages/home/home.component').then((c) => c.HomeComponent),
                data: {
                    availableLanguages: [LanguageEnum.EN],
                },
            },
        ],
    },
    { path: '**', redirectTo: 'page-not-found' },
];

@NgModule({
    imports: [
        RouterModule.forRoot(fallbackRoutes, {
            scrollPositionRestoration: 'enabled',
            anchorScrolling: 'enabled',
        }),
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: (routing: RoutingService) => () => routing.loadRoutes('root'),
            deps: [RoutingService],
            multi: true,
        },
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
