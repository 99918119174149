<ng-container *ngIf="loginModalContent">
    <gcore-modal
        [minPaddings]="false"
        [size]="ModalSize.Medium"
        [isOpen]="isLoginModalOpen"
        (isOpenChange)="toggleLoginModal(false)"
    >
        <gcore-modal-login
            [content]="loginModalContent"
            (closeModalEvent)="toggleLoginModal(false)"
            (navigate)="navigateToAuth($event)"
        ></gcore-modal-login>
    </gcore-modal>
    <gcore-modal
        [minPaddings]="false"
        [size]="ModalSize.Medium"
        [isOpen]="isRegisterModalOpen"
        (isOpenChange)="toggleRegisterModal(false)"
    >
        <gcore-modal-login
            [content]="loginModalContent"
            (closeModalEvent)="toggleRegisterModal(false)"
            (navigate)="navigateToAuth($event)"
        ></gcore-modal-login>
    </gcore-modal>
</ng-container>

<div class="gc-header-wrapper" *ngIf="content">
    <div class="gc-top-banner gc-top-banner_{{ variant }}">
        <div class="gc-content-wrapper">
            <div class="gc-promo-head gc-flex gc-flex-v-center">
                <div class="gc-promo-head-inner">
                    <span class="gc-promo-head-title gc-m-right_x-small">{{ content.bannerText }}</span>
                    <a
                        *ngIf="content.bannerLink.url.startsWith('http')"
                        [href]="content.bannerLink.url"
                        target="_blank"
                        tabindex="0"
                        gcoreAnalyticsEvent
                        [eventName]="'log4j_header'"
                        class="gc-link gc-link_contrast-primary gc-promo-link"
                        >{{ content.bannerLink.text | sliceDescription : 55 }}
                        <svg-icon src="assets/icons/header-new/arrow-right.svg"></svg-icon>
                    </a>
                    <a
                        *ngIf="content.bannerLink.url.startsWith('/')"
                        [routerLink]="content.bannerLink.url"
                        tabindex="0"
                        gcoreAnalyticsEvent
                        [eventName]="'log4j_header'"
                        class="gc-link gc-link_contrast-primary gc-promo-link"
                        >{{ content.bannerLink.text | sliceDescription : 55 }}
                        <svg-icon src="assets/icons/header-new/arrow-right.svg"></svg-icon>
                    </a>
                </div>
            </div>
            <div
                class="gc-top-banner-menu gc-flex gc-flex-v-center gc-mobile-hide"
                [class.gc-top-banner-menu-de]="locale === 'de'"
            >
                <a
                    [routerLink]="content.buttonUnderAttack.url"
                    tabindex="0"
                    gcoreAnalyticsEvent
                    [eventName]="'under-attack_header'"
                    class="gc-link gc-link_contrast-primary gc-desktop-show"
                    >{{ content.buttonUnderAttack.text }}</a
                >
                <button
                    *ngIf="content.buttonLogIn.text"
                    tabindex="0"
                    (click)="toggleLoginModal(true)"
                    class="gc-link gc-link_contrast-primary gc-desktop-show"
                >
                    {{ content.buttonLogIn.text }}
                </button>
                <gcore-language-selector
                    class="gc-display-md_none"
                    position="bottom"
                    darkTheme="mixed"
                    [availableLanguages]="availableLanguages"
                />
            </div>
        </div>
    </div>
    <div class="gc-main-header gc-header_{{ variant }}">
        <div class="gc-content-wrapper">
            <a aria-label="Gcore" *ngIf="!currentMenuItem" routerLink="./" tabindex="0">
                <gcore-logo class="logo-svg" [variant]="'orange'"></gcore-logo>
            </a>
            <button
                aria-label="link menu"
                tabindex="0"
                class="gc-back-button gc-text_truncate gc-text-align_left"
                *ngIf="currentMenuItem"
                (click)="navigateBack()"
            >
                <svg-icon class="gc-arrow-icon" src="assets/icons/header-new/arrow-left.svg"></svg-icon>
                <span class="gc-text_truncate">{{ currentMenuItem.title }}</span>
            </button>
            <div class="gc-flex gc-flex-v-center gc-hide-desktop gc-main-header-mobile">
                <button
                    #searchButtonMobile
                    tabindex="0"
                    class="gc-search-button gc-text-color_primary"
                    (click)="toggleSearchConsole()"
                >
                    <svg-icon src="assets/icons/header-new/search.svg"></svg-icon>
                </button>
                <button
                    class="gc-button gc-main-button-mobile gc-button_primary"
                    gcoreAnalyticsEvent
                    tabindex="0"
                    (click)="toggleRegisterModal(true)"
                >
                    {{ content.signUpLabel }}
                </button>
                <button
                    aria-label="link menu"
                    class="gc-menu-button gc-m-left_x-small"
                    (click)="toggleMenu()"
                    tabindex="0"
                >
                    <svg-icon
                        [src]="ICONS_MAP_NEW_HEADER[showMenu ? 'close-menu' : 'open-menu']"
                        class="gc-inherit-color"
                    ></svg-icon>
                </button>
            </div>

            <div class="gc-main-menu" [class.active]="showMenu" #mainMenu>
                <div class="gc-flex gc-main-menu-inner-wrapper gc-flex-v-center" #mainMenuWrapper>
                    <div class="gc-main-menu-list">
                        <div class="gc-flex gc-main-menu-list-inner gc-flex-v-center">
                            <gcore-expandable-menu-item
                                (currentMenuChange)="handleMenuChange($event)"
                                [menuConfig]="content.productsMenu"
                                [title]="content.productsMenuLabel"
                                [variant]="variant"
                                [minHeight]="2"
                                [isHoverable]="!isSearchPanelOpen"
                                (click)="setActiveMenuItem(1)"
                                [isActive]="activeMenuItem === 1"
                            >
                                <ng-template gcChildMenu let-children="children" let-menu="menu">
                                    <div class="gc-child-menu-columns gc-groupped-child-menu gc-grid gc-grid_3">
                                        <ul
                                            *ngFor="let childGroup of children | keyvalue : originalOrder"
                                            class="gc-child-menu-group-column"
                                        >
                                            <li
                                                class="gc-text gc-text_12 gc-m-bottom_large gc-menu-child-title"
                                                *ngIf="childGroup.key.indexOf('Column') === -1"
                                            >
                                                {{ childGroup.key }}
                                            </li>
                                            <li
                                                *ngFor="let childItem of childGroup.value"
                                                class="gc-m-bottom_medium gc-child-menu-item"
                                            >
                                                <a
                                                    *ngIf="
                                                        !(
                                                            childItem.url.startsWith('http') ||
                                                            childItem.url.startsWith('/')
                                                        )
                                                    "
                                                    tabindex="0"
                                                    [routerLink]="childItem.url"
                                                    class="gc-flex gc-flex-v-center gc-flex-row-wrap gc-link gc-link_primary gc-menu-item-inner"
                                                >
                                                    <div class="gc-menu-content">
                                                        <svg-icon
                                                            class="gc-inherit-color gc-menu-icon"
                                                            [src]="ICONS_MAP_NEW_HEADER[childItem.iconName]"
                                                        ></svg-icon>
                                                        <p class="gc-menu-title gc-gap_2">
                                                            {{ childItem.label }}
                                                            <gcore-tag
                                                                *ngIf="childItem.tag"
                                                                [content]="{ label: childItem.tag }"
                                                            />
                                                        </p>
                                                    </div>
                                                    <p
                                                        *ngIf="!isTablet; else cleanDescription"
                                                        class="gc-text gc-text_14 gc-m-top_xx-small gc-menu-description"
                                                    >
                                                        {{ childItem.description }}
                                                    </p>
                                                    <ng-template #cleanDescription>
                                                        <p
                                                            class="gc-text gc-text_14 gc-m-top_xx-small gc-menu-description"
                                                        >
                                                            {{ childItem.description }}
                                                        </p>
                                                    </ng-template>
                                                </a>
                                                <a
                                                    *ngIf="
                                                        childItem.url.startsWith('http') ||
                                                        childItem.url.startsWith('/')
                                                    "
                                                    [href]="childItem.url"
                                                    tabindex="0"
                                                    class="gc-flex gc-flex-v-center gc-flex-row-wrap gc-link gc-link_primary gc-menu-item-inner"
                                                >
                                                    <div class="gc-menu-content">
                                                        <svg-icon
                                                            class="gc-inherit-color gc-menu-icon"
                                                            [src]="ICONS_MAP_NEW_HEADER[childItem.iconName]"
                                                        ></svg-icon>
                                                        <p class="gc-menu-title">
                                                            {{ childItem.label }}
                                                            <span class="gc-menu-title-tag" *ngIf="childItem.tag">{{
                                                                childItem.tag
                                                            }}</span>
                                                        </p>
                                                    </div>
                                                    <p
                                                        *ngIf="!isTablet; else cleanDescription"
                                                        class="gc-text gc-text_14 gc-m-top_xx-small gc-menu-description"
                                                    >
                                                        {{ childItem.description }}
                                                    </p>
                                                    <ng-template #cleanDescription>
                                                        <p
                                                            class="gc-text gc-text_14 gc-m-top_xx-small gc-menu-description"
                                                        >
                                                            {{ childItem.description }}
                                                        </p>
                                                    </ng-template>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </ng-template>
                            </gcore-expandable-menu-item>
                            <gcore-expandable-menu-item
                                (currentMenuChange)="handleMenuChange($event)"
                                [menuConfig]="content.solutionsMenu"
                                [title]="content.solutionsMenuLabel"
                                [variant]="variant"
                                [minHeight]="1"
                                [isHoverable]="!isSearchPanelOpen"
                                (click)="setActiveMenuItem(2)"
                                [isActive]="activeMenuItem === 2"
                            >
                                <ng-template gcChildMenu let-children="children" let-menu="menu">
                                    <div
                                        class="gc-child-menu-columns gc-groupped-child-menu gc-solution-child-menu gc-grid gc-grid_3"
                                    >
                                        <div *ngFor="let childrenGroup of children">
                                            <ul
                                                *ngFor="let childGroup of childrenGroup | keyvalue : originalOrder"
                                                class="gc-child-menu-group-column"
                                            >
                                                <li class="gc-text gc-text_12 gc-m-bottom_large gc-menu-child-title">
                                                    {{ childGroup.key }}
                                                </li>
                                                <li
                                                    *ngFor="let childItem of childGroup.value"
                                                    class="gc-m-bottom_medium gc-child-menu-item"
                                                >
                                                    <a
                                                        *ngIf="
                                                            childItem.url.startsWith('/') ||
                                                            childItem.url.startsWith('http')
                                                        "
                                                        href="{{ childItem.url }}"
                                                        tabindex="0"
                                                        class="gc-link gc-link_primary gc-child-menu-link"
                                                    >
                                                        {{ childItem.label }}
                                                    </a>
                                                    <a
                                                        *ngIf="
                                                            !(
                                                                childItem.url.startsWith('/') ||
                                                                childItem.url.startsWith('http')
                                                            )
                                                        "
                                                        [routerLink]="childItem.url"
                                                        tabindex="0"
                                                        class="gc-link gc-link_primary gc-child-menu-link"
                                                    >
                                                        {{ childItem.label }}
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </ng-template>
                            </gcore-expandable-menu-item>
                            <div
                                class="gc-link-menu-item gc-p-top_x-small gc-p-bottom_x-small gc-p-left_small gc-p-right_small gc-flex gc-flex-v-center"
                            >
                                <a
                                    [routerLink]="content.pricingMenu.url"
                                    class="gc-link gc-link_primary gc-flex gc-flex-h-space-between gc-flex-v-center"
                                    (click)="toggleMenu()"
                                    tabindex="0"
                                    >{{ content.pricingMenuLabel }}</a
                                >
                            </div>
                            <gcore-expandable-menu-item
                                (currentMenuChange)="handleMenuChange($event)"
                                [menuConfig]="content.resourcesMenu"
                                [title]="content.resourcesMenuLabel"
                                [variant]="variant"
                                [oneLevelMenu]="true"
                                [showExtendedMenuContent]="true"
                                [isHoverable]="!isSearchPanelOpen"
                                (click)="setActiveMenuItem(3)"
                                [isActive]="activeMenuItem === 3"
                            >
                                <ng-template gcChildMenu let-children="children" let-menu="menu">
                                    <ul class="gc-child-menu-columns gc-one-lvl-menu">
                                        <li *ngFor="let childItem of children" class="gc-child-menu-item">
                                            <a
                                                *ngIf="
                                                    childItem.url.startsWith('/') || childItem.url.startsWith('http')
                                                "
                                                [attr.target]="childItem.openInNewTab ? '_blank' : null"
                                                href="{{ childItem.url }}"
                                                tabindex="0"
                                                class="gc-flex gc-flex-v-center gc-link gc-link_primary"
                                            >
                                                {{ childItem.label }}
                                            </a>
                                            <a
                                                *ngIf="
                                                    !(childItem.url.startsWith('/') || childItem.url.startsWith('http'))
                                                "
                                                [routerLink]="childItem.url"
                                                tabindex="0"
                                                class="gc-flex gc-flex-v-center gc-link gc-link_primary"
                                            >
                                                {{ childItem.label }}
                                            </a>
                                        </li>
                                    </ul>
                                </ng-template>
                            </gcore-expandable-menu-item>

                            <gcore-expandable-menu-item
                                (currentMenuChange)="handleMenuChange($event)"
                                [menuConfig]="content.partnersMenu"
                                [title]="content.partnersMenuLabel"
                                [variant]="variant"
                                [oneLevelMenu]="true"
                                [showExtendedMenuContent]="true"
                                [isHoverable]="!isSearchPanelOpen"
                                (click)="setActiveMenuItem(4)"
                                [isActive]="activeMenuItem === 4"
                            >
                                <ng-template gcChildMenu let-children="children" let-menu="menu">
                                    <ul class="gc-child-menu-columns gc-one-lvl-menu">
                                        <li *ngFor="let childItem of children" class="gc-child-menu-item">
                                            <a
                                                *ngIf="
                                                    childItem.url.startsWith('/') || childItem.url.startsWith('http')
                                                "
                                                [attr.target]="childItem.openInNewTab ? '_blank' : null"
                                                href="{{ childItem.url }}"
                                                tabindex="0"
                                                class="gc-flex gc-flex-v-center gc-link gc-link_primary"
                                            >
                                                {{ childItem.label }}
                                            </a>
                                            <a
                                                *ngIf="
                                                    !(childItem.url.startsWith('/') || childItem.url.startsWith('http'))
                                                "
                                                [routerLink]="childItem.url"
                                                tabindex="0"
                                                class="gc-flex gc-flex-v-center gc-link gc-link_primary"
                                            >
                                                {{ childItem.label }}
                                            </a>
                                        </li>
                                    </ul>
                                </ng-template>
                            </gcore-expandable-menu-item>

                            <gcore-expandable-menu-item
                                (currentMenuChange)="handleMenuChange($event)"
                                [menuConfig]="content.whyMenu"
                                [title]="content.whyMenuLabel"
                                [variant]="variant"
                                [oneLevelMenu]="true"
                                [showExtendedMenuContent]="true"
                                [isHoverable]="!isSearchPanelOpen"
                                (click)="setActiveMenuItem(5)"
                                [isActive]="activeMenuItem === 5"
                                [isLast]="true"
                            >
                                <ng-template gcChildMenu let-children="children" let-menu="menu">
                                    <ul class="gc-child-menu-columns gc-one-lvl-menu">
                                        <li *ngFor="let childItem of children" class="gc-child-menu-item">
                                            <a
                                                *ngIf="
                                                    childItem.url.startsWith('/') || childItem.url.startsWith('http')
                                                "
                                                [attr.target]="childItem.openInNewTab ? '_blank' : null"
                                                tabindex="0"
                                                href="{{ childItem.url }}"
                                                class="gc-flex gc-flex-v-center gc-link gc-link_primary"
                                            >
                                                {{ childItem.label }}
                                            </a>
                                            <a
                                                *ngIf="
                                                    !(childItem.url.startsWith('/') || childItem.url.startsWith('http'))
                                                "
                                                [routerLink]="childItem.url"
                                                tabindex="0"
                                                class="gc-flex gc-flex-v-center gc-link gc-link_primary"
                                            >
                                                {{ childItem.label }}
                                            </a>
                                        </li>
                                    </ul>
                                </ng-template>
                            </gcore-expandable-menu-item>
                        </div>
                    </div>
                    <button
                        #searchButton
                        class="gc-search-button gc-desktop-show"
                        (click)="toggleSearchConsole()"
                        tabindex="0"
                    >
                        <svg-icon src="assets/icons/header-new/search.svg"></svg-icon>
                    </button>
                    <div class="gc-link-menu-item gc-link-menu-item-bottom gc-hide-desktop">
                        <svg-icon
                            class="gc-menu-bottom-image"
                            [src]="ICONS_MAP_NEW_HEADER[content.buttonUnderAttack.iconName]"
                        ></svg-icon>
                        <a
                            [routerLink]="content.buttonUnderAttack.url"
                            gcoreAnalyticsEvent
                            tabindex="0"
                            [eventName]="'under-attack_header'"
                            class="gc-link"
                            (click)="toggleMenu()"
                            >{{ content.buttonUnderAttack.text }}</a
                        >
                    </div>
                    <div class="gc-link-menu-item gc-link-menu-item-bottom gc-hide-desktop">
                        <svg-icon
                            class="gc-menu-bottom-image"
                            [src]="ICONS_MAP_NEW_HEADER[content.buttonLogIn.iconName]"
                        ></svg-icon>
                        <button
                            *ngIf="content.buttonLogIn.text"
                            (click)="toggleLoginModal(true)"
                            tabindex="0"
                            class="gc-link gc-button-link"
                        >
                            {{ content.buttonLogIn.text }}
                        </button>
                    </div>
                    <gcore-language-selector
                        [mobileMenuItem]="true"
                        position="bottom"
                        class="gc-desktop-hide"
                        [availableLanguages]="availableLanguages"
                    />
                    <div class="gc-link-menu-item gc-link-menu-item-bottom gc-hide-desktop">
                        <svg-icon
                            class="gc-menu-bottom-image"
                            [src]="ICONS_MAP_NEW_HEADER[content.contactUsButton.iconName]"
                        ></svg-icon>
                        <a [routerLink]="content.contactUsButton.url" class="gc-link" tabindex="0">
                            {{ content.contactUsButton.text }}
                        </a>
                    </div>
                    <div class="gc-flex gc-flex-v-center gc-buttons-block gc-m-left_x-small" #buttonBlock>
                        <a
                            [routerLink]="content.contactUsButton.url"
                            tabindex="0"
                            class="gc-link gc-button-link gc-text_15 gc-text-semibold gc-button"
                        >
                            {{ content.contactUsButton.text }}
                        </a>
                        <a
                            class="gc-button gc-button_primary"
                            gcoreAnalyticsEvent
                            tabindex="0"
                            (click)="toggleRegisterModal(true)"
                            [target]="'_blank'"
                        >
                            {{ content.signUpLabel }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <gcore-search *ngIf="isSearchPanelOpen" [content]="content.searchConsole" />
</div>
