import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { LocalizationService } from '../services';

@Injectable({ providedIn: 'root' })
export class LocalizationResolver {
    constructor(private localizationService: LocalizationService) {}

    public resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
        this.localizationService.resolveLanguage(route.data.locale);
        return of(true);
    }
}
