export const breadcrumbsProductName: Record<string, string> = {
    ['/about']: 'About Gcore',
    ['/cdn/gaming']: 'CDN for Gaming',
    ['/cloud/ai-gpu']: 'AI GPU Cloud',
    ['/cloud/basic-vm']: 'Virtual Machines',
    ['/cloud/faas']: 'Function as a Service',
    ['/cloud/intel-sgx']: 'Intel-SGX',
    ['/cloud/secure-cloud-computing']: 'Secure Cloud Computing',
    ['/cloud/solutions/erp']: 'ERP in the Cloud',
    ['/ddos-protection/servers']: 'Game server DDoS Protection',
    ['/development']: 'Software Development',
    ['/dns']: 'DNS Hosting',
    ['/hosting/dedicated']: 'Dedicated Server',
    ['/hosting/dedicated/gpu']: 'GPU Powered Dedicated Server',
    ['/hosting/vds']: 'Virtual Dedicated Server',
    ['/hosting/ssl']: 'SSL Certificate',
    ['/legal']: 'Legal information',
    ['/mobile/5g']: '5G eSIM Technology',
    ['/partners/intel/icelakes']: 'Intel Ice Lake',
    ['/partners/intel/new-generation']: 'Intel New Generation',
    ['/pricing/cloud']: 'Cloud Pricing',
    ['/pricing/edge-network']: 'Edge Network Pricing',
    ['/pricing/storage']: 'Storage Pricing',
    ['/pricing/streaming-platform']: 'Video Streaming Platform',
    ['/streaming-platform/live']: 'Live Streaming',
    ['/streaming-platform/metaverse']: 'Metaverse Streaming',
    ['/streaming-platform/realtime']: 'Real-Time Video & WebRTC',
    ['/web-security']: 'Web Application Security',
    ['/whitelabel']: 'White Label Solutions',
};

export const breadcrumbsExcludedRoutes = ['mobile', 'partners', 'cloud/solutions'];
