import { Injectable } from '@angular/core';
import algoliasearch from 'algoliasearch/lite';
import { Router } from '@angular/router';
import { BehaviorSubject, debounceTime, distinctUntilChanged, from, map, Observable, of, switchMap, tap } from 'rxjs';
import config from '../../../config';
import { LanguageEnum } from '../enums';
import { toggleScroll } from '../utils/scroll-toggle';
import { LocalizationService } from './localization.service';

const algoliaInstance = algoliasearch(config.algoliaID, config.algoliaSearchKey);
const algoliaIndex = algoliaInstance.initIndex(config.environment === 'prod' ? 'site_prod' : 'site_preprod');

@Injectable({ providedIn: 'root' })
export class SearchService {
    public key$ = new BehaviorSubject<string>('');
    public isSearchConsoleOpened$ = new BehaviorSubject(false);
    private key = '';
    private locale: LanguageEnum;
    public isOpened: boolean = false;
    private suggestionNumber = 3;

    constructor(private localizationService: LocalizationService, private router: Router) {}

    public toggleSearchConsole(isOpened?: boolean, isToggleScroll: boolean = true): void {
        this.locale = this.localizationService.getCurrentLocale() as LanguageEnum;
        this.suggestionNumber =
            this.locale === LanguageEnum.ZH || this.locale === LanguageEnum.JA || this.locale === LanguageEnum.KO
                ? 1
                : 3;
        this.isOpened = isOpened !== undefined ? isOpened : !this.isOpened;
        this.isSearchConsoleOpened$.next(this.isOpened);
        if (isToggleScroll) toggleScroll(this.isOpened);
    }

    public search(topSearchResults: any): Observable<any> {
        return this.key$.pipe(
            tap((key) => {
                this.key = key;
            }),
            debounceTime(200),
            distinctUntilChanged(),
            switchMap((value) => {
                if (value.length >= this.suggestionNumber) {
                    return from(
                        algoliaIndex.search(value.trim(), {
                            hitsPerPage: 5,
                            filters: `lang:${this.locale} AND is_available:true`,
                        }),
                    );
                }
                return of(null);
            }),
            map((results) => {
                if (results === null) {
                    return {
                        status: 'default',
                        hits: topSearchResults,
                    };
                }
                if (results?.hits.length > 0) {
                    return {
                        status: 'results',
                        hits: results.hits,
                    };
                }
                return {
                    status: 'no-results',
                    hits: topSearchResults,
                };
            }),
        );
    }

    public goToSearchPage(): void {
        if (this.key.length) {
            this.router.navigate([`${this.locale === LanguageEnum.EN ? '' : this.locale}/search`], {
                queryParams: { key: encodeURI(this.key) },
            });
        }
    }
}
