import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject, Observable } from 'rxjs';
import { CURRENCY_SYMBOLS, CurrencyEnum } from '../enums';
import { CookieService } from './cookie.service';

@Injectable({
    providedIn: 'root',
})
export class CurrencyService {
    private currencyQueryKey: string = 'currency';
    private initialValue: CurrencyEnum = CurrencyEnum.EUR;
    private currencyBS$: BehaviorSubject<CurrencyEnum>;

    public currency$: Observable<CurrencyEnum>;

    constructor(@Inject(DOCUMENT) private document: Document, private cookieService: CookieService) {
        const init = this.getCurrencyCookieValue();
        this.currencyBS$ = new BehaviorSubject<CurrencyEnum>(init);
        this.currency$ = this.currencyBS$.asObservable();
    }

    public getCurrencySymbol(): string {
        return CURRENCY_SYMBOLS[this.currencyBS$.getValue()];
    }

    public checkQueryString(): void {
        const encodedSearchString: string = encodeURIComponent(window.location.search);
        const encodedKey: string = encodeURIComponent(`${this.currencyQueryKey}=`);

        if (encodedSearchString.includes(encodedKey)) {
            const currencyQueryValue: string = this.getCurrencyQueryValue(encodedSearchString);
            this.setCurrencyCookie(currencyQueryValue);
            // this.removeQueryStringFromUrl(); // if need to clear search params
        }
    }

    public setCurrencyCookie(currency: CurrencyEnum | string): void {
        if (currency === CurrencyEnum.USD || currency === CurrencyEnum.EUR) {
            this.cookieService.setCookie(this.currencyQueryKey, currency);
            this.currencyBS$.next(currency);
        }
    }

    public getCurrencyCookieValue(): CurrencyEnum {
        return (this.cookieService.getCookie(this.currencyQueryKey) as CurrencyEnum) || this.initialValue;
    }

    private getCurrencyQueryValue(encodedSearchString: string): string {
        const currencyKeyValue = encodedSearchString
            .split(encodeURIComponent('&'))
            .find((part) => part.includes(this.currencyQueryKey));

        return currencyKeyValue.split(encodeURIComponent('='))[1];
    }

    private removeQueryStringFromUrl(): void {
        window.history.replaceState({}, this.document.title, this.document.location.pathname);
    }
}
