import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalizationService } from '../../services';

interface UrlWithQueryParams {
    url: string;
    attr: string;
    value: string;
}

const innerPaths = ['blog', 'learning', 'docs', 'news', 'events', 'press', 'case-studies', 'library', 'interview'];

@Directive({
    selector: '[gcoreLink]',
    standalone: true,
})
export class LinkDirective implements OnInit, OnDestroy {
    @Input() public gcoreLink: string;

    private removeEventListener: () => void;

    constructor(
        private elementRef: ElementRef,
        private sanitizer: DomSanitizer,
        private renderer: Renderer2,
        private route: ActivatedRoute,
        private router: Router,
        private localizationService: LocalizationService,
    ) {}

    public ngOnInit(): void {
        const element = this.elementRef.nativeElement as HTMLLinkElement;
        const sanitizedUrl = this.sanitizer.sanitize(SecurityContext.URL, this.gcoreLink) || '';

        if (sanitizedUrl.startsWith('http')) {
            this.renderer.setAttribute(element, 'href', sanitizedUrl);
            this.renderer.setAttribute(element, 'target', '_blank');
            return;
        }

        if (sanitizedUrl.startsWith('+')) {
            this.renderer.setAttribute(element, 'href', `tel:${sanitizedUrl}`);
            return;
        }

        if (sanitizedUrl.startsWith('download:')) {
            const urlSplit = sanitizedUrl.split('/');
            this.renderer.setAttribute(element, 'href', sanitizedUrl.replace('download:', ''));
            this.renderer.setAttribute(element, 'download', urlSplit[urlSplit.length - 1].split('.')[0]);
            this.renderer.setAttribute(element, 'target', '_blank');
            return;
        }

        if (sanitizedUrl.includes('@')) {
            this.renderer.setAttribute(element, 'href', `mailto:${sanitizedUrl}`);
            return;
        }

        if (sanitizedUrl.startsWith('#')) {
            this.renderer.setAttribute(element, 'href', `${location.pathname}${sanitizedUrl}`);
            return;
        }

        const trimmedUrl = sanitizedUrl.replace(/^\.?\.?\//, '');
        const localizedUrl =
            trimmedUrl.length + 1 < sanitizedUrl.length
                ? `/${trimmedUrl}`
                : `/${this.localizationService.getLanguageRoute()}${trimmedUrl}`;

        if (innerPaths.some((path) => trimmedUrl.startsWith(path))) {
            this.renderer.setAttribute(element, 'target', '_self');
            this.renderer.setAttribute(element, 'href', localizedUrl);
            return;
        }

        this.renderer.setAttribute(element, 'href', localizedUrl);
        this.addEventListenerToLink(localizedUrl);
    }

    public ngOnDestroy(): void {
        if (this.removeEventListener) {
            this.removeEventListener();
        }
    }

    private addEventListenerToLink(url: string): void {
        this.removeEventListener = this.renderer.listen(this.elementRef.nativeElement, 'click', (e) => {
            if (e.ctrlKey || e.metaKey) {
                return;
            }
            e.preventDefault();
            const urlConfig = this.getUrlParams(url);
            this.router
                .navigate([urlConfig.rawUrl], {
                    relativeTo: this.route,
                    fragment: urlConfig.fragment,
                    queryParams: urlConfig.queryParams,
                })
                .then(() => {});
        });
    }

    private getUrlParams(url: string): any {
        const splitByFragmentUrl = url.split('#');
        const fragment = splitByFragmentUrl?.[1];
        const splitByParams = splitByFragmentUrl[0].split('?');
        const rawUrl = splitByParams[0];
        const paramList = splitByParams?.[1]?.split('&');
        const queryParams = paramList ? Object.fromEntries(paramList.map((param) => param.split('='))) : undefined;

        return {
            rawUrl,
            queryParams,
            fragment,
        };
    }
}
