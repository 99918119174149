export enum MapMarkerType {
    Active = 'active',
    Planned = 'planned',
    Enterprise = 'enterprise',
    SecondaryActive = 'secondary-active',
    SecondaryPlanned = 'secondary-planned',
    User = 'user',
    Custom = 'custom',
}

export enum MapNetworkType {
    CLOUD = 'cloud',
    NETWORK = 'network',
}

export interface Legend {
    value: string;
    type: MapMarkerType;
}

interface MapMarkerBase {
    locationName: string;
    displayName?: string;
    locationGroup?: string;
    serverLocation?: string;
    iconName?: string;
    type: MapMarkerType;
    latency?: number;
}

export interface MapLocation extends MapMarkerBase {
    latitude: number;
    longitude: number;
    tags?: string;
}

export interface MapMarker extends MapMarkerBase {
    x: number;
    y: number;
    tags?: { [key: string]: string };
}

export interface Location {
    latitude: number;
    longitude: number;
    locationName: string;
    type: MapMarkerType;
}
