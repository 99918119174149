import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class UserAgentService {
    public countryCode: string;

    constructor(private http: HttpClient) {}

    public getUserIpData(): void {
        this.http.get('https://iam.gcdn.co/info/json').subscribe((data: any) => {
            this.countryCode = data['Client Country code'];
        });
    }
}
