<div class="gc-modal-login">
    <button class="gc-close-button" (click)="closeModal()">
        <svg-icon src="assets/icons/close.svg" />
    </button>
    <h2
        class="gc-modal-header gc-text_32 gc-text_bold gc-text-align_center gc-m-bottom_x-large gc-mobile-m-bottom_large"
    >
        {{ content.title }}
    </h2>
    <div class="gc-login gc-login-recommended gc-m-bottom_medium">
        <div class="gc-login-title">{{ content.recommendedLabel }}</div>
        <div class="gc-login-content">
            <div class="gc-login-header">
                <span class="gc-login-subtitle-text"
                    ><svg-icon
                        [src]="'/assets/icons/cloud.svg'"
                        class="gc-inherit-color gc-login-svg-cloud"
                        [svgClass]="'gc-login-svg-stroke'"
                    ></svg-icon>
                    {{ content.edgeTitle }}</span
                >
                <a class="gc-link gc-link-arrow" (click)="navigate.emit(ModalLoginEmitEnum.Cloud)"
                    >{{ content.edgeLinkLabel }} &rarr;</a
                >
            </div>
            <div class="gc-login-products" *ngIf="{ isMobile: isMobile$ | async } as param">
                <p class="gc-text gc-text_regular gc-m-top_small">{{ content.productsLabel }}</p>
                <ul class="gc-m-top_small">
                    <gcore-list-item-checked
                        *ngFor="
                            let product of content.edgeProductsList
                                | slice : 0 : (param.isMobile && !showAllProducts ? 3 : 15)
                        "
                        [text]="product"
                        role="listitem"
                    />
                </ul>
                <div *ngIf="param.isMobile && !showAllProducts" (click)="showAll()" class="gc-login-show-all">
                    {{ content.showFullListLabel }}
                </div>
            </div>
        </div>
    </div>

    <div class="gc-login">
        <div class="gc-login-content">
            <div class="gc-login-header">
                <span class="gc-login-subtitle-text"
                    ><svg-icon
                        [src]="'/assets/icons/dns.svg'"
                        class="gc-inherit-color gc-login-svg-cloud"
                        [svgClass]="'gc-login-svg-stroke'"
                    ></svg-icon>
                    {{ content.hostingTitle }}</span
                >
                <a class="gc-link gc-link-arrow gc-login-link_mute" (click)="navigate.emit(ModalLoginEmitEnum.Hosting)"
                    >{{ content.hostingLinkLabel }} &rarr;</a
                >
            </div>
            <div class="gc-login-products">
                <p class="gc-text gc-text_regular gc-m-top_small">{{ content.productsLabel }}</p>
                <ul class="gc-m-top_small">
                    <gcore-list-item-checked
                        *ngFor="let product of content.hostingProductsList"
                        [text]="product"
                        class="gc-m-bottom_none"
                        role="listitem"
                    />
                </ul>
            </div>
        </div>
    </div>
</div>
