export const splitArrayToChunks = (array: Array<any>, chunkSize = 1): Array<Array<any>> => {
    const result = [];

    for (let i = 0; i < array.length; i += chunkSize) {
        const chunk = array.slice(i, i + chunkSize);
        result.push(chunk);
    }

    return result;
};
