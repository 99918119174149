import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from 'angular-svg-icon';
import { ICONS_COLLECTION } from '../../../core/constants';
import { TagContent } from './tag.model';

@Component({
    selector: 'gcore-tag',
    standalone: true,
    imports: [CommonModule, SvgIconComponent],
    templateUrl: './tag.component.html',
    styleUrls: ['./tag.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagComponent {
    @Input() public content: TagContent;
    @Input() public iconPosition: 'left' | 'right' = 'right';
    @Input() public darkTheme: boolean = false;
    @Input() public type: 'neutral' | 'brand' = 'brand';
    protected readonly ICONS_COLLECTION = ICONS_COLLECTION;
}
