import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, ActivatedRouteSnapshot, Event, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { filter, Observable, tap } from 'rxjs';
import { isScullyRunning } from '@scullyio/ng-lib';
import { DOCUMENT } from '@angular/common';
import config from '../../../config';
import { createBreadCrumbs, insertHeadSeoScript } from '../utils/seo/seo-schema';
import { LanguageEnum } from '../enums';
import { ImageMediaRaw } from '../models/Media';

@Injectable({
    providedIn: 'root',
})
export class SeoService {
    private render: Renderer2;

    constructor(
        private readonly title: Title,
        private meta: Meta,
        private activatedRoute: ActivatedRoute,
        public router: Router,
        private rendererFactory: RendererFactory2,
        @Inject(DOCUMENT) private document: Document,
    ) {
        this.render = rendererFactory.createRenderer(null, null);
    }

    private _isCaptchaOnPage = false;

    public get isCaptchaOnPage(): boolean {
        return this._isCaptchaOnPage;
    }

    public set isCaptchaOnPage(value: boolean) {
        this._isCaptchaOnPage = value;
    }

    public addNoIndexTag(): void {
        if (config.environment === 'preprod') {
            this.meta.addTag({ name: 'robots', content: 'noindex, nofollow' });
            this.meta.addTag({
                name: 'ahrefs-site-verification',
                content: '485c75b4075240e83007bc97f02920c7da086265822292d779184e3ff0bbda3a',
            });
        }
    }

    public routerChangeMetaUpdate(renderer: Renderer2): Observable<Event> {
        return this.router.events.pipe(
            filter((e) => e instanceof NavigationEnd),
            tap((data) => {
                const lastChildRouteSnapshot: ActivatedRouteSnapshot = this.getLastChildRouteSnapshot();

                const { noSEO } = lastChildRouteSnapshot.data;

                let currentUrl = `https://gcore.com${this.router.url}`;
                if (this.router.url === '/') {
                    currentUrl = currentUrl.slice(0, -1);
                }
                this.meta.updateTag({ name: 'og:url', content: currentUrl });

                if (!noSEO) {
                    this.updateCanonicalTag(currentUrl, renderer);

                    if (data instanceof RouterEvent && isScullyRunning()) {
                        insertHeadSeoScript([createBreadCrumbs(data)]);
                    }
                }
            }),
        );
    }

    public setMetaInfo(metaData: { title: string; description: string }, featuredImage?: ImageMediaRaw): void {
        const { title, description } = metaData;
        this.title.setTitle(title);
        this.meta.updateTag({ name: 'og:title', content: title });

        if (description) {
            this.meta.updateTag({
                name: 'description',
                content: description,
            });
            this.meta.updateTag({ name: 'og:description', content: description });
        }

        if (featuredImage) {
            this.meta.updateTag({ property: 'og:image', content: featuredImage.data.attributes.url });
            this.meta.updateTag({ property: 'og:image:secure_url', content: featuredImage.data.attributes.url });
        }
    }

    public updateAlternateTag(renderer: Renderer2, langList: Array<LanguageEnum>): void {
        const tagList = document.head.querySelectorAll('link[rel="alternate"]');
        const pathWithoutParams = location.pathname.split(/[?, #]/)[0];
        const path = (langList as Array<string>).includes(pathWithoutParams.split('/')[1])
            ? pathWithoutParams.slice(3)
            : pathWithoutParams;
        const domain = config.environment === 'prod' ? 'https://gcore.com' : 'https://site.preprod.world';

        if (tagList.length > 0) {
            tagList.forEach((tag) => {
                document.head.removeChild(tag);
            });
        }

        langList.forEach((lang) => {
            const tag = document.createElement('link');
            tag.setAttribute('rel', 'alternate');
            tag.setAttribute('hreflang', lang);
            let langFragment = '';
            if (lang !== LanguageEnum.EN) langFragment = `/${lang}`;
            tag.setAttribute('href', `${domain}${langFragment}${path}`);
            document.head.appendChild(tag);
        });

        const tag = document.createElement('link');
        tag.setAttribute('rel', 'alternate');
        tag.setAttribute('hreflang', 'x-default');
        tag.setAttribute('href', `${domain}${path}`);
        document.head.appendChild(tag);
    }

    private updateCanonicalTag(url: string, renderer: Renderer2): void {
        let tag = document.head.querySelector('link[data-canonical]');
        const urlWithoutParams = url.split(/[?, #]/)[0];

        if (tag) {
            renderer.setAttribute(tag, 'href', urlWithoutParams);
        } else {
            tag = renderer.createElement('link');
            renderer.setAttribute(tag, 'data-canonical', '');
            renderer.setAttribute(tag, 'rel', 'canonical');
            renderer.setAttribute(tag, 'href', urlWithoutParams);
            renderer.appendChild(document.head, tag);
        }
    }

    private getLastChildRouteSnapshot(): ActivatedRouteSnapshot {
        let child: ActivatedRoute = this.activatedRoute.firstChild;
        let snapshot: ActivatedRouteSnapshot;
        while (child) {
            if (child.firstChild) {
                child = child.firstChild;
            } else {
                snapshot = child.snapshot;
                child = null;
            }
        }
        return snapshot;
    }
}
