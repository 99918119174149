<div
    class="gc-expandable-menu-item gc-expandable-menu-item-{{ variant }}"
    #expandableMenu
    [class.gc-active]="showMenu && isActive"
    (mouseenter)="openMenuOnMouseOver($event)"
    (click)="toggleMenuOnMouseClick($event)"
    (mouseleave)="closeMenuOnMouseOver($event)"
>
    <p
        class="gc-expandable-menu-item-title"
        tabindex="0"
        (click)="toggleMenu($event.target)"
        [class.active]="showMenu && isActive"
    >
        {{ title }}
        <svg-icon class="gc-hide-desktop" src="assets/icons/header-new/chevron--down.svg"></svg-icon>
    </p>
    <div
        *ngIf="showMenu && isActive"
        class="gc-expandable-section"
        [class.active]="showMenu && isActive"
        [class.gc-one-level-menu]="oneLevelMenu"
        #expandableSection
    >
        <div class="gc-indentation-section"></div>
        <div
            class="gc-expandable-section-content gc-expandable-section-inner gc-expandable-section-content_height-{{
                minHeight
            }}"
        >
            <div class="gc-content-wrapper">
                <ul class="gc-parent-menu" [ngClass]="{ 'gc-parent-menu_last': isLast }">
                    <li
                        class="gc-parent-menu-item"
                        #parentMenuItem
                        (mouseenter)="openChildMenuOnMouseOver($event)"
                        *ngFor="let menuItem of menuConfig; let i = index; let first = first"
                        [class.gc-active]="!oneLevelMenu && first"
                    >
                        <div
                            *ngIf="menuItem.childMenu"
                            class="gc-menu-item"
                            (click)="toggleChildMenu(i, menuItem.label)"
                        >
                            <span class="gc-menu-item-main-title"
                                >{{ menuItem.label }}
                                <svg-icon
                                    *ngIf="!showExtendedMenuContent"
                                    class="gc-hide-desktop"
                                    src="assets/icons/header-new/arrow-right.svg"
                                ></svg-icon>
                            </span>
                            <div class="gc-extended-menu-content" *ngIf="showExtendedMenuContent">
                                <ng-container
                                    *ngTemplateOutlet="
                                        childMenu;
                                        context: { children: menuItem.childMenu, menu: menuItem }
                                    "
                                >
                                </ng-container>
                            </div>
                        </div>
                        <div
                            *ngIf="!menuItem.childMenu && !showExtendedMenuContent"
                            class="gc-menu-item"
                            (click)="hideMenuOnSelect($event)"
                        >
                            <a
                                *ngIf="menuItem.url.startsWith('/') || menuItem.url.startsWith('http')"
                                [href]="menuItem.url"
                                class="gc-link gc-link_primary"
                                tabindex="0"
                                ><svg-icon
                                    *ngIf="oneLevelMenu"
                                    class="gc-inherit-color"
                                    [src]="ICONS_MAP_NEW_HEADER[menuItem.iconName]"
                                ></svg-icon
                                >{{ menuItem.label }}</a
                            >
                            <a
                                *ngIf="!(menuItem.url.startsWith('/') || menuItem.url.startsWith('http'))"
                                [routerLink]="menuItem.url"
                                class="gc-link gc-link_primary"
                                tabindex="0"
                                ><svg-icon
                                    *ngIf="oneLevelMenu"
                                    class="gc-inherit-color"
                                    [src]="ICONS_MAP_NEW_HEADER[menuItem.iconName]"
                                ></svg-icon
                                >{{ menuItem.label }}</a
                            >
                        </div>
                        <div
                            *ngIf="menuItem.childMenu && !showExtendedMenuContent"
                            class="gc-child-menu"
                            [class.active]="showChildMenuIndex === i"
                            (click)="hideMenuOnSelect($event)"
                        >
                            <ng-container
                                *ngTemplateOutlet="childMenu; context: { children: menuItem.childMenu, menu: menuItem }"
                            >
                            </ng-container>
                            <div class="gc-child-menu-more" *ngIf="menuItem.moreAboutButton">
                                <a
                                    class="gc-text gc-text_15"
                                    tabindex="0"
                                    routerLink="{{ menuItem.moreAboutButton.url }}"
                                >
                                    {{ menuItem.moreAboutButton.text }}
                                    <svg-icon [src]="ICONS_MAP_NEW_HEADER['arrow-right']"></svg-icon>
                                </a>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="gc-expandable-section-backdrop"></div>
    </div>
</div>
