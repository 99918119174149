import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import config from '../../../config';
import { LanguageEnum } from '../enums';
import { toggleScroll } from '../utils/scroll-toggle';
import { CookieService } from './cookie.service';

const cookieKey = 'language';

@Injectable({
    providedIn: 'root',
})
export class LocalizationService {
    public readonly defaultLanguages = [LanguageEnum.EN, LanguageEnum.DE, LanguageEnum.ZH, LanguageEnum.KO];
    public availableLanguages: Array<LanguageEnum> = this.defaultLanguages;
    private locale$ = new BehaviorSubject<LanguageEnum>(LanguageEnum.EN);
    private domain = config.environment === 'prod' ? 'gcore.com;' : 'preprod.world;';
    private oneYear = 60 * 60 * 24 * 365;
    private renderer: Renderer2;

    constructor(
        private cookieService: CookieService,
        private rendererFactory: RendererFactory2,
        private router: Router,
    ) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    public switchLanguage(locale: LanguageEnum): void {
        const split = this.router.url.slice(1).split('/') || [];
        const path = locale === LanguageEnum.EN ? '' : locale;

        if (this.defaultLanguages.includes(split[0] as LanguageEnum)) {
            split.splice(0, 1, path);
        } else if (split[0] === '') {
            split.push(path);
        } else {
            split.unshift(path);
        }
        this.setLanguageToCookie(locale);
        this.router.navigateByUrl(split.join('/'));
    }

    public resolveLanguage(locale: LanguageEnum): void {
        const body = document.body;
        if (locale) {
            this.locale$.next(locale);
            this.renderer.setAttribute(document.documentElement, 'lang', locale);
            if (locale === LanguageEnum.ZH) {
                this.renderer.addClass(body, 'lang-cn');
            } else {
                this.renderer.removeClass(body, 'lang-cn');
            }
            toggleScroll(false);
        }
    }

    public getCurrentLocale(): LanguageEnum {
        return this.locale$.value;
    }

    public getLocaleFromCookie(): LanguageEnum {
        const language = this.cookieService.getCookie(cookieKey);
        if (language) {
            return this.defineLanguage(language);
        } else {
            return this.defineLanguage(window.navigator.language) || LanguageEnum.EN;
        }
    }

    public getLanguageRoute(): string {
        const currentLanguage = this.getCurrentLocale();
        return currentLanguage === LanguageEnum.EN ? '' : `${currentLanguage}/`;
    }

    public applySavedLocale(): void {
        if (this.router.url === '/') {
            const language = this.getLocaleFromCookie();
            if (language) {
                this.switchLanguage(language);
            }
        }
    }

    private setLanguageToCookie(locale: LanguageEnum): void {
        this.cookieService.setCookie(cookieKey, locale, this.oneYear, this.domain);
    }

    private defineLanguage = (language: string): LanguageEnum | null => {
        for (const lang of this.defaultLanguages) {
            if (language.includes(lang)) {
                return lang;
            }
        }

        return null;
    };
}
