import { Directive, HostListener, Input } from '@angular/core';

declare const dataLayer: Array<any>;

@Directive({
    selector: '[gcoreAnalyticsEvent]',
    standalone: true,
})
export class AnalyticsEventDirective {
    @Input() public eventName: string = 'try_for_free_all-pages';

    @HostListener('click') public onClick(): void {
        dataLayer.push({ event: this.eventName });
    }
}
