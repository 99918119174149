import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from 'angular-svg-icon';

@Component({
    selector: 'gcore-list-item-checked',
    templateUrl: './list-item-checked.component.html',
    styleUrls: ['./list-item-checked.component.scss'],
    imports: [CommonModule, SvgIconComponent],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListItemCheckedComponent {
    @Input() public text: string;
    @Input() public variant: 'white' | 'dark' | 'violet' | 'orange' = 'dark';
    @Input() public iconAlign: 'top' | 'center' = 'top';
}
