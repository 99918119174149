export interface WindowResizeEvent extends UIEvent {
    target: Window;
}

export enum DeviceTypeEnum {
    Mobile = 'Mobile',
    Tablet = 'Tablet',
    Desktop = 'Desktop',
}

// temporarily, until the new design release
export enum NewDeviceTypeEnum {
    XS = 'xs',
    SM = 'sm',
    MD = 'md',
    XL = 'xl',
    XXL = 'xxl',
}

export interface Device {
    type: DeviceTypeEnum;
    breakpoint: number;
}

export interface NewDevice {
    type: NewDeviceTypeEnum;
    breakpoint: number;
}

export interface Screen {
    type: ScreenTypeEnum;
    breakpoint: ScreenBreakpointsEnum;
}

export enum ScreenTypeEnum {
    XS = 'XS',
    SM = 'SM',
    MD = 'MD',
    LG = 'LG',
    XL = 'XL',
}

export enum ScreenBreakpointsEnum {
    XS = 599,
    SM = 799,
    MD = 1175,
    LG = 1999,
    XL = Infinity,
}
