/* eslint-disable @typescript-eslint/naming-convention */

export const templatesList: Record<string, () => Promise<any>> = {
    site: () => import('./site/site.component').then((c) => c.SiteComponent),
    'site-page': () => import('./site-page/site-page.component').then((c) => c.SitePageComponent),
    event: () => import('./event/event.component').then((c) => c.EventComponent),
    resource: () => import('./resource/resource.component').then((c) => c.ResourceComponent),
    case: () => import('./case-study/case-study.component').then((c) => c.CaseStudyComponent),
    'press-release': () => import('./press-release/press-release.component').then((c) => c.PressReleaseComponent),
    blog: () => import('./blog/blog.component').then((c) => c.BlogComponent),
    interview: () => import('./interview/interview.component').then((c) => c.InterviewComponent),
    learning: () => import('./learning/learning.component').then((c) => c.LearningComponent),
    'archive-page': () => import('./archive/archive.component').then((c) => c.ArchiveComponent),
    promo: () => import('./promo/promo.component').then((c) => c.PromoComponent),
    'dev-tools': () => import('./dev-tools/dev-tools.component').then((c) => c.DevToolsComponent),
    'landing-page': () => import('./landing-page/landing-page.component').then((c) => c.LandingPageComponent),
    'feedback-page': () => import('./feedback/feedback.component').then((m) => m.FeedbackComponent),
};
