import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';
import { map, Observable } from 'rxjs';
import { Router, RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from 'angular-svg-icon';
import { FormsModule } from '@angular/forms';
import { SearchService } from '../../../core/services/search.service';
import { SearchConsoleContent } from '../../../core/models/Search';

@Component({
    selector: 'gcore-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
    standalone: true,
    imports: [CommonModule, SvgIconComponent, FormsModule, RouterLink],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent implements OnInit, AfterViewInit {
    @Input() public content: SearchConsoleContent;
    @Input() public isOpened: boolean = false;
    @ViewChild('input') public input: ElementRef;
    public value = '';
    public resultText: string;
    public isNoResults: boolean = false;
    public results$: Observable<any>;
    public isButtonDisable = true;

    private topSearchResults: Array<Record<'route' | 'menuTitle', string>>;

    constructor(private router: Router, private searchService: SearchService, private cd: ChangeDetectorRef) {}

    public ngOnInit(): void {
        this.resultText = this.content.topResultsTitle;
        this.topSearchResults = this.content.topResultsList.map((item) => ({
            route: item.value,
            menuTitle: item.label,
        }));

        this.results$ = this.searchService.search(this.topSearchResults).pipe(
            map((data) => {
                if (data.status === 'default') {
                    this.isButtonDisable = true;
                    this.resultText = this.content.topResultsTitle;
                    this.isNoResults = false;
                }
                if (data.status === 'no-results') {
                    this.isButtonDisable = false;
                    this.resultText = this.content.topResultsTitle;
                    this.isNoResults = true;
                }
                if (data.status === 'results') {
                    this.isButtonDisable = false;
                    this.resultText = this.content.resultsTitle;
                    this.isNoResults = false;
                }
                return data.hits;
            }),
        );

        this.searchService.key$.subscribe((key) => {
            this.value = key;
        });
    }

    public ngAfterViewInit(): void {
        this.input?.nativeElement.focus();
    }

    public onInput(event: string): void {
        this.searchService.key$.next(event);
    }

    public clearInput(): void {
        this.searchService.key$.next('');
        this.input.nativeElement.focus();
    }

    public closeConsole(): void {
        this.searchService.toggleSearchConsole(false);
    }

    public goToSearchPage(event?: Event): void {
        if ((!event || (event as KeyboardEvent)?.key === 'Enter') && this.value.length) {
            this.searchService.goToSearchPage();
            this.closeConsole();
        }
    }
}
