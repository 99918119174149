export const CLOUD_ENDPOINTS = [
    {
        locationName: 'Almaty',
        url: 'https://kal-2-latency.tools.gcore.com',
    },
    {
        locationName: 'Amsterdam',
        url: 'https://dra4-2-latency.tools.gcore.com',
    },
    {
        locationName: 'Chicago',
        url: 'https://drc-2-latency.tools.gcore.com',
    },
    {
        locationName: 'Darmstadt',
        url: 'https://darz-2-latency.tools.gcore.com',
    },
    {
        locationName: 'Dubai',
        url: 'https://dx1-2-latency.tools.gcore.com',
    },
    {
        locationName: 'Frankfurt',
        url: 'https://drf-2-latency.tools.gcore.com',
    },
    {
        locationName: 'Hong Kong',
        url: 'https://gnc-2-latency.tools.gcore.com',
    },
    {
        locationName: 'Istanbul',
        url: 'https://tii-2-latency.tools.gcore.com',
    },
    {
        locationName: 'Johannesburg',
        url: 'https://jb1-2-latency.tools.gcore.com',
    },
    {
        locationName: 'London',
        url: 'https://ld3-2-latency.tools.gcore.com',
    },
    {
        locationName: 'Luxembourg',
        url: 'https://ed-16-latency.tools.gcore.com',
    },
    {
        locationName: 'Manassas',
        url: 'https://anx-2-latency.tools.gcore.com',
    },
    {
        locationName: 'Newport',
        url: 'https://cwl1-2-latency.tools.gcore.com',
    },

    /* {
        locationName: 'Mumbai',
        url: 'https://ww-2-latency.tools.gcore.com',
    }, */

    {
        locationName: 'Paris',
        url: 'https://pa5-2-latency.tools.gcore.com',
    },
    {
        locationName: 'Santa Clara',
        url: 'https://scl2-2-latency.tools.gcore.com',
    },
    {
        locationName: 'São Paulo',
        url: 'https://sp3-2-latency.tools.gcore.com',
    },
    {
        locationName: 'Singapore',
        url: 'https://sgc-2-latency.tools.gcore.com',
    },
    {
        locationName: 'Sydney',
        url: 'https://sy4-2-latency.tools.gcore.com',
    },
    {
        locationName: 'Tokyo',
        url: 'https://cc1-2-latency.tools.gcore.com',
    },
    {
        locationName: 'Warsaw',
        url: 'https://wa2-2-latency.tools.gcore.com',
    },
];
