import { Directive, Input, OnInit } from '@angular/core';
import { isScullyRunning } from '@scullyio/ng-lib';
import { LocalizationService } from '../../../core/services';
import { UserAgentService } from '../../../core/services/user-agent.service';
import { LanguageEnum } from '../../../core/enums';
import { LinkContent } from './link.model';

@Directive({
    standalone: true,
    selector: '[gcorePhoneSwap]',
})
export class PhoneSwapDirective implements OnInit {
    @Input() public content: LinkContent;

    constructor(private localizationService: LocalizationService, private userAgentService: UserAgentService) {}

    public ngOnInit(): void {
        // eslint-disable-next-line sonarjs/no-collapsible-if
        if (this.content?.url.startsWith('+') && !isScullyRunning()) {
            if (
                this.localizationService.getCurrentLocale() === LanguageEnum.EN &&
                this.userAgentService.countryCode === 'US'
            ) {
                this.content.text = '+1 888 4148 646';
                this.content.url = this.content.text.replace(/\s+/g, '');
            }
        }
    }
}
