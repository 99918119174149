import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from 'angular-svg-icon';
import { ListItemCheckedComponent } from '../../ui-kit/ui-kit-old/list-item-checked';
import { LoginModalContent } from '../../core/models';
import { DeviceService } from '../../core/services/device';
import { ModalLoginEmitEnum } from './modal-login.interface';

@Component({
    selector: 'gcore-modal-login',
    templateUrl: './modal-login.component.html',
    styleUrls: ['./modal-login.component.scss'],
    imports: [CommonModule, SvgIconComponent, ListItemCheckedComponent],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalLoginComponent {
    @Input() public content: LoginModalContent;
    @Output() public navigate = new EventEmitter<ModalLoginEmitEnum>();
    @Output() public closeModalEvent: EventEmitter<any> = new EventEmitter();
    public isMobile$: Observable<boolean> = this.deviceService.isMobile$;
    public showAllProducts = false;
    public ModalLoginEmitEnum = ModalLoginEmitEnum;

    constructor(private deviceService: DeviceService) {}

    public showAll(): void {
        this.showAllProducts = true;
    }

    public closeModal(): void {
        this.closeModalEvent.emit();
    }
}
