<svg
    width="128"
    height="32"
    viewBox="0 0 128 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="logo-variant_{{ variant }}"
>
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M27.4271 16C27.4271 24.8361 20.2794 32 11.4631 32C7.90167 32 4.61184 30.8306 1.9567 28.8556C2.41125 29.1111 2.87962 29.3416 3.36188 29.55C5.18832 30.3389 7.15612 30.7472 9.14609 30.7472C12.8516 30.7472 16.4187 29.3416 19.1347 26.8195C21.8508 24.2972 23.5193 20.8389 23.802 17.1361C23.8409 16.7862 23.8629 16.4361 23.8685 16.0861C23.8685 15.6388 23.8325 15.1222 23.7853 14.65C23.7826 14.6056 23.7798 14.5583 23.7771 14.5139C23.7771 14.4611 23.7742 14.4083 23.7687 14.3583L23.7549 14.3722C23.6884 13.7583 23.6191 13.2861 23.6191 13.2861H12.0368L11.1361 14.975L9.06848 18.8917H17.821C17.4967 19.8612 17.0117 20.7722 16.3881 21.5833C15.5372 22.7 14.4425 23.6028 13.1842 24.2251C11.9287 24.8472 10.5457 25.1723 9.14331 25.1694C8.07072 25.1694 7.00646 24.975 6.00039 24.6027C4.24046 23.9584 2.72166 22.789 1.64907 21.25C0.57648 19.7084 0 17.8778 0 16C0 14.7972 0.235582 13.6028 0.695657 12.4917C1.15573 11.3806 1.82921 10.3694 2.67732 9.51666C3.5254 8.66667 4.53425 7.98888 5.64563 7.53056C6.75702 7.07223 7.94601 6.83334 9.14609 6.83334C11.0834 6.83056 12.9736 7.44723 14.5367 8.59445L15.7368 6.34166L17.1669 3.63334C14.7806 2.075 11.9925 1.24722 9.14331 1.25C6.62398 1.25 4.17116 1.9 2.00105 3.10556C4.65342 1.15556 7.92383 0 11.4631 0C20.2794 0 27.4271 7.16388 27.4271 16Z"
    />
    <path
        d="M70.7826 19.6361C70.2195 20.3118 69.5131 20.8546 68.7122 21.2153C67.9987 21.5304 67.2223 21.688 66.4423 21.6845C65.4456 21.702 64.4592 21.4604 63.5814 20.9807C63.5499 20.9632 63.522 20.9422 63.4905 20.9212C62.707 20.4694 62.0599 19.8112 61.6194 19.0164C61.1401 18.1375 60.8988 17.1534 60.9164 16.1521C60.8988 15.1541 61.1401 14.1667 61.6194 13.2878C62.0599 12.4964 62.7035 11.8381 63.4905 11.3829C63.522 11.3654 63.5499 11.3409 63.5814 11.3234C64.4592 10.8437 65.442 10.6021 66.4423 10.6196C67.2257 10.6126 67.9987 10.7702 68.7156 11.0818L70.342 8.02146C70.342 8.02146 70.3175 8.00745 70.3034 8.00045C69.02 7.46472 67.6384 7.19509 66.2464 7.21259C64.7076 7.19509 63.1896 7.53474 61.8012 8.20004C61.6857 8.25606 61.5634 8.29808 61.448 8.36111C60.0595 9.09293 58.8984 10.1959 58.0939 11.5475C57.272 12.9411 56.8489 14.5343 56.8733 16.1521C56.8489 17.7697 57.272 19.3629 58.0939 20.7565C58.8984 22.1047 60.0595 23.2078 61.448 23.9396C61.5634 24.0025 61.6857 24.0446 61.8012 24.1006C63.1792 24.7624 64.69 25.1021 66.2185 25.0879C67.6139 25.109 68.999 24.8395 70.286 24.2967C70.3034 24.2896 70.3175 24.2792 70.3349 24.2722C71.5031 23.7644 72.5348 22.9871 73.3427 21.9997L70.7791 19.6292L70.7826 19.6361Z"
    />
    <path
        d="M79.18 23.9291C77.781 23.1937 76.6129 22.0872 75.8014 20.7321C74.9795 19.3491 74.5564 17.7629 74.5808 16.1521C74.5564 14.5414 74.9795 12.9552 75.8014 11.5721C76.6129 10.217 77.781 9.11052 79.18 8.3752C80.6734 7.58734 82.3381 7.19167 84.0275 7.21268C85.7098 7.18466 87.3745 7.58384 88.8609 8.3717C90.2564 9.11052 91.4246 10.217 92.2394 11.5686C93.0684 12.9517 93.495 14.5379 93.4741 16.1486C93.495 17.7593 93.0684 19.3456 92.2394 20.7287C91.4246 22.0803 90.2564 23.1868 88.8609 23.9255C87.3745 24.7135 85.7098 25.1127 84.0275 25.088C82.3417 25.1127 80.6734 24.7135 79.18 23.9255V23.9291ZM86.7869 20.9773C87.6019 20.515 88.2734 19.8358 88.7245 19.0128C89.2036 18.134 89.445 17.1501 89.4275 16.1486C89.445 15.1508 89.2036 14.1633 88.7245 13.2844C88.2734 12.4615 87.6019 11.7822 86.7869 11.32C85.944 10.8473 84.9928 10.6022 84.024 10.6162C83.0587 10.6022 82.1039 10.8438 81.261 11.32C80.446 11.7822 79.7745 12.4615 79.3234 13.2844C78.8443 14.1633 78.6029 15.1472 78.6204 16.1486C78.6029 17.1466 78.8443 18.134 79.3234 19.0128C79.7745 19.8358 80.446 20.515 81.261 20.9773C82.1039 21.4536 83.0551 21.6951 84.024 21.6812C84.9892 21.6951 85.944 21.4536 86.7869 20.9773Z"
    />
    <path
        d="M107.481 24.794L104.152 19.9793H100.476V24.794H96.4818V7.51025H103.952C105.32 7.47875 106.677 7.73786 107.936 8.2771C108.996 8.73229 109.902 9.48863 110.538 10.4516C111.164 11.4495 111.482 12.6085 111.451 13.785C111.482 14.9616 111.161 16.117 110.528 17.1045C109.881 18.0605 108.968 18.8028 107.901 19.2404L111.773 24.7974H107.481V24.794ZM106.47 11.5475C105.677 10.9768 104.708 10.7002 103.735 10.7702H100.479V16.7929H103.735C104.711 16.8593 105.684 16.5827 106.47 16.0015C106.785 15.7284 107.034 15.3852 107.198 15.0001C107.359 14.6149 107.432 14.1982 107.411 13.7815C107.436 13.3613 107.366 12.9412 107.202 12.556C107.041 12.1673 106.789 11.8242 106.474 11.5475H106.47Z"
    />
    <path
        d="M127.973 21.5865V24.7974H114.605V7.51025H127.651V10.7212H118.575V14.4748H126.591V17.5877H118.575V21.5865H127.969H127.973Z"
    />
    <path
        d="M49.174 19.6325C48.611 20.3084 47.9045 20.8511 47.1037 21.2118C46.3901 21.527 45.6137 21.6844 44.8337 21.681C43.837 21.6985 42.8507 21.4568 41.9729 20.9771C41.9414 20.9597 41.9134 20.9386 41.8819 20.9176C41.0985 20.466 40.4514 19.8077 40.0109 19.0128C39.5316 18.1339 39.2903 17.15 39.3078 16.1486C39.2903 15.1505 39.5316 14.1631 40.0109 13.2843C40.4514 12.4929 41.095 11.8346 41.8819 11.3794C41.9134 11.3619 41.9414 11.3374 41.9729 11.3199C42.8507 10.8402 43.8334 10.5986 44.8337 10.6161C45.6172 10.6091 46.3901 10.7666 47.1071 11.0783L48.7335 8.01793C48.7335 8.01793 48.709 8.00391 48.6949 7.99691C47.4114 7.46117 46.0299 7.19156 44.6379 7.21257C43.0991 7.19506 41.5811 7.53471 40.1927 8.2C40.0772 8.25604 39.9549 8.29804 39.8395 8.36108C38.451 9.0929 37.2898 10.1959 36.4854 11.5475C35.6635 12.9376 35.2403 14.5343 35.2648 16.152C35.2403 17.7697 35.6635 19.363 36.4854 20.7566C37.2898 22.1046 38.451 23.2076 39.8395 23.9394C39.9549 24.0025 40.0772 24.0444 40.1927 24.1005C41.5707 24.7623 43.0815 25.1019 44.61 25.088C46.0054 25.109 47.3904 24.8393 48.6775 24.2967C48.6949 24.2896 48.709 24.2791 48.7264 24.2721C49.8946 23.7644 50.9263 22.9871 51.7342 21.9996L49.1706 19.629L49.174 19.6325Z"
    />
    <path
        d="M53.9656 15.6549L50.4751 15.6689C50.5241 16.5478 50.3738 17.4266 50.0345 18.2391C49.7231 18.9569 49.265 19.6011 48.6915 20.1298C48.4082 20.4029 48.1038 20.6516 47.7716 20.8581C48.2087 21.9682 48.5935 23.0011 48.8034 24.1846C49.5764 23.768 50.3142 23.4389 50.9578 22.8295C51.9931 21.8912 52.7135 20.6971 53.287 19.4225C53.2941 19.4051 53.2975 19.3875 53.3046 19.3735C53.8152 18.2041 54.0426 16.933 53.9621 15.6584L53.9656 15.6549Z"
    />
    <path d="M53.9831 15.2312L53.9655 15.6549L52.2553 18.7327H43.2179L45.0471 15.2312H53.9831Z" />
</svg>
