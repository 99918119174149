import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    Renderer2,
    TemplateRef,
    ViewChildren,
} from '@angular/core';

import { CommonModule } from '@angular/common';
import { SvgIconComponent } from 'angular-svg-icon';
import { RouterLink } from '@angular/router';

import { HeaderVariant } from '../header.component';

import { ACTIVE_CLASS, HEADER_NEW_ICONS_MAP, MOBILE_DEVICE_BREAKPOINT } from '../../../core/constants';
import { CurrentMenuItem, MenuItem, MenuItemGroupped } from '../../../core/models';
import { toggleScroll } from '../../../core/utils/scroll-toggle';
import { ChildMenuDirective } from './child-menu.directive';

@Component({
    selector: 'gcore-expandable-menu-item',
    templateUrl: './expandable-menu-item.component.html',
    styleUrls: ['./expandable-menu-item.component.scss'],
    imports: [CommonModule, SvgIconComponent, RouterLink],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpandableMenuItemComponent implements OnInit {
    @Input() public menuConfig: Array<MenuItem | MenuItemGroupped> = [];
    @Input() public title: string;
    @Input() public variant: HeaderVariant = 'default';
    @Input() public oneLevelMenu: boolean = false;
    @Input() public showExtendedMenuContent: boolean = false;
    @Input() public minHeight: number = 0;
    @Input() public isHoverable: boolean = true;
    @Input() public isActive: boolean;
    @Input() public isLast: boolean;

    @Output() public currentMenuChange: EventEmitter<CurrentMenuItem> = new EventEmitter();
    @Output() public clicked = new EventEmitter<void>();

    @ContentChild(ChildMenuDirective, { read: TemplateRef })
    public childMenu: TemplateRef<any>;

    @ViewChildren('parentMenuItem') public parentMenuItems: QueryList<ElementRef>;
    @ViewChildren('expandableMenu') public menuComponents: QueryList<ElementRef>;

    public showChildMenuIndex: number = null;
    public showMenu: boolean = false;
    public ICONS_MAP_NEW_HEADER = HEADER_NEW_ICONS_MAP;

    constructor(
        private changeDetector: ChangeDetectorRef,
        private renderer: Renderer2,
        private elementRef: ElementRef,
    ) {}

    public ngOnInit(): void {
        if (window.innerWidth > MOBILE_DEVICE_BREAKPOINT && !this.isHoverable) {
            this.isActive = true;
        }
    }

    public hideMenuOnSelect(event: Event): void {
        const target = event.target as HTMLElement;
        if (target.classList.contains('gc-link') || target.parentElement?.classList.contains('gc-link')) {
            this.showMenu = false;
            toggleScroll(this.showMenu);
            this.showChildMenuIndex = null;
            this.currentMenuChange.emit(null);
        }
    }

    public hideMenuMobile(): void {
        this.showMenu = false;
        toggleScroll(this.showMenu);
        this.showChildMenuIndex = null;
        this.changeDetector.detectChanges();
    }

    public hideMenu(): void {
        this.showMenu = false;
        toggleScroll(this.showMenu);
        this.changeDetector.detectChanges();
    }

    public hideChildMenu(): void {
        if (this.showChildMenuIndex !== null) {
            this.showChildMenuIndex = null;
            this.currentMenuChange.emit({ title: this.title, lvl: 1 });
        }
    }

    public toggleChildMenu(i: number, name: string): void {
        if (window.innerWidth <= MOBILE_DEVICE_BREAKPOINT) {
            this.showChildMenuIndex = i;
            this.currentMenuChange.emit({ title: name, lvl: 2 });
        }
    }

    public toggleMenu(element: any): void {
        if (window.innerWidth <= MOBILE_DEVICE_BREAKPOINT) {
            if (element.classList.contains('active')) {
                this.showMenu = !this.showMenu;
            } else {
                this.showMenu = true;
            }

            this.clicked.emit();
        }
    }

    public openMenuOnMouseOver(event: Event): void {
        if (window.innerWidth > MOBILE_DEVICE_BREAKPOINT && this.isHoverable) {
            this.isActive = true;
            this.showMenu = true;
            toggleScroll(this.showMenu);
        }
    }

    public closeMenuOnMouseOver(event: Event): void {
        if (window.innerWidth > MOBILE_DEVICE_BREAKPOINT && this.isHoverable) {
            this.isActive = false;
            this.showMenu = false;
            toggleScroll(this.showMenu);
        }
    }

    public toggleMenuOnMouseClick(event: Event): void {
        if (window.innerWidth > MOBILE_DEVICE_BREAKPOINT && !this.isHoverable) {
            this.isActive = !this.isActive;
            this.showMenu = !this.showMenu;
            toggleScroll(this.showMenu);
        }
    }

    public openChildMenuOnMouseOver(event: Event): void {
        const target = event.currentTarget as HTMLElement;
        this.parentMenuItems.forEach((parentMenuItem) =>
            this.renderer.removeClass(parentMenuItem.nativeElement, ACTIVE_CLASS),
        );
        this.renderer.addClass(target, ACTIVE_CLASS);
        target.querySelector('.gc-child-menu')?.scrollTo({ top: 0 });
    }

    protected readonly HEADER_NEW_ICONS_MAP = HEADER_NEW_ICONS_MAP;
}
