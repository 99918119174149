import { Injectable } from '@angular/core';
import { debounceTime, fromEvent, map, Observable, startWith } from 'rxjs';
import {
    Device,
    DeviceTypeEnum,
    NewDevice,
    NewDeviceTypeEnum,
    WindowResizeEvent,
    Screen,
    ScreenBreakpointsEnum,
    ScreenTypeEnum,
} from './device.interface';

export const DEVICES: Array<Device> = [
    {
        type: DeviceTypeEnum.Mobile,
        breakpoint: 576,
    },
    {
        type: DeviceTypeEnum.Tablet,
        breakpoint: 1128,
    },
    {
        type: DeviceTypeEnum.Desktop,
        breakpoint: Infinity,
    },
];

// temporarily, until the new design release
export const DEVICES_NEW: Array<NewDevice> = [
    {
        type: NewDeviceTypeEnum.XS,
        breakpoint: 576,
    },
    {
        type: NewDeviceTypeEnum.SM,
        breakpoint: 898,
    },
    {
        type: NewDeviceTypeEnum.MD,
        breakpoint: 1342,
    },
    {
        type: NewDeviceTypeEnum.XL,
        breakpoint: 1898,
    },
    {
        type: NewDeviceTypeEnum.XXL,
        breakpoint: Infinity,
    },
];

export const SCREENS: Array<Screen> = [
    {
        type: ScreenTypeEnum.XS,
        breakpoint: ScreenBreakpointsEnum.XS,
    },
    {
        type: ScreenTypeEnum.SM,
        breakpoint: ScreenBreakpointsEnum.SM,
    },
    {
        type: ScreenTypeEnum.MD,
        breakpoint: ScreenBreakpointsEnum.MD,
    },
    {
        type: ScreenTypeEnum.LG,
        breakpoint: ScreenBreakpointsEnum.LG,
    },
    {
        type: ScreenTypeEnum.XL,
        breakpoint: ScreenBreakpointsEnum.XL,
    },
];

@Injectable({
    providedIn: 'root',
})
export class DeviceService {
    public deviceType$: Observable<DeviceTypeEnum>;
    public screenType$: Observable<ScreenTypeEnum>;
    public deviceTypeNew$: Observable<NewDeviceTypeEnum>;
    public isMobile$: Observable<boolean>;
    private resize$: Observable<number>;

    constructor() {
        this.resize$ = this.windowResize$().pipe(
            map((event) => window.innerWidth),
            startWith(window.innerWidth),
            debounceTime(300),
        );

        this.deviceType$ = this.resize$.pipe(
            map((width) => {
                return this.getDeviceType(width);
            }),
        );

        this.deviceTypeNew$ = this.resize$.pipe(
            map((width) => {
                return this.getDeviceTypeNew(width);
            }),
        );

        this.screenType$ = this.resize$.pipe(
            map((width) => {
                return this.getScreenType(width);
            }),
        );

        this.isMobile$ = this.deviceType$.pipe(map((type) => type === DeviceTypeEnum.Mobile));
    }

    private windowResize$(): Observable<WindowResizeEvent> {
        return fromEvent(window, 'resize', {
            passive: true,
        }) as Observable<WindowResizeEvent>;
    }

    private getDeviceType(width: number): DeviceTypeEnum {
        return DEVICES.find((device) => width < device.breakpoint)?.type;
    }

    private getDeviceTypeNew(width: number): NewDeviceTypeEnum {
        return DEVICES_NEW.find((device) => width < device.breakpoint)?.type;
    }

    private getScreenType(width: number): ScreenTypeEnum {
        return SCREENS.find((screen) => width < screen.breakpoint)?.type;
    }
}
