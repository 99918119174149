import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from 'angular-svg-icon';
import { ModalSize, ModalTheme } from './modal.interface';

@Component({
    selector: 'gcore-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
    imports: [CommonModule, SvgIconComponent],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent {
    @Input() public isOpen: boolean;
    @Input() public size: ModalSize = ModalSize.FullWidth;
    @Input() public theme: ModalTheme = ModalTheme.Default;
    @Input() public scrollable: boolean = true;
    @Input() public minPaddings: boolean = true;
    @Output() public isOpenChange = new EventEmitter<boolean>();

    public ModalTheme = ModalTheme;
    public ModalSize = ModalSize;

    public showModal($event: MouseEvent): void {
        $event.stopPropagation();
        this.isOpenChange.emit(!this.isOpenChange);
    }
}
