import { Converter } from 'showdown';
import { CardItem, ListItem, StringListItem, StructuredSchemaMarkup, StructuredSchemaMarkupObject } from '../models/';
import { TableHeaderEl } from '../models';
import { MapMarker } from '../../widgets/map';
import { TableColumn, TableColumnRaw } from '../../pages/hosting/internet-options/internet-options.model';
import { PricingCloudTableConfigs } from '../../pages/pricing/cloud/cloud.model';

export const reducePricingCloudTableConfigs = (
    acc: PricingCloudTableConfigs,
    curr: {
        tableType: string;
        title: string;
        description: string;
        columnsConfig: Array<TableHeaderEl<any>>;
    },
): PricingCloudTableConfigs => {
    acc[curr.tableType] = {
        title: curr.title,
        description: curr.description,
        columnsConfig: curr.columnsConfig,
    };

    return acc;
};

export const mapAttributes = <T>(item: { attributes: T }): T => ({
    ...item.attributes,
});

export const mapListItemsToCardItems = (items: Array<ListItem>): Array<CardItem> => {
    return items.map((item: ListItem) => ({
        title: item.value,
        iconName: item.iconName,
        description: item.details,
    }));
};

export const mapToStringsArray = (item: StringListItem): string => item.value;

export const mapRichTextListItem = (item: ListItem, converter: Converter): ListItem => ({
    ...item,
    details: converter.makeHtml(item.details),
});

export const mapPrices = (data: Array<any>): any =>
    data.map(mapAttributes).map((item: any) => ({
        ...item,
        pricesList: item.prices.map((price: any) => price.label),
    }));

export const roundFloatNumber = (
    num: number,
    precision: number = 100,
    type: 'round' | 'floor' | 'ceil' = 'round',
): number => {
    switch (type) {
        case 'floor':
            return Math.floor((num + Number.EPSILON) * precision) / precision;
        case 'ceil':
            return Math.ceil(num * precision) / precision;
        case 'round':
        default:
            return Math.round((num + Number.EPSILON) * precision) / precision;
    }
};

export const deepCopy = <T>(object: T): T => {
    return JSON.parse(JSON.stringify(object));
};

export const mapToTableColumn = (column: TableColumnRaw): TableColumn => ({
    label: column.label,
    rows: column.rows.reduce((acc, row) => ({ ...acc, [row.label]: row.value }), {}),
});

const getPrice = (lowPrice: string, highPrice: string): Record<string, string> =>
    lowPrice === highPrice ? { Price: lowPrice } : highPrice !== null ? { lowPrice, highPrice } : { lowPrice };

export const toStructuredSchemaMarkup = (
    structuredSchemaMarkup: StructuredSchemaMarkup,
): StructuredSchemaMarkupObject => ({
    ['@type']: structuredSchemaMarkup.type,
    name: structuredSchemaMarkup.name,
    category: structuredSchemaMarkup.category,
    url: structuredSchemaMarkup.url,
    brand: {
        ['@type']: structuredSchemaMarkup.schemaMarkupBrand.type,
        name: structuredSchemaMarkup.schemaMarkupBrand.name,
    },
    offers: {
        ['@type']: structuredSchemaMarkup.schemaMarkupOffers.type,
        offerCount: structuredSchemaMarkup.schemaMarkupOffers.offerCount,
        ...getPrice(
            structuredSchemaMarkup.schemaMarkupOffers.lowPrice,
            structuredSchemaMarkup.schemaMarkupOffers.highPrice,
        ),
        priceCurrency: structuredSchemaMarkup.schemaMarkupOffers.priceCurrency,
    },
    aggregateRating: {
        ['@type']: structuredSchemaMarkup.schemaMarkupAggregateRating.type,
        ratingValue: structuredSchemaMarkup.schemaMarkupAggregateRating.ratingValue,
        bestRating: structuredSchemaMarkup.schemaMarkupAggregateRating.bestRating,
        worstRating: structuredSchemaMarkup.schemaMarkupAggregateRating.worstRating,
        ratingCount: structuredSchemaMarkup.schemaMarkupAggregateRating.ratingCount,
    },
    publisher: {
        ['@type']: structuredSchemaMarkup.schemaMarkupPublisher.type,
        name: structuredSchemaMarkup.schemaMarkupPublisher.name,
    },
});

export const sortLocationForLocationList = (a: MapMarker, b: MapMarker): number => {
    if ((b.type === 'planned' && a.type !== 'planned') || (a.type === b.type && a.locationName < b.locationName)) {
        return -1;
    }

    return 0;
};
