import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import config from './config';
import { initSentry } from './app/helpers/init-sentry';

if (config.production) {
    enableProdMode();
    initSentry();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => {
        console.error(err);
    });
