<div class="gc-popup-overlay" *ngIf="isOpen" (click)="showModal($event)"></div>
<div
    *ngIf="isOpen"
    class="gc-popup gc-popup-open"
    [class.gc-popup_medium]="size === ModalSize.Medium"
    [class.gc-popup_max-height]="!minPaddings"
    [class.gc-popup_full-width]="size === ModalSize.FullWidth"
    [class.gc-popup-video]="theme === ModalTheme.Video"
    [class.gc-popup-default]="theme === ModalTheme.Default"
>
    <div [class.gc-popup-scroll]="scrollable">
        <div *ngIf="theme === ModalTheme.Video" class="gc-popup-close" (click)="showModal($event)">
            <svg-icon src="/assets/icons/close.svg"></svg-icon>
        </div>
        <div class="gc-popup-content">
            <ng-content></ng-content>
        </div>
    </div>
</div>
