import {
    ChangeDetectionStrategy,
    Component,
    NgZone,
    OnInit,
    Renderer2,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { isScullyRunning } from '@scullyio/ng-lib';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AnalyticsService } from './core/services';
import { CurrencyService } from './core/services/currency.service';
import { SeoService } from './core/services/seo.service';
import { LanguageEnum } from './core/enums';
import { ResourceLoadService, ThirdPartyScripts } from './core/services/resource-load.service';

@UntilDestroy()
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
    @ViewChild('modal', { read: ViewContainerRef })
    private entry!: ViewContainerRef;
    public availableLanguages: Array<LanguageEnum>;
    public isNewDesign: boolean = false;

    constructor(
        private analitycsService: AnalyticsService,
        private ngZone: NgZone,
        private currencyService: CurrencyService,
        private resourceLoadService: ResourceLoadService,
        private seoService: SeoService,
        private renderer: Renderer2,
    ) {
        this.seoService.addNoIndexTag();
        this.seoService.routerChangeMetaUpdate(this.renderer).pipe(untilDestroyed(this)).subscribe();
    }

    // prettier-ignore
    public ngOnInit(): void {
        if (!window.location.href.endsWith('/streaming-platform-calculator') && !isScullyRunning()) {
            this.analitycsService.setUTMCookie()

            this.resourceLoadService.loadScripts([ThirdPartyScripts.zenDeskWidget]).subscribe()
            setTimeout(() => {
                this.resourceLoadService.initApp();
            }, 2000)

            // Clear declined cookies every 10 seconds
            this.ngZone.runOutsideAngular(() => {
                setInterval(() => this.analitycsService.applyCookiesConsent(), 10000);
            });

            this.currencyService.checkQueryString();
        }
    }
}
