import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, combineLatest, map, Observable, of } from 'rxjs';

import { MapLocation, MapMarker, MapMarkerType } from '../../widgets/map';
import { CLOUD_ENDPOINTS } from '../constants/cloud-servers';

const MAP_INITIAL_HEIGHT = 1128;
const MAP_TOP_OFFSET = 125;
const MAP_BOTTOM_OFFSET = 328;
const MAP_TOP_OFFSET_PERCENT = (MAP_TOP_OFFSET / MAP_INITIAL_HEIGHT) * 100;
const MAP_BOTTOM_OFFSET_PERCENT = (MAP_BOTTOM_OFFSET / MAP_INITIAL_HEIGHT) * 100;

@Injectable({
    providedIn: 'root',
})
export class MapService {
    constructor(private http: HttpClient) {}

    public mapLocationToMarker(location: MapLocation): MapMarker {
        return {
            locationName: location.locationName,
            displayName: location.displayName,
            locationGroup: location.locationGroup,
            iconName: location.iconName,
            type: location.type || MapMarkerType.Custom,
            latency: location.latency || 0,
            x: this.calculateXPosition(location.longitude),
            y: this.calculateYPosition(location.latitude),
            tags: this.mapTags(location.tags, location.type),
        };
    }

    public mapStreamingLocationToMarker(location: MapLocation): MapMarker {
        return {
            locationName: location.locationName,
            locationGroup: location.locationGroup,
            iconName: location.iconName,
            type:
                location.tags === 'streamActive'
                    ? MapMarkerType.SecondaryActive
                    : location.tags === 'streamPlanned'
                    ? MapMarkerType.SecondaryPlanned
                    : location.type || MapMarkerType.Custom,
            latency: location.latency || 0,
            x: this.calculateXPosition(location.longitude),
            y: this.calculateYPosition(location.latitude),
            tags: this.mapTags(location.tags, location.type),
        };
    }

    public getUserMarker(measureLatency?: boolean): Observable<MapMarker> {
        return this.getCurrentLocation(measureLatency).pipe(
            map((data: any) => {
                const coords = data['Client LatLong'].split(',').map((coord: string) => parseFloat(coord));
                return {
                    y: this.calculateYPosition(coords[0]),
                    x: this.calculateXPosition(coords[1]),
                    locationName: data['Client City'],
                    serverLocation: data['Server City'],
                    iconName: data['Client Country'],
                    latency: data.responseTime,
                    type: MapMarkerType.User,
                };
            }),
        );
    }

    public filterMapLocationsByTag(locations: Array<MapMarker>, tag: string): Array<MapMarker> {
        return locations.reduce((filteredLocations, location) => {
            if (location.tags[tag]) {
                filteredLocations.push({ ...location, type: location.tags[tag] });
            }
            return filteredLocations;
        }, []);
    }

    private getCurrentLocation(measureLatency?: boolean): Observable<any> {
        if (measureLatency) {
            performance.clearResourceTimings();
        }
        return this.http.get('https://iam.gcdn.co/info/json').pipe(
            map((data) => {
                return { ...data, responseTime: this.getResponseTime('https://iam.gcdn.co/info/json') };
            }),
        );
    }

    public getCloudLatency(): Observable<any> {
        performance.clearResourceTimings();
        return combineLatest(
            CLOUD_ENDPOINTS.map((endpoint) => {
                const salt = new Date();
                const url = `${endpoint.url}/?${+salt}`;
                return this.http.get(url, { responseType: 'blob' }).pipe(
                    catchError(() => {
                        return of(null);
                    }),
                    map(() => ({
                        locationName: endpoint.locationName,
                        latency: this.getResponseTime(url),
                    })),
                );
            }),
        );
    }

    private calculateXPosition(longitude: number): number {
        return (longitude + 180) * (100 / 360);
    }

    private getResponseTime(entryName: string): number {
        let responseTime;
        try {
            const performanceEntries = performance.getEntriesByName(entryName);
            const lastEntry = performanceEntries[performanceEntries.length - 1] as PerformanceResourceTiming;
            responseTime = Math.round(lastEntry.responseStart - lastEntry.requestStart);
        } catch (e) {}
        return responseTime;
    }

    private calculateYPosition(latitude: number): number {
        const latRad = (latitude * Math.PI) / 180;
        const mercN = Math.log(Math.tan(Math.PI / 4 + latRad / 2));
        return (
            ((100 / 2 - (100 * mercN) / (2 * Math.PI) - MAP_TOP_OFFSET_PERCENT) /
                (100 - MAP_TOP_OFFSET_PERCENT - MAP_BOTTOM_OFFSET_PERCENT)) *
            100
        );
    }

    private mapTags(tags: string, markerBaseType: MapMarkerType): { [key: string]: MapMarkerType } {
        if (tags) {
            return tags.split('\n').reduce((tagsMap, tag) => {
                if (tag.includes('(planned)')) {
                    tagsMap[tag.replace('(planned)', '').trimEnd()] = MapMarkerType.Planned;
                } else {
                    tagsMap[tag] = markerBaseType;
                }
                return tagsMap;
            }, {} as { [key: string]: MapMarkerType });
        } else {
            return null;
        }
    }
}
