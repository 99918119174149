<ng-container *ngIf="content.url; else buttonTemplate">
    <a
        class="gc-button-icon gc-flex gc-align-items_center gc-border-radius_full gc-cursor_pointer gc-transition_fast"
        [class.gc-p-special_md]="size === 'lg'"
        [class.gc-p-special_sm]="size === 'md'"
        [class.gc-p_1]="size === 'sm'"
        [class.gc-bg_action-brand]="type === 'primary'"
        [class.gc-bg_action-tertiary]="type === 'secondary'"
        [class.gc-bg_static-subtle]="type === 'subtle'"
        [class.dark]="darkTheme"
        [class.disabled]="state === 'disabled'"
        *ngIf="content"
        [gcoreUrlParse]="content.url"
    >
        <svg-icon
            class="gc-icon"
            [class.dark]="darkTheme"
            [class.gc-icon_extra-small]="size === 'md'"
            [class.gc-icon_button]="size === 'sm'"
            [class.gc-icon_small]="size === 'lg'"
            [class.gc-icon_action-static]="type === 'primary'"
            [class.gc-icon_secondary]="type === 'secondary'"
            [class.gc-icon_action-tertiary]="type === 'subtle'"
            [src]="ICONS_COLLECTION[content.icon]"
        />
    </a>
</ng-container>
<ng-template #buttonTemplate>
    <button
        class="gc-button-icon gc-flex gc-align-items_center gc-border-radius_full gc-cursor_pointer gc-transition_fast"
        [class.gc-p-special_md]="size === 'lg'"
        [class.gc-p-special_sm]="size === 'md'"
        [class.gc-p_1]="size === 'sm'"
        [class.gc-bg_action-brand]="type === 'primary'"
        [class.gc-bg_action-tertiary]="type === 'secondary'"
        [class.gc-bg_static-subtle]="type === 'subtle'"
        [class.dark]="darkTheme"
        [class.disabled]="state === 'disabled'"
        *ngIf="content"
        (click)="onClick($event)"
    >
        <svg-icon
            class="gc-icon"
            [class.dark]="darkTheme"
            [class.gc-icon_extra-small]="size === 'md'"
            [class.gc-icon_button]="size === 'sm'"
            [class.gc-icon_small]="size === 'lg'"
            [class.gc-icon_action-static]="type === 'primary'"
            [class.gc-icon_secondary]="type === 'secondary'"
            [class.gc-icon_action-tertiary]="type === 'subtle'"
            [src]="ICONS_COLLECTION[content.icon]"
        />
    </button>
</ng-template>
