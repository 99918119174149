import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, Routes } from '@angular/router';
import { catchError, EMPTY, map, Observable, of, tap } from 'rxjs';
import { isScullyRunning } from '@scullyio/ng-lib';
import { pagesList } from './pages/pages';
import { templatesList } from './templates/templates';
import { LocalizationResolver } from './core/resolvers';
import { fallbackRoutes } from './app-routing.module';
import { StrapiContentService } from './core/services';

@Injectable({
    providedIn: 'root',
})
export class RoutingService {
    private componentList = { ...pagesList, ...templatesList };

    constructor(private http: HttpClient, private router: Router, private strapiService: StrapiContentService) {}

    public getRoutes(collection: string, language?: string): Observable<any> {
        return this.strapiService.getRoutes(language).pipe(
            map((data) => {
                if (!data) throw new Error('Empty routes file');
                return this.parseRoutes(data as Array<Routes>, language);
            }),
            catchError((err) => {
                console.error(err);
                if (!isScullyRunning()) {
                    return of(fallbackRoutes);
                } else {
                    return EMPTY;
                }
            }),
        );
    }

    public loadRoutes(collection: string): any {
        return this.getRoutes(collection).pipe(
            tap((newRoutes: Routes) => {
                if (Array.isArray(newRoutes) && newRoutes.length > 0) this.router.resetConfig(newRoutes);
            }),
        );
    }

    private parseRoutes(collection: Array<Routes>, language?: string): Array<any> {
        return collection?.map((item: any) => {
            const newItem = { ...item };
            if (item.loadChildren) {
                newItem.loadChildren = () => {
                    const [childLanguage, childCollection] = item.loadChildren.split('/');
                    return this.getRoutes(childCollection, language || childLanguage);
                };
            }

            if (item.children && item.children.length > 0) {
                newItem.children = this.parseRoutes(item.children, language);
            }

            if (item.data?.locale) {
                newItem.resolve = {
                    localeUpdate: LocalizationResolver,
                };
            }

            if (item.loadComponent) {
                newItem.loadComponent = this.componentList[item.loadComponent];
            }
            return newItem;
        });
    }
}
