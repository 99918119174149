import { Injectable } from '@angular/core';
import { isScullyRunning } from '@scullyio/ng-lib';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';

export enum ThirdPartyScripts {
    mktoForm = 'mktoForm',
    zenDeskWidget = 'zenDeskWidget',
    gtm = 'gtm',
    hubspot = 'hbspt',
}

interface Script {
    src: string;
    isLoaded: boolean;
    id?: string;
    addBehavior?: () => void;
}

@Injectable({
    providedIn: 'root',
})
export class ResourceLoadService {
    private isLoadedSubject$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public isLoaded$: Observable<boolean> = this.isLoadedSubject$.asObservable();

    private scripts: Record<ThirdPartyScripts, Script> = {
        [ThirdPartyScripts.gtm]: {
            src: 'https://www.googletagmanager.com/gtm.js?id=GTM-PXQB4BG',
            isLoaded: false,
            addBehavior: () => {
                const extendedWindow = window as unknown as { dataLayer: Array<any> };
                extendedWindow.dataLayer = extendedWindow.dataLayer || [];
                // eslint-disable-next-line @typescript-eslint/naming-convention
                extendedWindow.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
            },
        },
        [ThirdPartyScripts.mktoForm]: {
            src: 'https://l.go.gcore.com/js/forms2/js/forms2.min.js',
            isLoaded: false,
        },
        [ThirdPartyScripts.hubspot]: {
            src: 'https://js.hsforms.net/forms/v2.js',
            isLoaded: false,
        },
        [ThirdPartyScripts.zenDeskWidget]: {
            src: 'https://static.zdassets.com/ekr/snippet.js?key=50b017d2-4573-4bab-8b4b-70f6f01a0c97',
            id: 'ze-snippet',
            isLoaded: false,
        },
    };

    constructor() {}

    public initApp(): void {
        if (!isScullyRunning()) {
            this.isLoadedSubject$.next(true);
        }
    }

    public loadScripts(scripts: Array<ThirdPartyScripts> | ThirdPartyScripts): Observable<any> {
        return Array.isArray(scripts)
            ? combineLatest(scripts.map((script) => this.loadScript(script)))
            : this.loadScript(scripts);
    }

    private loadScript(scriptName: ThirdPartyScripts): Observable<void> {
        return new Observable<void>((observer) => {
            const script = this.scripts[scriptName];
            if (script?.isLoaded) {
                observer.next();
                observer.complete();
            } else if (script) {
                const scriptElem = document.createElement('script');
                if (script.addBehavior) {
                    script.addBehavior();
                }
                scriptElem.src = script.src;
                scriptElem.id = script?.id || '';
                scriptElem.defer = true;
                scriptElem.onload = () => {
                    script.isLoaded = true;
                    observer.next();
                    observer.complete();
                };
                scriptElem.onerror = () => {
                    observer.error(new Error(`Failed to load ${scriptName} script`));
                };

                document.head.appendChild(scriptElem);
            } else {
                observer.error(new Error(`Script "${scriptName}" not found`));
            }
        });
    }
}
