import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from 'angular-svg-icon';
import { RouterLink } from '@angular/router';
import { UrlParseDirective } from '../../../core/directives/url-parse/url-parse.directive';
import { LinkDirective } from '../../../core/directives/link/link.directive';
import { ICONS_COLLECTION } from '../../../core/constants';
import { LinkContent } from './link.model';

@Component({
    selector: 'gc-link',
    standalone: true,
    imports: [CommonModule, SvgIconComponent, UrlParseDirective, RouterLink, LinkDirective],
    templateUrl: './link.component.html',
    styleUrls: ['./link.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkComponent {
    @Input() public content: LinkContent;
    @Input() public iconPosition: 'left' | 'right' = 'right';
    @Input() public underline: boolean = false;
    @Input() public size: 'sm' | 'md' | 'lg' = 'lg';
    @Input() public type: 'primary' | 'secondary' | 'tertiary' | 'subtle' = 'primary';
    @Input() public state: 'none' | 'disabled' | 'selected' = 'none';
    @Input() public darkTheme: boolean = false;
    public ICONS_COLLECTION = ICONS_COLLECTION;
}
