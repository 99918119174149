let storedScrollPosition = 0;
const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

export const toggleScroll = (isDisabled: boolean): void => {
    const body = document.body;

    if (isDisabled) {
        storedScrollPosition = window.scrollY;

        if (!isFirefox) {
            body.classList.add('scroll_disabled');
            body.style.top = `-${storedScrollPosition}px`;
        } else {
            body.classList.add('scroll_disabled-overflow');
        }
    } else {
        if (!isFirefox) {
            body.classList.remove('scroll_disabled');
            body.style.top = '';
            window.scrollTo(0, storedScrollPosition);
        } else {
            body.classList.remove('scroll_disabled-overflow');
        }
    }
};
