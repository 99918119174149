<div
    class="gc-card-item"
    [ngClass]="{
        'gc-card-item_transparent-border': appearance === 'transparent-border',
        'gc-card-item_dark': appearance === 'dark',
        'gc-bg_grey gc-card-item_gray': appearance === 'gray',
        'gc-card-bg_white gc-card-item_white': appearance === 'white',
        'gc-card-bg_white gc-card-item_lilac': appearance === 'lilac',
        'gc-card-bg_white gc-card-item_dark-orange': appearance === 'dark-orange',
        'gc-card-item_transparent-orange': appearance === 'transparent-orange',
        'gc-card-item-flex-start': isHasList
    }"
>
    <div class="gc-card-item_wrapper">
        <div class="gc-flex gc-card-item-row" [ngClass]="isHasList ? 'gc-flex-column' : 'gc-flex-v-center'">
            <svg-icon
                *ngIf="
                    item.iconName &&
                        (appearance === 'dark' || appearance === 'gray' || appearance === 'transparent-orange');
                    else squareIconTpl
                "
                class="gc-inherit-color gc-m-right_medium"
                [src]="ICONS_MAP[item.iconName]"
            ></svg-icon>
            <ng-template #squareIconTpl>
                <gcore-square-icon
                    *ngIf="item.iconName"
                    [variant]="iconAppearance"
                    class="gc-m-right_medium"
                    [iconName]="item.iconName"
                    [shape]="shape"
                ></gcore-square-icon>
            </ng-template>
            <ng-container *ngIf="item.title && titlePosition === 'right'">
                <h3 *ngFor="let h4 of item.title | splitString" [innerHTML]="h4" class="gc-text_18"></h3>
            </ng-container>
        </div>
        <div *ngIf="item.title && titlePosition === 'bottom'" [class.gc-m-top_medium]="item.iconName">
            <h3 *ngFor="let h3 of item.title | splitString" [innerHTML]="h3" class="gc-text_18"></h3>
            <h3 *ngFor="let i of [].constructor(numUnderTitleEmptyLines)" class="gc-card-item-invisible gc-text_18">
                empty
            </h3>
        </div>
        <div *ngIf="item.description" [class.gc-m-top_medium]="item.iconName || titlePosition !== 'hidden'">
            <p *ngFor="let p of item.description | splitString" [innerHTML]="p" class="gc-text_16"></p>
        </div>
    </div>
    <div class="gc-relative">
        <ng-content></ng-content>
    </div>
</div>
