export enum LoginLinksEnum {
    Cloud = 'https://auth.gcore.com/login/signin',
    Hosting = 'https://hosting.gcore.com/billmgr?func=logon',
    CloudPreprod = 'https://auth.preprod.world/login/signin',
}

export enum RegisterLinksEnum {
    Cloud = 'https://auth.gcore.com/login/signup',
    Hosting = 'https://hosting.gcore.com/billmgr?func=register',
    CloudPreprod = 'https://auth.preprod.world/login/signup',
}

export const AuthLinkDefaultProductMap = new Map([
    ['cloud', 'CLOUD'],
    ['dns', 'DNS'],
    ['cdn', 'CDN'],
    ['streaming', 'STREAMING'],
    ['streaming-platform', 'STREAMING'],
    ['storage', 'STORAGE'],
    ['ddos-protection', 'DDOS'],
    ['web-security', 'DDOS'],
]);

export const CloudCustomInfoMap = new Map([
    [
        'bare-metal-servers',
        'eyJ0eXBlIjoicmVkaXJlY3QiLCJyZWRpcmVjdF9wYWdlIjoiYmFyZW1ldGFscy9jcmVhdGUiLCJyZWRpcmVjdF9kZXRhaWxzIjp7ImZsYXZvciI6ICJibTEtaGYtbWVkaXVtLWZha2UiLCAicmVnaW9uX2lkIjogIjM4In19',
    ],
    [
        'basic-vm',
        'eyJ0eXBlIjoicmVkaXJlY3QiLCAicmVkaXJlY3RfcGFnZSI6ICJ2bS9jcmVhdGUtYmFzaWMiLCAicmVkaXJlY3RfZGV0YWlscyI6IHsiZmxhdm9yX2lkIjogImcycy1zaGFyZWQtMS0xLTI1IiwgICJyZWdpb25faWQiOiAiMzgifX0%3D',
    ],
    [
        'managed-kubernetes',
        'eyJ0eXBlIjoicmVkaXJlY3QiLCJyZWRpcmVjdF9wYWdlIjoia3ViZXJuZXRlcy12Mi9jcmVhdGUiLCJyZWRpcmVjdF9kZXRhaWxzIjp7InJlZ2lvbl9pZCI6ICIzOCJ9fQ%3D%3D',
    ],
    [
        'faas',
        'eyJ0eXBlIjoicmVkaXJlY3QiLCJyZWRpcmVjdF9wYWdlIjoiZmFhcy9uYW1lc3BhY2VzL2NyZWF0ZS1uYW1lc3BhY2UiLCJyZWRpcmVjdF9kZXRhaWxzIjp7InJlZ2lvbl9pZCI6ICI2In19IA%3D%3D',
    ],
    [
        'managed-logging',
        'eyJ0eXBlIjoicmVkaXJlY3QiLCJyZWRpcmVjdF9wYWdlIjoibG9nZ2luZyIsInJlZGlyZWN0X2RldGFpbHMiOnsicmVnaW9uX2lkIjogIjYifX0%3D&promocode=LAASPROMO',
    ],
    [
        'managed-database-postgresql',
        'eyJ0eXBlIjoicmVkaXJlY3QiLCJyZWRpcmVjdF9wYWdlIjoiZGJhYXMvY3JlYXRlIiwicmVkaXJlY3RfZGV0YWlscyI6eyJyZWdpb25faWQiOiAiNzYifX0%3D',
    ],
    [
        'container-as-a-service',
        'eyJ0eXBlIjoicmVkaXJlY3QiLCJyZWRpcmVjdF9wYWdlIjoiY2Fhcy9jb250YWluZXJzL2NyZWF0ZSIsInJlZGlyZWN0X2RldGFpbHMiOnsicmVnaW9uX2lkIjogIjM4In19',
    ],
]);
