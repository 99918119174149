import { RouterEvent } from '@angular/router';
import { AccordionItem } from '../../models/';
import { LanguageEnum } from '../../enums';
import { BreadcrumbItem, BreadCrumbs, FaqSchema, VideoSchema } from './models';
import { breadcrumbsExcludedRoutes, breadcrumbsProductName } from './breadcrumbsProductName';

export function getFaqSchema(data: Array<AccordionItem>): FaqSchema {
    const faqItems =
        data?.map((item) => ({
            ['@type']: 'Question',
            name: item.title,
            acceptedAnswer: {
                ['@type']: 'Answer',
                text: item.description,
            },
        })) || [];

    return {
        ['@type']: 'FAQPage',
        mainEntity: faqItems,
    };
}

export function getBreadCrumbsSchema(breadcrumbs: Array<BreadcrumbItem>): BreadCrumbs {
    const itemListElement = breadcrumbs?.map((breadcrumb, idx) => {
        if (breadcrumb.item) {
            return {
                ['@type']: 'ListItem',
                position: idx + 1,
                name: breadcrumb.name,
                item: `https://gcore.com${breadcrumb.item}`,
            };
        }
        return {
            ['@type']: 'ListItem',
            position: idx + 1,
            name: breadcrumb.name,
            item: `https://gcore.com${breadcrumb.item}`,
        };
    });

    return {
        ['@type']: 'WebPage',
        breadcrumb: {
            ['@type']: 'BreadcrumbList',
            itemListElement,
        },
    };
}

export function createBreadCrumbs(data: RouterEvent): BreadCrumbs {
    const languages = Object.values(LanguageEnum) as Array<string>;
    let breadcrumbs;

    if (data.url === '/' || languages.includes(data.url.replace('/', ''))) {
        breadcrumbs = [
            {
                name: 'Home',
                item: data.url,
            },
        ];
    } else {
        const rmLangCharReg = new RegExp(`\/(${languages.join('|')})\/`);

        breadcrumbs = data.url
            .split('/')
            .reduce((acc: Array<BreadcrumbItem>, path: string, idx: number, array: Array<string>) => {
                const breadcrumbPath = array.slice(0, idx + 1).join('/');

                if (!path || breadcrumbsExcludedRoutes.includes(path) || languages.includes(path)) {
                    return acc;
                }

                return [
                    ...acc,
                    {
                        name:
                            breadcrumbsProductName[breadcrumbPath.replace(rmLangCharReg, '/')] ||
                            path
                                .split('-')
                                .map((name: string) => `${name[0].toUpperCase()}${name.slice(1)}`)
                                .join(' '),

                        item: breadcrumbPath,
                    },
                ];
            }, []);
    }

    return getBreadCrumbsSchema(breadcrumbs);
}

export function getVideoObjSchema(data: Array<VideoSchema>): Array<VideoSchema> {
    return data.map((videoObj) => ({
        ['@type']: 'VideoObject',
        contentUrl: videoObj.contentUrl,
        name: videoObj.name,
        duration: {
            min: videoObj.duration.min,
            sec: videoObj.duration.sec,
        },
        description: videoObj.description,
        uploadDate: videoObj.uploadDate,
        thumbnailUrl: videoObj.thumbnailUrl,
    }));
}

export function insertHeadSeoScript(schemaCollection: Array<any>): void {
    const schema = {
        ['@context']: 'https://schema.org',
        ['@graph']: [...schemaCollection],
    };
    const jsonSchema = `${JSON.stringify(schema, null, 2)}`;

    const node = document.createElement('script');
    node.type = 'application/ld+json';
    node.async = true;
    node.innerHTML = jsonSchema;

    document.getElementsByTagName('head')[0].appendChild(node);
}
