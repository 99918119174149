import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sliceDescription',
    standalone: true,
})
export class SliceDescriptionPipe implements PipeTransform {
    public transform(value: string, max: number): string {
        return `${value?.slice(0, max)}${value?.length > max ? '...' : ''}`;
    }
}
