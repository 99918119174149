import { CompareTableColumnInterface } from '../../components/compare-table';
import { RowItemEnum, TableRowConfigInterface } from '../models';

export const getTableConfigDiffRows = (
    comparePricings: Array<CompareTableColumnInterface>,
    tableConfigRows: Array<TableRowConfigInterface>,
): Array<TableRowConfigInterface> => {
    const rowFields: Array<string> = tableConfigRows.map((row) => row.field);
    const fieldsTypeMap = tableConfigRows.reduce((acc: Record<string, string>, row: TableRowConfigInterface) => {
        acc[row.field] = row.type;
        return acc;
    }, {});

    const diffRowFields: Array<string> = [];
    rowFields.forEach((field) => {
        let i = 0;
        while (i < comparePricings.length - 1) {
            const isEqualCurrentAndNextValues = comparePricings[i].rows[field] === comparePricings[i + 1].rows[field];
            if (fieldsTypeMap[field] === RowItemEnum.Str || !isEqualCurrentAndNextValues) {
                diffRowFields.push(field);
                break;
            }
            i++;
        }
    });

    return tableConfigRows.filter((row) => diffRowFields.includes(row.field));
};

export const filterTableConfigByCurrency = (
    tableConfigRows: Array<TableRowConfigInterface>,
    currency: string,
): Array<TableRowConfigInterface> =>
    tableConfigRows.filter((row) => !row.isPrice || new RegExp(currency, 'i').test(row.field));
