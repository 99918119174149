<footer
    *ngIf="content$ | async as content"
    [class.dark]="darkTheme"
    [class.gc-bg_primary]="darkTheme"
    [class.gc-bg_secondary]="!darkTheme"
>
    <div class="gc-container gc-container_grid gc-p-section_lg">
        <ul
            class="gc-column-wrapper gc-display_grid gc-grid-col_2 gc-grid-col-span_10 gc-grid-col-lg_full gc-row-gap_2 gc-column-gap_6"
        >
            <ng-container *ngFor="let category of content.categories">
                <li class="gc-footer-category">
                    <h3 class="gc-text_tertiary gc-paragraph gc-paragraph_sm" [class.dark]="darkTheme">
                        {{ category.category }}
                    </h3>
                    <div class="gc-flex gc-flex_column gc-gap_2 gc-mt_2">
                        <gc-link
                            *ngFor="let link of category.links"
                            gcorePhoneSwap
                            [content]="link"
                            [type]="'subtle'"
                            [size]="'sm'"
                            [darkTheme]="darkTheme"
                        />
                    </div>
                </li>
            </ng-container>
        </ul>
        <div
            class="gc-media-wrapper gc-display_flex gc-justify_between gc-align-items_center gc-mt_16 gc-mt-sm_12 gc-py_2 gc-border_primary gc-grid-col_2 gc-grid-col-span_10 gc-grid-col-lg_full"
            [class.dark]="darkTheme"
        >
            <ul class="gc-flex gc-gap_2 gc-align-items_center">
                <li *ngFor="let media of content.social_media_links">
                    <gc-button-icon
                        [darkTheme]="darkTheme"
                        type="subtle"
                        [content]="{
                            icon: media.iconName,
                            url: media.url
                        }"
                    />
                </li>
            </ul>
            <gcore-language-selector
                position="top"
                [darkTheme]="darkTheme"
                [secondaryTheme]="darkTheme"
                [availableLanguages]="availableLanguages"
            />
        </div>
        <div
            class="gc-additional-wrapper gc-display_flex gc-gap_6 gc-justify_between gc-flex-sm_column-reverse gc-mt_12 gc-mt-sm_8 gc-grid-col_2 gc-grid-col-span_10 gc-grid-col-lg_full"
        >
            <span class="gc-text_tertiary gc-paragraph gc-paragraph_sm" [class.dark]="darkTheme">
                {{ content.copyright }}
            </span>
            <ul class="gc-display_flex gc-gap_4 gc-flex_wrap">
                <li
                    class="gc-display_flex gc-align-items_center gc-gap_4"
                    *ngFor="let link of content.additionalLinks; let index = index"
                >
                    <gc-link [content]="link" type="subtle" size="sm" [darkTheme]="darkTheme" />
                    <div *ngIf="index !== content.additionalLinks.length - 1" class="gc-divider"></div>
                </li>
            </ul>
        </div>
    </div>
</footer>
