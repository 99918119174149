import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { LocalizationService } from '../../services';

interface UrlWithQueryParams {
    url: string;
    attr: string;
    value: string;
}

@Directive({
    selector: '[gcoreUrlParse]',
    standalone: true,
})
export class UrlParseDirective implements OnInit, OnDestroy {
    @Input() public gcoreUrlParse: string;

    private removeEventListener: () => void;

    constructor(
        private elementRef: ElementRef,
        private sanitizer: DomSanitizer,
        private renderer: Renderer2,
        private route: ActivatedRoute,
        private router: Router,
        private localizationService: LocalizationService,
    ) {}

    public ngOnInit(): void {
        const sanitizedValue = this.sanitizer.sanitize(SecurityContext.URL, this.gcoreUrlParse) || '';
        const element = this.elementRef.nativeElement as HTMLLinkElement;
        const trimmedHref = sanitizedValue.replace(/\.?\.\//g, '');
        const localizedRoute =
            trimmedHref.length < sanitizedValue.length
                ? this.localizationService.getLanguageRoute() + trimmedHref
                : sanitizedValue;
        this.renderer.setAttribute(element, 'href', localizedRoute);

        if (sanitizedValue.startsWith('http')) {
            this.renderer.setAttribute(element, 'target', '_blank');
            return;
        }

        if (
            sanitizedValue.startsWith('/blog') ||
            sanitizedValue.startsWith('/learning') ||
            sanitizedValue.startsWith('/docs') ||
            sanitizedValue.startsWith('/news') ||
            sanitizedValue.startsWith('/events') ||
            sanitizedValue.startsWith('/press') ||
            sanitizedValue.startsWith('/case-studies') ||
            sanitizedValue.startsWith('/library') ||
            sanitizedValue.startsWith('/interview')
        ) {
            this.renderer.setAttribute(element, 'target', '_self');
            return;
        }

        if (sanitizedValue.includes('#')) {
            const [url, fragment] = sanitizedValue.split('#');
            this.addEventListenerToLink(url, { fragment });
            return;
        }

        if (sanitizedValue.startsWith('+')) {
            this.renderer.setAttribute(element, 'href', `tel:${sanitizedValue}`);
            return;
        }

        if (sanitizedValue.includes('@')) {
            this.renderer.setAttribute(element, 'href', `mailto:${sanitizedValue}`);
            return;
        }

        if (sanitizedValue.includes('?')) {
            const { url, attr, value } = this.getUrlWithQueryParams(sanitizedValue);
            this.addEventListenerToLink(url, { queryParams: { [attr]: value } });
            return;
        }

        this.addEventListenerToLink(sanitizedValue);
    }

    public ngOnDestroy(): void {
        if (this.removeEventListener) {
            this.removeEventListener();
        }
    }

    private addEventListenerToLink(url: string, options: NavigationExtras = {}): void {
        this.removeEventListener = this.renderer.listen(this.elementRef.nativeElement, 'click', (e) => {
            e.preventDefault();
            this.router.navigate([url], {
                relativeTo: this.route,
                ...options,
            });
        });
    }

    private getUrlWithQueryParams(rowUrl: string): UrlWithQueryParams {
        const isUrlWithParams = /^(.*?)\?(.*?)=(.*?)$/;
        const [url, attr, value] = rowUrl.match(isUrlWithParams).slice(1);
        return { url, attr, value };
    }
}
