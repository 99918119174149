import { CompareTableColumnInterface } from '../../components/compare-table';
import { PriceTableColumnInterface } from '../../components/price-table';
import { TableRowConfigInterface } from '../models';
import { LoadBalancersPricing } from '../../pages/cloud/load-balancers/load-balancers.model';

export const mapToPricing = (pricing: any): LoadBalancersPricing => {
    return {
        title: pricing.title,
        price: pricing.price,
        showCurrency: pricing.showCurrency,
        button: pricing.button,
        mostPopular: pricing.mostPopular,
    };
};

export const mapIntelSgxPricingToTable = (
    column: any,
    rowsConfig: Array<TableRowConfigInterface>,
): PriceTableColumnInterface => {
    return {
        name: undefined,
        price: column.monthPrice,
        priceUSD: column.priceUSD,
        isCustomPrice: isNaN(column.monthPrice),
        hourPrice: column.hourPrice,
        isHourCustomPrice: isNaN(column.hourPrice),
        button: undefined,
        description: undefined,
        rows: rowsConfig.reduce((records, { field }) => {
            records[field] = column[field];
            return records;
        }, {} as Record<string, string>),
    };
};

export const mapToPricingTable = (
    pricing: any,
    rowsConfig: Array<TableRowConfigInterface>,
): PriceTableColumnInterface => {
    return {
        name: pricing.name,
        price: pricing.price,
        priceUSD: pricing.priceUSD,
        priceLabel: pricing.priceLabel,
        priceTemplate: pricing.priceTemplate,
        priceTemplateUSD: pricing.priceTemplateUSD,
        isCustomPrice: isNaN(pricing.price),
        type: pricing.type,
        button: pricing.button
            ? {
                  text: pricing.button.text,
                  url: pricing.button.url?.startsWith('http') ? `${pricing.button.url as string}` : pricing.button.url,
              }
            : null,
        description: pricing.description,
        columnLabel: pricing.columnLabel,
        rows: rowsConfig.reduce((records, { field }) => {
            records[field] = pricing[field];
            return records;
        }, {} as Record<string, string>),
    };
};

export const mapToPricingTableForGamingDdos = (
    pricing: any,
    rowsConfig: Array<TableRowConfigInterface>,
): PriceTableColumnInterface => {
    return {
        ...mapToPricingTable(pricing, rowsConfig),
        button: {
            url: `../${pricing.button.url as string}`,
            text: pricing.button.text,
        },
    };
};

export const mapToCompareTable = (
    column: any,
    rowsConfig: Array<TableRowConfigInterface>,
): CompareTableColumnInterface => {
    return {
        name: column.name || column.companyName,
        iconName: column.iconName || column.logo,
        button: column.button,
        rows: rowsConfig.reduce((records, { field }) => {
            records[field] = column[field];
            return records;
        }, {} as Record<string, string>),
    };
};

const priceFieldNames = [
    'pricePerMonth_EUR',
    'pricePerYear_EUR',
    'pricePerMonth_USD',
    'pricePerYear_USD',
    'price',
    'price_EUR',
    'price_USD',
];

export const markPriceInRowsConfig = (rowsConfig: Array<TableRowConfigInterface>): Array<TableRowConfigInterface> => {
    return rowsConfig.map((row) => ({ ...row, isPrice: priceFieldNames.includes(row.field) }));
};

export const getPricingFields = (data: any, pricingFieldName?: string, pricingTableConfigName?: string): any => ({
    pricings: data[pricingFieldName || 'pricings'].data.map(({ attributes }: any) => {
        const pricingColumn = mapToPricingTable(attributes, data[pricingTableConfigName || 'pricingTableConfig'].rows);
        if (pricingColumn.button.url.startsWith('..')) {
            pricingColumn.button.url = `../${pricingColumn.button.url}`;
        }
        return pricingColumn;
    }),
    pricingTableConfig: data[pricingTableConfigName || 'pricingTableConfig'],
    mostPopularPricing: data.pricings.data.findIndex(({ attributes }: any) => attributes.mostPopular),
});

export const mapPriceTableAttributes = (attributes: Record<string, any>): Record<string, any> => ({
    ...attributes,
    ...(attributes.columnConfig
        ? attributes.columnConfig.reduce(
              (acc: Record<string, string>, el: Record<string, string>) => ({
                  ...acc,
                  [el.field]: el.value,
              }),
              {},
          )
        : {
              rows: attributes,
          }),
});
