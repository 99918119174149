/* eslint-disable @typescript-eslint/naming-convention */

export const FORM_FEEDBACK_URL = 'thank-you';
export const FORM_SOURCE = 'https://l.go.gcore.com';
export const FORM_MUNCHKIN_ID = '359-JAE-779';
export const FORM_EVENT = {
    emergency: 'feedback_emergency-ddos-protection',
    'global-ddos': 'feedback_global-ddos-protection',
    whitepaper: 'whitepaper_new_ddos-protection',
    'contact-us': 'feedback_contact-us',
    referral: 'referral_program',
    subscription: 'email_form_footer',
};
