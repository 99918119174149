import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { CommonModule } from '@angular/common';
import { SquareIconComponent, SquareIconShape, SquareIconVariant } from '../square-icon';
import { CardItem, CardItemWithList } from '../../../core/models';
import { ICONS_MAP } from '../../../core/constants';
import { SplitStringPipe } from '../../../core/pipes/split-string';

export type CardItemAppearanceType =
    | 'transparent'
    | 'dark'
    | 'white'
    | 'gray'
    | 'transparent-border'
    | 'lilac'
    | 'dark-orange'
    | 'transparent-orange';

@Component({
    selector: 'gcore-card-item',
    templateUrl: './card-item.component.html',
    styleUrls: ['./card-item.component.scss'],
    imports: [CommonModule, SvgIconComponent, SplitStringPipe, SquareIconComponent],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardItemComponent {
    @Input() public item: CardItem | CardItemWithList;
    @Input() public appearance: CardItemAppearanceType = 'transparent';
    @Input() public titlePosition: 'right' | 'bottom' | 'hidden' = 'hidden';
    @Input() public numUnderTitleEmptyLines: number = 0;
    @Input() public iconAppearance: SquareIconVariant = 'brand';
    @Input() public isHasList: boolean = false;
    @Input() public shape: SquareIconShape = 'square';

    public ICONS_MAP = ICONS_MAP;
}
