import config from './../../config';

const urls = [/^https:\/\/site.preprod.world/, /^https:\/\/gcore\.com/];

export async function initSentry(): Promise<void> {
    if (!config.sentryDsn) {
        return;
    }

    try {
        const Sentry = await import('@sentry/browser');

        Sentry.init({
            dsn: config.sentryDsn,
            environment: config.environment,
            maxBreadcrumbs: 20,
            tracesSampleRate: 0.3,
            release: config.releaseVersion,
            allowUrls: urls,
            integrations: [Sentry.browserTracingIntegration()],
            tracePropagationTargets: urls,
        });

        console.log('Sentry initialized successfully');
    } catch (error) {
        console.error('Sentry failed to start', error);
    }
}
