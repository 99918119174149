import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { map, Observable, tap } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SvgIconComponent } from 'angular-svg-icon';
import { CommonModule } from '@angular/common';
import { StrapiContentService } from '../../core/services';
import { LinkComponent } from '../../ui-kit/actions/link/link.component';
import { LanguageSelectorComponent } from '../../components/language-selector/language-selector.component';
import { LanguageEnum } from '../../core/enums';
import { ICONS_COLLECTION } from '../../core/constants';
import { ButtonIconComponent } from '../../ui-kit/actions/button-icon/button-icon.component';
import { ScreenBreakpointsEnum, ScreenTypeEnum } from '../../core/services/device';
import { PhoneSwapDirective } from '../../ui-kit/actions/link/phone-swap.directive';
import { FooterCategory, FooterContent } from './footer.model';

@UntilDestroy()
@Component({
    selector: 'gcore-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        RouterLink,
        SvgIconComponent,
        LinkComponent,
        LanguageSelectorComponent,
        ButtonIconComponent,
        PhoneSwapDirective,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements OnInit {
    @Input() public darkTheme: boolean;
    @Input() public availableLanguages: Array<LanguageEnum>;
    public content$: Observable<FooterContent>;
    public positions: any;
    protected readonly ICONS_COLLECTION = ICONS_COLLECTION;

    constructor(private element: ElementRef, private strapiContentService: StrapiContentService) {}

    public ngOnInit(): void {
        this.content$ = this.getContent();
    }

    public getContent(): Observable<FooterContent> {
        return this.strapiContentService.getSingleContentType('footer', 'footer').pipe(
            map((data) => ({
                ...data,
                social_media_links: data.social_media_links.data.map((media: any) => media.attributes),
            })),
            tap((content) => {
                const style = document.createElement('style');
                style.innerHTML = `
                    ${this.calculateCategories(content, ScreenTypeEnum.LG)}
                    @media (max-width: ${ScreenBreakpointsEnum.MD}px) {
                        ${this.calculateCategories(content, ScreenTypeEnum.MD)}
                    }
                    
                    @media (max-width: ${ScreenBreakpointsEnum.SM}px) {
                        ${this.calculateCategories(content, ScreenTypeEnum.SM)}
                    }
                `;
                this.element.nativeElement.appendChild(style);
            }),
        );
    }

    private calculateCategories(content: FooterContent, device: ScreenTypeEnum): string {
        let columnOrder = 1;
        let rowOrder = 0;
        const maxRow = device === ScreenTypeEnum.SM ? +Math.round(content.categories.length / 2) - 1 : 1;
        return content.categories
            .map((category: FooterCategory, index) => {
                const config = {
                    position: [columnOrder, columnOrder + 1],
                    gap: rowOrder === maxRow ? 1 : 2,
                    order: index,
                };

                if (device !== ScreenTypeEnum.SM) {
                    if (device === ScreenTypeEnum.LG && category.forceNewColumnAfter) rowOrder = 1;
                    if (rowOrder === 1) {
                        rowOrder = 0;
                        columnOrder++;
                    } else {
                        rowOrder++;
                    }
                }

                if (device === ScreenTypeEnum.SM) {
                    if (index === maxRow) {
                        columnOrder++;
                        rowOrder = 0;
                    } else {
                        rowOrder++;
                    }
                }
                this.customCategoriesSwap(config, device, index);
                return `.gc-footer-category:nth-child(${index + 1}) { grid-column: ${config.position.join(
                    '/',
                )}; grid-row: span ${category.links.length + config.gap}; order: ${config.order};}`;
            })
            .join(' ');
    }

    private customCategoriesSwap(config: any, device: ScreenTypeEnum, index: number): void {
        if (device === ScreenTypeEnum.MD) {
            if (index === 1) {
                config.position = [2, 3];
                config.gap = 2;
            }
            if (index === 2) {
                config.position = [2, 3];
                config.gap = 1;
            }
            if (index === 3) {
                config.position = [1, 2];
                config.gap = 1;
            }
        }

        if (device === ScreenTypeEnum.SM) {
            if (index === 1) {
                config.order = 2;
                config.gap = 2;
            }
            if (index === 2) {
                config.order = 3;
                config.gap = 1;
            }
            if (index === 3) {
                config.position = [1, 2];
                config.gap = 2;
                config.order = 1;
            }
        }
    }
}
