<div
    class="gc-text gc-flex gc-flex-v-center gc-m-bottom_medium gc-custom-li-item"
    [ngClass]="{
        'gc-list-item-checked_dark': variant === 'dark',
        'gc-list-item-checked_white': variant === 'white',
        'gc-list-item-checked_violet': variant === 'violet',
        'gc-list-item-checked_orange': variant === 'orange'
    }"
    [class.gc-flex-v-top]="iconAlign === 'top'"
>
    <div class="gc-point-checkmark gc-m-right_medium">
        <svg-icon aria-hidden="true" class="gc-inherit-color" src="/assets/icons/check-mark.svg"></svg-icon>
    </div>
    {{ text }}
</div>
