export const marketingCookies = [
    '__hstc',
    'hubspotutk',
    '_gcl_au',
    '_fbp',
    '__hssc',
    '__hssrc',
    'messagesUtk',
    'guest_id_ads',
    'twid',
    'auth_token',
    'DV',
    'OTZ',
];
export const statisticCookies = ['_ga', '_gat', '_gid', 'dc_gtm*', 'MUID'];
export const essentialCookies = [
    'intercom-id',
    'intercom-session',
    'cookie_consent',
    'hosting_refer',
    'support_related_*',
    'ACCWID_FT',
    'ACCWID_PRODUCTS_VISIBILITY',
    'AuthToken',
    'utm_params',
    'currency',
    'gc-language',
    'oribiuserguid',
    'confirmed_cookie',
    '_ym_uid',
    'sbjs_udata',
    'sbjs_current',
];
