<a
    *ngIf="content && content.text"
    class="gc-link gc-display_inline-flex gc-link_{{ type }}"
    [gcoreLink]="content.url"
    [class.gc-gap_2]="size === 'lg'"
    [class.gc-gap-special_sm]="size === 'md' || size === 'sm'"
    [class.dark]="darkTheme"
>
    <svg-icon
        *ngIf="content.icon"
        class="gc-icon"
        [class.dark]="darkTheme"
        [class.gc-icon_extra-small]="size === 'sm' || size === 'md'"
        [class.gc-icon_small]="size === 'lg'"
        [class.gc-flex-order_1]="iconPosition === 'right'"
        [src]="ICONS_COLLECTION[content.icon]"
    />
    <span
        class="gc-label"
        [class.gc-label_md]="size === 'lg'"
        [class.gc-label_sm]="size === 'md'"
        [class.gc-label_xs]="size === 'sm'"
        [class.gc-text_underline]="underline"
        [class.dark]="darkTheme"
        [class.disabled]="state === 'disabled'"
        [class.selected]="state === 'selected'"
        >{{ content.text }}</span
    >
</a>
