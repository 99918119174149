/* eslint-disable @typescript-eslint/naming-convention */

export const pagesList: Record<string, () => Promise<any>> = {
    home: () => import('./home/home.component').then((m) => m.HomeComponent),
    'about-page': () => import('./about/about.component').then((m) => m.AboutComponent),
    'speech-to-text-page': () =>
        import('./speech-to-text/speech-to-text.component').then((r) => r.SpeechToTextComponent),
    'careers-page': () => import('./careers/careers.component').then((r) => r.CareersComponent),
    'cdn-page': () => import('./cdn/cdn.component').then((r) => r.CdnComponent),
    'dns-page': () => import('./dns/dns.component').then((r) => r.DnsComponent),
    'managed-dns-page': () => import('./managed-dns/managed-dns.component').then((r) => r.ManagedDnsComponent),
    'cloud-page': () => import('./cloud/cloud.component').then((r) => r.CloudComponent),
    'storage-page': () => import('./storage/storage.component').then((r) => r.StorageComponent),
    'partner-ampere-page': () => import('./partners-company/ampere/ampere.component').then((r) => r.AmpereComponent),
    'partner-equinix-page': () =>
        import('./partners-company/equinix/partner-equinix.component').then((r) => r.PartnerEquinixPageComponent),
    'partner-hesp-page': () =>
        import('./partners-company/hesp/partner-hesp.component').then((r) => r.PartnerHespPageComponent),
    'partner-unum-page': () => import('./partners-company/unum/unum.component').then((r) => r.UnumComponent),
    'partner-in-data-page': () => import('./partners-company/in-data/in-data.component').then((r) => r.InDataComponent),
    'partner-intel-page': () =>
        import('./partners-company/intel/partner-intel.component').then((r) => r.PartnerIntelPageComponent),
    'new-generation-page': () =>
        import('./partners-company/intel/new-generation/new-generation.component').then(
            (r) => r.NewGenerationComponent,
        ),
    'icelakes-page': () =>
        import('./partners-company/intel/icelakes/icelakes.component').then((r) => r.IcelakesComponent),
    'infrastructure-for-startups-page': () =>
        import('./infrastructure-for-startups/infrastructure-for-startups.component').then(
            (r) => r.InfrastructureForStartupsComponent,
        ),
    'edge-network-page': () => import('./edge-network/edge-network.component').then((r) => r.EdgeNetworkComponent),
    'cloudflare-alternative-page': () =>
        import('./edge-network/cloudflare-alternative/cloudflare-alternative.component').then(
            (r) => r.CloudflareAlternativeComponent,
        ),
    'infrastructure-terms-page': () =>
        import('./infrastructure-terms/infrastructure-terms.component').then((r) => r.InfrastructureTermsComponent),
    'hosting-page': () => import('./hosting/hosting.component').then((r) => r.HostingComponent),
    'internet-options-page': () =>
        import('./hosting/internet-options/internet-options.component').then((r) => r.InternetOptionsComponent),
    'hosting-gpu-servers-page': () =>
        import('./hosting/dedicated-gpu/dedicated-gpu.component').then((r) => r.DedicatedGPUPageComponent),
    'hosting-dedicated-servers-page': () =>
        import('./hosting/dedicated/dedicated.component').then((r) => r.DedicatedPageComponent),
    'miscellaneous-page': () =>
        import('./hosting/miscellaneous/miscellaneous.component').then((r) => r.MiscellaneousComponent),
    'ssl-page': () => import('./hosting/ssl/ssl.component').then((r) => r.SslComponent),
    'streaming-platform-page': () =>
        import('./streaming-platform/features/features.component').then((r) => r.FeaturesComponent),
    'features-demo-page': () =>
        import('./streaming-platform/features-demo/features-demo.component').then((r) => r.FeaturesDemoComponent),
    'features-demo-inner-page': () =>
        import('./streaming-platform/features-demo/features-demo-inner-page/features-demo-inner-page.component').then(
            (r) => r.FeaturesDemoInnerPageComponent,
        ),
    'online-education-page': () =>
        import('./streaming-platform/online-education/online-education.component').then(
            (r) => r.OnlineEducationComponent,
        ),
    'web-application-page': () =>
        import('./web-application/web-application.component').then((r) => r.WebApplicationComponent),
    'gaming-cdn-page': () => import('./cdn/gaming/gaming.component').then((r) => r.GamingComponent),
    'cdn-wordpress-page': () => import('./cdn/wordpress/wordpress.component').then((r) => r.WordpressComponent),
    'e-commerce-cdn-page': () =>
        import('./cdn/e-commerce-cdn/e-commerce-cdn.component').then((r) => r.ECommercePageComponent),
    'multi-cdn-page': () => import('./cdn/multi-cdn/multi-cdn.component').then((r) => r.MultiCdnComponent),
    'video-cdn-page': () => import('./cdn/video-cdn/video-cdn.component').then((r) => r.VideoCdnComponent),
    'it-infrastructure-management': () =>
        import('./it-infrastructure-management/it-infrastructure-management.component').then(
            (r) => r.ItInfrastructureManagementComponent,
        ),
    'gaming-ddos-page': () =>
        import('./global-ddos-protection/gaming-ddos/gaming-ddos.component').then((r) => r.GamingDdosComponent),
    'marketplace-page': () => import('./cloud/marketplace/marketplace.component').then((r) => r.MarketplaceComponent),
    'load-balancers-page': () =>
        import('./cloud/load-balancers/load-balancers.component').then((r) => r.LoadBalancersComponent),
    'network-page': () => import('./streaming-platform/network/network.component').then((r) => r.NetworkComponent),
    'virtual-private-page': () =>
        import('./cloud/virtual-private/virtual-private.component').then((r) => r.VirtualPrivateComponent),
    'bare-metal-page': () => import('./cloud/bare-metal/bare-metal.component').then((r) => r.BareMetalComponent),
    'recovery-page': () => import('./cloud/recovery/recovery.component').then((r) => r.RecoveryComponent),
    'pen-test-page': () => import('./pen-test/pen-test.component').then((r) => r.PenetrationTestPageComponent),
    'managed-logging-page': () =>
        import('./cloud/managed-logging/managed-logging.component').then((r) => r.ManagedLoggingComponent),
    'managed-logging-page-v2': () =>
        import('./cloud/managed-logging/v2/managed-logging.component').then((r) => r.ManagedLoggingComponent),
    'managed-kubernetes-page': () =>
        import('./cloud/managed-kubernetes/managed-kubernetes.component').then((r) => r.ManagedKubernetesComponent),
    'mkaas-page': () => import('./cloud/mkaas/mkaas.component').then((r) => r.MkaasComponent),
    'intel-sgx-page': () => import('./cloud/intel-sgx/intel-sgx.component').then((r) => r.IntelSgxComponent),
    'migration-page': () => import('./migration/migration.component').then((r) => r.MigrationComponent),
    'cloud-financial-services-page': () =>
        import('./cloud/financial-services/financial-services.component').then((r) => r.FinancialServicesComponent),
    'veeam-backups-page': () =>
        import('./cloud/veeam-backup/veeam-backup.component').then((r) => r.VeeamBackupComponent),
    'global-ddos-protection-page': () =>
        import('./global-ddos-protection/global-ddos-protection.component').then(
            (r) => r.GlobalDdosProtectionComponent,
        ),
    'image-stack-page': () => import('./image-stack/image-stack.component').then((r) => r.ImageStackComponent),
    'faas-page': () => import('./cloud/faas/faas.component').then((r) => r.FaasPageComponent),
    'cloud-computer-resources-page': () =>
        import('./cloud/computer-resources/computer-resources.component').then((r) => r.ComputerResourcesComponent),
    'cloud-basic-vm': () => import('./cloud/basic-vm/basic-vm.component').then((r) => r.BasicVmComponent),
    'streaming-web-rtc-page': () =>
        import('./streaming-platform/webrtc/webrtc.component').then((r) => r.WebRTCPageComponent),
    'streaming-live-page': () =>
        import('./streaming-platform/live/streaming-live.component').then((r) => r.StreamingLiveComponent),
    'streaming-computer-vision-page': () =>
        import('./streaming-platform/computer-vision/computer-vision.component').then((r) => r.ComputerVisionComponent),
    'streaming-platform-video-hosting': () =>
        import('./streaming-platform/video-hosting/video-hosting.component').then(
            (r) => r.StreamingPlatformVideoHostingComponent,
        ),
    'streaming-platform-online-events-page': () =>
        import('./streaming-platform/online-events/online-events.component').then((r) => r.OnlineEventsComponent),
    'streaming-metaverse': () =>
        import('./streaming-platform/metaverse/metaverse.component').then((r) => r.MetaverseComponent),
    'streaming-broadcasting-page': () =>
        import('./streaming-platform/broadcasting/broadcasting.component').then((r) => r.BroadcastingComponent),
    'streaming-solution': () =>
        import('./landing-pages/streaming-solution-page/streaming-solution-page.component').then(
            (r) => r.StreamingSolutionPageComponent,
        ),
    'hosting-vsd-page': () => import('./hosting/vds/vds.component').then((r) => r.VdsComponent),
    'legal-page': () => import('./legal/legal.component').then((r) => r.LegalComponent),
    'legal-archive': () => import('./legal-archive/legal-archive.component').then((r) => r.LegalArchiveComponent),
    datenschutzrichtlinie: () =>
        import('./privacy-policy/privacy-policy.component').then((m) => m.PrivacyPolicyComponent),
    'bug-bounty-policy-page': () =>
        import('./bug-bounty-policy/bug-bounty-policy.component').then((r) => r.BugBountyPolicyComponent),
    'white-lable-services-page': () =>
        import('./white-label-services/white-label-services.component').then((r) => r.WhiteLabelServicesComponent),
    'resolver-page': () => import('./resolver/resolver.component').then((r) => r.ResolverPageComponent),
    'streaming-platform-main-page': () =>
        import('./streaming-platform/streaming-platform.component').then((r) => r.StreamingPlatformComponent),
    'sport-solutions-page': () =>
        import('./streaming-platform/sport-solutions/sport-solutions.component').then((r) => r.SportSolutionsComponent),
    'pricing-page': () => import('./pricing/pricing.component').then((r) => r.PricingComponent),
    'pricing-security-page': () =>
        import('./pricing/security/security.component').then((r) => r.PricingSecurityComponent),
    'pricing-edge-network-page': () =>
        import('./pricing/edge-network/edge-network.component').then((r) => r.PricingEdgeNetworkComponent),
    'pricing-cloud-page': () => import('./pricing/cloud/cloud.component').then((r) => r.PricingCloudComponent),
    'pricing-ai-page': () => import('./pricing/ai/ai.component').then((r) => r.PricingAiComponent),
    'pricing-streaming-platform': () =>
        import('./pricing/streaming-platform/streaming-platform.component').then(
            (r) => r.PricingStreamingPlatformComponent,
        ),
    'review-reward-page': () => import('./review-reward/review-reward.component').then((r) => r.ReviewRewardComponent),
    'not-found-page': () => import('./page-not-found/page-not-found.component').then((r) => r.PageNotFoundComponent),
    'referral-program-terms-page': () =>
        import('./referral-program-terms/referral-program-terms.component').then(
            (r) => r.ReferralProgramTermsComponent,
        ),
    'referral-program-page': () =>
        import('./referral-program/referral-program.component').then((r) => r.ReferralProgramComponent),
    'internet-peering-page': () =>
        import('./internet-peering/internet-peering.component').then((r) => r.InternetPeeringComponent),
    'secure-infrastructure-page': () =>
        import('./secure-infrastructure/secure-infrastructure.component').then((r) => r.SecureInfrastructureComponent),
    'infrastructure-page': () =>
        import('./infrastructure/infrastructure.component').then((r) => r.InfrastructureComponent),
    'cloud-secure-page': () =>
        import('./cloud/secure-cloud-computing/secure-cloud-computing.component').then(
            (r) => r.SecureCloudComputingComponent,
        ),
    'web-service-page': () => import('./cloud/web-service/web-service.component').then((r) => r.WebServiceComponent),
    'esim5g-platform': () =>
        import('./mobile/esim-5g-platform/esim-5g-platform.component').then((r) => r.Esim5gPlatformComponent),
    'support-ukraine-page': () =>
        import('./support-ukraine/support-ukraine.component').then((r) => r.SupportUkraineComponent),
    'edge-network-website-speed-up-page': () =>
        import('./edge-network/website-speed-up/website-speed-up.component').then((r) => r.WebsiteSpeedUpComponent),
    'ai-gpu-page': () => import('./cloud/ai-gpu/ai-gpu.component').then((r) => r.AiGpuComponent),
    'ai-image-generator-page': () =>
        import('./cloud/ai-image-generator/ai-image-generator.component').then((r) => r.AiImageGeneratorComponent),
    'development-page': () => import('./development/development.component').then((r) => r.DevelopmentComponent),
    'veeam-backup-page': () => import('./cloud/file-shares/file-shares.component').then((r) => r.FileSharesComponent),
    'game-development-cloud-page': () => import('./cloud/gamedev/gamedev.component').then((r) => r.GamedevComponent),
    'managed-database-page': () =>
        import('./cloud/managed-database-postgresql/managed-database-postgresql.component').then(
            (r) => r.ManagedDatabasePostgresqlComponent,
        ),
    'cloud-online-store-page': () =>
        import('./cloud/onlinestore/onlinestore.component').then((r) => r.OnlinestoreComponent),
    'cloud-migration-page': () => import('./cloud/migration/migration.component').then((r) => r.MigrationComponent),
    'fast-edge-page': () => import('./fast-edge/fast-edge.component').then((r) => r.FastEdgeComponent),
    'caas-page': () =>
        import('./cloud/container-as-a-service/container-as-a-service.component').then(
            (r) => r.ContainerAsAServiceComponent,
        ),
    'awards-page': () => import('./awards-page/awards-page.component').then((r) => r.AwardsPageComponent),
    'erp-page': () => import('./cloud/erp-page/erp-page.component').then((m) => m.ErpPageComponent),
    'inference-page': () => import('./inference/inference.component').then((m) => m.InferenceComponent),
    'search-page': () => import('./search-page/search-page.component').then((m) => m.SearchPageComponent),
    'black-friday-page': () =>
        import('./promo/blackfriday-product/blackfriday-product.component').then((c) => c.BlackfridayProductComponent),
    'black-friday-main-2023': () =>
        import('./promo/blackfriday-main/blackfriday-main.component').then((c) => c.BlackfridayMainComponent),
    'streaming-platform-calculator': () =>
        import('./streaming-platform-calculator/streaming-platform-calculator.component').then(
            (r) => r.StreamingPlatformCalculatorComponent,
        ),
    'servico-global-de-protecao-ddos': () =>
        import(
            './global-ddos-protection/global-ddos-protection-portuguese/global-ddos-protection-portuguese.component'
        ).then((r) => r.GlobalDdosProtectionPortugueseComponent),
    'cloud-basic-vm-smb': () =>
        import('../pages/landing-pages/basic-vm-smb/basic-vm-smb.component').then((m) => m.BasicVmSmbComponent),
    'dev-tools-navigation': () =>
        import('./dev-tools/navigation-page/navigation-page.component').then((c) => c.NavigationPageComponent),
    'dns-lookup': () => import('./dev-tools/dns-lookup/dns-lookup.component').then((c) => c.DnsLookupComponent),
    'conv-base64': () =>
        import('./dev-tools/base64-converter/base64-converter.component').then((c) => c.Base64ConverterComponent),
    'decode-jwt': () => import('./dev-tools/jwt-decoder/jwt-decoder.component').then((c) => c.JwtDecoderComponent),
    'calc-net-ipv4': () =>
        import('./dev-tools/ipv4-calculator/ipv4-calculator.component').then((c) => c.Ipv4CalculatorComponent),
    'calc-net-ipv6': () =>
        import('./dev-tools/ipv6-calculator/ipv6-calculator.component').then((c) => c.Ipv6CalculatorComponent),
    'calc-hash': () =>
        import('./dev-tools/hash-calculator/hash-calculator.component').then((c) => c.HashCalculatorComponent),
    'calc-unix': () =>
        import('./dev-tools/unix-calculator/unix-calculator.component').then((c) => c.UnixCalculatorComponent),
    'gen-uuid-v4': () =>
        import('./dev-tools/uuid-generator/uuid-generator.component').then((c) => c.UuidGeneratorComponent),
};
