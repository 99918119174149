import { CommonModule, DOCUMENT, KeyValue } from '@angular/common';
import {
    AfterViewChecked,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { ActivatedRoute, Data, NavigationEnd, Router, RouterLink } from '@angular/router';
import { combineLatest, filter, map, Observable, switchMap } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { isScullyRunning } from '@scullyio/ng-lib';
import { SvgIconComponent } from 'angular-svg-icon';
import { FormsModule } from '@angular/forms';
import { AnalyticsService, LocalizationService, SeoService, StrapiContentService } from '../../core/services';
import { HEADER_ICONS_MAP, HEADER_NEW_ICONS_MAP, MOBILE_DEVICE_BREAKPOINT, PINNED_HEADER } from '../../core/constants';
import {
    AuthLinkDefaultProductMap,
    CloudCustomInfoMap,
    LanguageEnum,
    LoginLinksEnum,
    RegisterLinksEnum,
} from '../../core/enums';
import { ClickOutsideDirective } from '../../core/directives';
import { SearchService } from '../../core/services/search.service';
import { toggleScroll } from '../../core/utils/scroll-toggle';
import { ModalLoginComponent, ModalLoginEmitEnum } from '../../components/modal-login';
import { ModalComponent, ModalSize } from '../../ui-kit/ui-kit-old/modal';
import { AnalyticsEventDirective } from '../../core/directives/analytics-event/analytics-event.directive';
import { CurrentMenuItem, LoginModalContent, MenuItem } from '../../core/models';
import { SliceDescriptionPipe } from '../../core/pipes/slice-description/slice-description.pipe';
import { LogoComponent } from '../../ui-kit/ui-kit-old/logo';
import { DeviceTypeEnum } from '../../core/services/device';
import { LanguageSelectorComponent } from '../../components/language-selector/language-selector.component';
import { TagComponent } from '../../ui-kit/indicators/tag/tag.component';
import { SearchComponent } from './search/search.component';
import { ChildMenuDirective } from './expandable-menu-item/child-menu.directive';
import { DropdownMenuItemComponent } from './dropdown-menu-item/dropdown-menu-item.component';
import { ExpandableMenuItemComponent } from './expandable-menu-item/expandable-menu-item.component';
import { HeaderContent } from './header.model';

export type HeaderVariant = 'default' | 'dark' | 'scrolled';

@UntilDestroy()
@Component({
    selector: 'gcore-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    imports: [
        CommonModule,
        ModalComponent,
        AnalyticsEventDirective,
        DropdownMenuItemComponent,
        SvgIconComponent,
        RouterLink,
        FormsModule,
        LogoComponent,
        ExpandableMenuItemComponent,
        ModalLoginComponent,
        ChildMenuDirective,
        SearchComponent,
        SliceDescriptionPipe,
        ClickOutsideDirective,
        LanguageSelectorComponent,
        TagComponent,
    ],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewChecked {
    @HostBinding('class.gc-old-styles') private isOldStyle = true;
    @Input() public availableLanguages: Array<LanguageEnum>;
    public showMenu: boolean = false;
    public variant: HeaderVariant = 'default';
    public intersectionObserver: IntersectionObserver;
    public isLoginModalOpen: boolean = false;
    public isRegisterModalOpen: boolean = false;
    public isSearchPanelOpen: boolean = false;
    public locale: string;
    public ModalSize = ModalSize;
    public currentMenuItem: CurrentMenuItem = null;
    public content: HeaderContent;
    public loginModalContent: LoginModalContent;
    public ICONS_MAP = HEADER_ICONS_MAP;
    public ICONS_MAP_NEW_HEADER = HEADER_NEW_ICONS_MAP;
    public contentInitialized: boolean = false;
    public showDropdown = false;
    public activeMenuItem: number | null = null;
    public signUpUrl: string = this.analyticsService.getSignUpUrlWithParams(RegisterLinksEnum.Cloud, 'CLOUD');
    public isTablet: boolean = false;
    @ViewChildren(ExpandableMenuItemComponent) public menuComponents: QueryList<ExpandableMenuItemComponent>;
    @ViewChild('topBanner', { static: false }) public topBanner: ElementRef;
    @ViewChild('menu', { static: false }) public menu: ElementRef;
    @ViewChild('mainMenu', { static: false }) public mainMenu: ElementRef;
    @ViewChild('mainMenuWrapper', { static: false }) public mainMenuWrapper: ElementRef;
    @ViewChild('buttonBlock', { static: false }) public buttonBlock: ElementRef;
    protected readonly DeviceTypeEnum = DeviceTypeEnum;
    private _variant: HeaderVariant = 'default';

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private localizationService: LocalizationService,
        private strapiContentService: StrapiContentService,
        private elRef: ElementRef,
        private changeDetector: ChangeDetectorRef,
        private analyticsService: AnalyticsService,
        private seoService: SeoService,
        private searchService: SearchService,
        @Inject(DOCUMENT) private document: Document,
    ) {
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                switchMap(() =>
                    combineLatest([
                        this.route.firstChild.data,
                        this.route.firstChild.fragment,
                        this.route.firstChild.url,
                    ]),
                ),
                untilDestroyed(this),
            )
            .subscribe(([data, fragment, url]) => {
                this.setHeaderColor(data, fragment);
                this.observeElementIntersection();
                if (this.searchService.isOpened) this.searchService.toggleSearchConsole(false);
                this.closeMenu();
            });
    }

    public originalOrder = (a: KeyValue<string, Array<MenuItem>>, b: KeyValue<string, Array<MenuItem>>): number => {
        return 0;
    };

    public ngOnInit(): void {
        const { data, fragment, url } = this.route.firstChild.snapshot;
        this.setHeaderColor(data, fragment);

        if (!isScullyRunning()) {
            this.addScroll();
        }
        this.getContent()
            .pipe(untilDestroyed(this))
            .subscribe((data) => {
                this.content = data;
                this.changeDetector.detectChanges();
            });
        this.strapiContentService
            .getLoginModalContent()
            .pipe(untilDestroyed(this))
            .subscribe((data) => {
                this.loginModalContent = data;
                this.changeDetector.detectChanges();
            });

        if (window.innerWidth <= MOBILE_DEVICE_BREAKPOINT) {
            this.isTablet = true;
        }

        this.searchService.isSearchConsoleOpened$.subscribe((value) => {
            this.isSearchPanelOpen = value;
            this.changeDetector.detectChanges();
        });
    }

    public ngAfterViewChecked(): void {
        if (!this.contentInitialized && this.elRef.nativeElement.offsetWidth) {
            this.observeElementIntersection();
            this.contentInitialized = true;
        }

        if (this.seoService.isCaptchaOnPage && this.document.querySelector('iframe[title="reCAPTCHA"]')) {
            const element = document.querySelector('iframe[title="reCAPTCHA"]') as HTMLIFrameElement;

            if (element.src.includes(`hl=${this.locale}&`)) {
                this.seoService.isCaptchaOnPage = false;
                return;
            }

            const reg = new RegExp(`hl=(${this.localizationService.defaultLanguages.join('|')})&`);
            element.src = element.src.replace(reg, `hl=${this.locale}&`);
            const extraElements = this.document.querySelectorAll('script, iframe') as NodeListOf<
                HTMLIFrameElement | HTMLScriptElement
            >;
            extraElements.forEach((el) => {
                if (el.src?.includes('recaptcha') && el !== element) {
                    el.remove();
                }
            });
            this.seoService.isCaptchaOnPage = false;
        }
    }

    public ngOnDestroy(): void {
        this.intersectionObserver?.disconnect();
    }

    public handleMenuChange(menuItem: CurrentMenuItem): void {
        this.currentMenuItem = menuItem;
        if (menuItem === null && window.innerWidth <= MOBILE_DEVICE_BREAKPOINT) {
            this.toggleMenu();
        }
    }

    public toggleMenu(): void {
        this.showMenu = !this.showMenu;
        if (window.innerWidth <= MOBILE_DEVICE_BREAKPOINT) {
            this.menuComponents?.forEach((menu) => menu.hideMenuMobile());
            this.currentMenuItem = null;
        }
        toggleScroll(this.showMenu);
    }

    public closeMenu(): void {
        this.showMenu = false;
        this.menuComponents?.forEach((menu) => menu.hideMenuMobile());
        this.currentMenuItem = null;
        toggleScroll(false);
    }

    public setActiveMenuItem(index: number): void {
        this.activeMenuItem = index;
        if (this.searchService.isOpened) this.searchService.toggleSearchConsole(false, false);
        this.changeDetector.detectChanges();
    }

    public navigateBack(): void {
        if (this.currentMenuItem.lvl === 2) {
            this.menuComponents.forEach((menu) => menu.hideChildMenu());
            this.currentMenuItem = null;
        }
    }

    public toggleLoginModal(event: boolean): void {
        this.isLoginModalOpen = event;
        this.searchService.toggleSearchConsole(false);
        toggleScroll(this.isLoginModalOpen);
    }

    public toggleSearchConsole(event?: boolean): void {
        this.searchService.toggleSearchConsole(event);
    }

    public toggleRegisterModal(event: boolean): void {
        if (event) {
            if (this.getLastUrlChunk() === 'ddos-protection') {
                this.router.navigate(['emergency-ddos-protection'], { relativeTo: this.route });
                return;
            }

            const lastChunk: string = this.getFirstUrlChunk();
            const defaultProduct: string = AuthLinkDefaultProductMap.get(lastChunk);
            const customInfo = CloudCustomInfoMap.get(this.getLastUrlChunk());

            if (defaultProduct) {
                this.navigateToExternalUrl(
                    this.analyticsService.getSignUpUrlWithParams(RegisterLinksEnum.Cloud, defaultProduct, customInfo),
                );
            } else if (lastChunk === 'hosting') {
                this.navigateToExternalUrl(this.analyticsService.getSignUpUrlWithParams(RegisterLinksEnum.Hosting));
            } else {
                this.isRegisterModalOpen = true;
            }
        } else {
            this.isRegisterModalOpen = false;
        }
        if (this.isSearchPanelOpen) {
            this.searchService.toggleSearchConsole(false);
        }
        toggleScroll(this.isRegisterModalOpen);
    }

    public navigateToAuth(link: ModalLoginEmitEnum): void {
        if (!this.isLoginModalOpen && !this.isRegisterModalOpen) {
            return;
        }

        const url = this.prepareUrl(link);
        this.toggleLoginModal(false);
        this.toggleRegisterModal(false);
        this.navigateToExternalUrl(url);
        this.changeDetector.detectChanges();
    }

    public addScroll(): void {
        const agent = window.navigator.userAgent;
        let os: string = '';
        const htmlTag = document.documentElement;

        if (agent.indexOf('Win') !== -1) os = 'Windows';
        if (agent.indexOf('Mac') !== -1) os = 'MacOS';
        if (agent.indexOf('X11') !== -1) os = 'UNIX';
        if (agent.indexOf('Linux') !== -1) os = 'Linux';

        if (os !== '' && os !== 'MacOS') {
            htmlTag.classList.add('scrollbar');
        }
    }

    public toggleDropdown(event: MouseEvent): void {
        this.showDropdown = !this.showDropdown;
        event.stopPropagation();
    }

    public closeDropdownLang(): void {
        this.showDropdown = false;
    }

    private observeElementIntersection(): void {
        const el = this.elRef.nativeElement;

        this.intersectionObserver = new IntersectionObserver(
            ([e]) => {
                const target = el;

                if (e.intersectionRatio > 0) {
                    if (e.intersectionRatio < 1) {
                        this.variant = 'scrolled';
                        target.classList.add(PINNED_HEADER);
                    } else {
                        this.variant = this._variant;
                        target.classList.remove(PINNED_HEADER);
                    }
                }

                this.changeDetector.detectChanges();
            },
            {
                threshold: [1],
            },
        );

        this.intersectionObserver.observe(el);
    }

    private navigateToExternalUrl(url: string): void {
        window.open(url, '_blank');
    }

    private prepareUrl(link: ModalLoginEmitEnum): string {
        const lastChunk: string = this.getFirstUrlChunk();
        const defaultProduct: string = AuthLinkDefaultProductMap.get(lastChunk);
        let url: string;
        if (this.isLoginModalOpen) {
            url =
                link === ModalLoginEmitEnum.Cloud
                    ? this.analyticsService.getSignUpUrlWithParams(LoginLinksEnum.Cloud, defaultProduct)
                    : this.analyticsService.getSignUpUrlWithParams(LoginLinksEnum.Hosting);
        } else {
            url =
                link === ModalLoginEmitEnum.Cloud
                    ? this.analyticsService.getSignUpUrlWithParams(RegisterLinksEnum.Cloud, defaultProduct)
                    : this.analyticsService.getSignUpUrlWithParams(RegisterLinksEnum.Hosting);
        }
        return url;
    }

    private getFirstUrlChunk(): string {
        const urlChunks: Array<string> = this.router.url.split('/');
        if (Object.values(LanguageEnum).includes(urlChunks[1] as LanguageEnum)) return urlChunks[2];
        return urlChunks[1];
    }

    private getLastUrlChunk(): string {
        const urlChunks: Array<string> = this.router.url.split('/');
        return urlChunks[urlChunks.length - 1];
    }

    private setHeaderColor(data: Data, fragment: string): void {
        if (data.isDarkHeader) {
            if (!fragment) {
                this.variant = 'dark';
            }
            this._variant = 'dark';
        } else {
            if (!fragment) {
                this.variant = 'default';
            }
            this._variant = 'default';
        }
    }

    public getContent(): Observable<HeaderContent> {
        return combineLatest([
            this.strapiContentService.getSingleContentType('header-config', 'headerConfig'),
            this.strapiContentService.getSingleContentType('search-console', 'SearchConsole'),
        ]).pipe(
            map(([data, searchConsole]) => ({
                ...data,
                searchConsole,
                solutionsMenu: data.solutionsMenu.map((menuItem: any) => ({
                    label: menuItem.label,
                    childMenu: menuItem.childMenu.map((children: any) => {
                        return children.childMenu.reduce((resultMenu: any, menuGroup: any) => {
                            resultMenu[menuGroup.label] = [...menuGroup.childMenu];
                            return resultMenu;
                        }, {});
                    }),
                })),
                productsMenu: data.productsMenu.map((productsItem: any) => ({
                    label: productsItem.label,
                    childMenu: productsItem.childMenu.reduce((resultMenu: any, menuGroup: any) => {
                        resultMenu[menuGroup.label] = [...menuGroup.childMenu];
                        return resultMenu;
                    }, {}),
                    moreAboutButton: productsItem.moreAboutButton,
                })),
            })),
        );
    }
}
